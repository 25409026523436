import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environment";

@Injectable({
	providedIn: "root"
})

export class HeaderTagService {

	env = environment.development ? "(Dev)" : environment.staging ? "(Staging)" : "";
	defaultTitle = "My $brand Self Service - $brand $env";
	defaultMeta = "We’re making it easier for you to manage your $brand services. Log in to My Dodo and click on the Billing tab to see your balance.";

	constructor(private title: Title, private meta: Meta) {
		this.defaultTitle = this.title.getTitle() || this.defaultTitle;
	}

	setTag(url: string) {
		let title = this.defaultTitle;
		let metaTagDescription = this.defaultMeta;

		switch (url) {
			case "/login":
				title = "My $brand Self Service Login - $brand $env";
				metaTagDescription = "Log in to My $brand and click on the Billing tab to see your balance.";
				break;
			case "/forgot-pwd":
				title = "Forgot Password - $brand $env";
				metaTagDescription = "If you don't have a mobile number listed on your account, get in touch on $phone (Monday - Friday 10AM - 7PM AEST).";
				break;
			case "/pay-my-bill":
				title = "Pay My Bill - $brand $env";
				metaTagDescription = "Please enter your Contact number listed with $brand OR Account number provided to you on your welcome email and your date of birth to retrieve your account.";
				break;
			case "/payment-extension":
				title = "Payment Extensions - $brand $env";
				metaTagDescription = "Payment Extensions: Please enter your Contact number listed with $brand OR Account number provided to you on your welcome email and your date of birth to retrieve your account.";
				break;
			default:
				title = this.defaultTitle;
				metaTagDescription = this.defaultMeta;
	  	}

		title = this.formatText(title);
		metaTagDescription = this.formatText(metaTagDescription);

		this.title.setTitle(title);
		this.meta.updateTag({
			name: "description",
			content: this.formatText(metaTagDescription)
  		});
  }

	formatText(contentText: string): string {
		let brand = "";
		let phone = "";

		switch (environment.brand.toLowerCase()) {
			case "dodo":
				brand = "Dodo";
				phone = "13 36 36";
				break;
		  	case "iprimus":
				brand = "iPrimus";
				phone = "13 17 89";
				break;
		  	default:
				brand = "Vocus";
		}

		return contentText
		.replace(new RegExp("\\$env", "g"), this.env)
		.replace(new RegExp("\\$brand", "g"), brand)
		.replace(new RegExp("\\$phone", "g"), phone);
	}

}
