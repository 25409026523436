<!-- <div class="animate done" style="margin-top: 2rem;" id="demo-btns"> 
    <button class="btn btn1" (click)="createPasskey()" id="authenticate-btn">Authenticate</button> 
    <button class="btn btn1 disabled" (click)="verifyPasskey();" id="verify-btn">Verify</button> 
</div> -->

<!DOCTYPE html>
<html lang="en">

<head>
  <!-- Bootstrap CSS -->
  <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
</head>

<body class="pwa-biometric">
  <div class="pwa-biometric__container container d-flex flex-column vh-100">
    <img class="pwa-biometric__logo" src="assets/dodo-logo-white.svg">
    <h1 class="pwa-biometric__header">Fast, reliable, almost flawless.</h1>

    <ng-container *ngIf="isUserProfileAvailable();else login">
      <button class="btn-primary btn-custom" (click)="createPasskey()">Set Up Biometric</button>
    </ng-container>
    <ng-template #login>
      <button class="btn-success btn-custom" (click)="verifyPasskey()">Sign In</button>
    </ng-template>
  </div>
</body>

</html>