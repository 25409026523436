import { Component, OnInit } from "@angular/core";
import { Auth0Service } from "../../../auth0/auth0.service";
import { environment } from "src/environment";
@Component({
	selector: "app-forgot-pwd",
	templateUrl: "./forgot-pwd.component.html",
	styleUrls: ["./forgot-pwd.component.scss"]
})
export class ForgotPwdComponent implements OnInit {
	public _email: string;
	public _accountNumber: number;
	public _mobileNumber: string;
	public _passwordErrorMessage: string;
	public _usernameErrorMessage: string;
	public isPwdStatus: boolean;
	public isUsernameStatus: boolean;
	public btnUsernameLoader: boolean;
	public btnPwdLoader: boolean;
	public isPasswordPostSuccessful: boolean;
	public isUsernamePostSuccessful: boolean;
	public recoverUserNameSelected: boolean;
	public recoverPasswordSelected: boolean;
	public returnedMobileno: string;
	public recoveredEmail: string;
	public token: string | undefined;
	public isAccNoPopoverActive = false;
	public isMobNoPopoverActive = false;
	public sentToText = "SMS";
	constructor(
		private __auth0Service: Auth0Service
	) {
		this.token = undefined;
	}

	ngOnInit() {
	}

	public submitForgotPwdEmail(): void {
		this.btnPwdLoader = true;
		const data = {
			emailAddress: this._email,
			type: "Email",
			subject: "authResetPassword"
		};
		this.__auth0Service.resetPwdEmailSentStatus(data).subscribe(
			resetPwdResponse => {
				this.sentToText = this.setTxtEmailSMS(resetPwdResponse.destination);
				this.isPasswordPostSuccessful = true;
				if (resetPwdResponse.status) {
					this.isPwdStatus = true;
					this.btnPwdLoader = false;
					this.returnedMobileno = resetPwdResponse.destination;
				} else {
					this.isPwdStatus = false;
				}
			},
			error => {
				this.isPasswordPostSuccessful = false;
				this.btnPwdLoader = false;
				if (error.error?.message) {
					this._passwordErrorMessage = error.error?.message;
				} else {
					this._passwordErrorMessage = "Sorry! We can't reset your Password at this time. Please try again later.";
				}
			}
		);
	}

	public submitForgotUsernameEmail(): void {
		this.btnUsernameLoader = true;
		const data = {
			accountNumber: this._accountNumber,
			phoneNumber: this._mobileNumber,
			type: "Mobile",
			subject: "authRetrieveUsername"
		};
		this.__auth0Service.recoverUsername(data).subscribe(
			resetPwdResponse => {
				this.isUsernamePostSuccessful = true;
				if (resetPwdResponse.status) {
					this.isUsernameStatus = true;
					this.btnUsernameLoader = false;
					this.recoveredEmail = resetPwdResponse.destination;
					console.log("returned Email: ", this.recoveredEmail);
				} else {
					this.isUsernameStatus = false;
				}
			},
			error => {
				this.isUsernamePostSuccessful = false;
				this.btnUsernameLoader = false;
				if (error.error?.message) {
					this._usernameErrorMessage = error.error?.message;
				} else {
					this._usernameErrorMessage = "Sorry! We can't retrieve your username at this time. Please try again later.";
				}
			}
		);
	}

	private setTxtEmailSMS(destination: string): string {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(destination) ? "EDM" : "SMS";
	}

	getSupportFormURL() {
		return `${environment.websiteHostServer}/online-support-request-my-dodo`;
	}
	public recoverUsername(): void {
		this.recoverUserNameSelected = true;
	}
	public recoverPassword(): void {
		this.recoverPasswordSelected = true;
	}

	public toggleAccNoPopover(): void {
		this.isAccNoPopoverActive = !this.isAccNoPopoverActive;
	}

	public toggleMobNoPopover(): void {
		this.isMobNoPopoverActive = !this.isMobNoPopoverActive;
	}
}
