import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Customerlogin } from "src/app/authentication/customerlogin";

import { AuthcustomerService } from "src/app/services/authcustomer.service";
import { AppStoreService } from "src/app/services/appStore.service";

import { ICustomerUnAuth } from "src/app/self-serve/models/store.model";
import { Title, Meta } from "@angular/platform-browser";

import {
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from "@angular/material-moment-adapter";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE
} from "@angular/material/core";

import * as _moment from "moment";
const moment = _moment;

export const MY_FORMATS_DDMMYYYY = {
	parse: { dateInput: "DD/MM/YYYY" },
	display: {
		dateInput: "DD/MM/YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM YYYY"
	}
};

@Component({
	selector: "app-pay-bill-login",
	templateUrl: "./pay-bill-login.component.html",
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "en-AU" },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_DDMMYYYY }
	]
})
export class PayBillLoginComponent implements OnInit {
	apiError = false;
	customerlogin = new Customerlogin("", "", "");

	btnProloader = false;
	message = "";
	myAccountLoginHeader: string;
	customerdobYYYYMMDD: any;
	minDate: any;
	maxDate: any;
	myAccountLoginBtntxt: string;
	API_CUSTOM_ERROR_MESSAGE = "Unfortunately My Dodo is offline. Please try again later.";
	CUSTOM_MSG_FOR_NO_ELIGIBLE_SERVICES = "We are unable to find an eligible service at this time, please log into MyDodo to continue.";
	CUSTOM_MSG_NO_SERVICES_TO_TRACK_ORDER_STATUS = "Currently you don't have any services to track your order.";

	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private _authcustomerService: AuthcustomerService,
		private appStoreService: AppStoreService,
		private titleService: Title,
		private meta: Meta
	) { }

	ngOnInit() {
		this.setMyAccountHeader();
		this.getDOBdateRange();
	}

	private getDOBdateRange(): void {
		this.minDate = this.subtractYears(new Date(), 130);
		this.maxDate = this.subtractYears(new Date(), 18);
	}

	private subtractYears(date, years): void {
		date.setFullYear(date.getFullYear() - years);
		return date;
	}

	public onClickMyPayBill_plan_ActStat_navigate(): void {
		if (this.router.url.indexOf("/pay-my-bill") > -1) {
			this.customerlogin.sourceurl = "pay-my-bill";
			this.unauthAuthenticate();
		} else if (this.router.url.indexOf("/payment-extension") > -1) {
			this.customerlogin.sourceurl = "payment-extension";
			this.unauthAuthenticate();
		} else if (this.router.url.indexOf("/dodo-account-status") > -1) {
			this.customerlogin.sourceurl = "dodo-account-status";
			this.unauthAuthenticate();
		}
	}

	private unauthAuthenticate(): any {
		this.btnProloader = true;
		if (!this.validateCustomer()) {
			return;
		}

		this.customerdobYYYYMMDD = moment(this.customerlogin.customerdob).format(
			"YYYYMMDD"
		);
		this._authcustomerService
			.authenticateCustomer(
				this.customerlogin.contactservicecustomerid,
				this.customerdobYYYYMMDD
			)
			.subscribe(
				(allProductsunauth) => {
					if (allProductsunauth && allProductsunauth.length >= 1) {
						this.setCustomerProductInfo();
						this.appStoreService.updateProductsUnAuth(allProductsunauth);
						this.btnProloader = false;
						if (this.customerlogin.sourceurl === "payment-extension") {
							this.router.navigate(["/payment-helper"]);
						} else {
							this.router.navigate(["/select-services"]);
						}
					}
				},
				(error) => {
					if (error.status === 400 || error.message === "Invalid value 'null' for query parameter customerId. Invalid type Null, expected String") {
						this.apiError = true;
						this.btnProloader = false;
						this.displayCustomMsgForSelectedUrl();
					} else if (error.status === 401) {
						this.apiError = true;
						this.btnProloader = false;
						this.message = "Your Customerid	or DOB is incorrect.";
					} else {
						this.apiError = true;
						this.btnProloader = false;
						this.message = this.API_CUSTOM_ERROR_MESSAGE;
					}
				}
			);
	}

	private validateCustomer(): any {
		if (
			this.customerlogin.contactservicecustomerid !== "" &&
			this.customerlogin.customerdob !== ""
		) {
			return true;
		}
		return false;
	}

	private setCustomerProductInfo(): void {
		const customerunauth = <ICustomerUnAuth>{
			contactservicecustomerid: this.customerlogin.contactservicecustomerid,
			sourceurl: this.customerlogin.sourceurl
		};
		this.appStoreService.updateCustomerUnAuth(customerunauth);
	}

	private setMyAccountHeader(): void {
		if (this.router.url.indexOf("/pay-my-bill") > -1) {
			this.myAccountLoginHeader = "Pay a Bill";
			this.myAccountLoginBtntxt = "FIND MY ACCOUNT";
			this.setTitle("Pay My Bill - Dodo");
			this.meta.updateTag({
				name: "description",
				content:
					"Please enter your Contact number listed with dodo OR Account number provided to you on your welcome email and your date of birth to retrieve your account."
			});
		} else if (this.router.url.indexOf("/payment-extension") > -1) {
			this.myAccountLoginHeader = "Payment Extension";
			this.myAccountLoginBtntxt = "FIND MY ACCOUNT";
			this.setTitle("Payment Extensions - Dodo");
			this.meta.updateTag({
				name: "description",
				content:
					"Payment Extensions: Please enter your Contact number listed with dodo OR Account number provided to you on your welcome email and your date of birth to retrieve your account."
			});
		} else if (this.router.url.indexOf("/dodo-account-status") > -1) {
			this.myAccountLoginHeader = "Account Status";
			this.myAccountLoginBtntxt = "CHECK MY ORDER";
			this.setTitle("Account Status - Dodo");
			this.meta.updateTag({
				name: "description",
				content:
					"Please enter your Contact number listed with dodo OR Account number provided to you on your welcome email and your date of birth to retrieve your account."
			});
		}
	}

	private setTitle(newTitle: string): void {
		this.titleService.setTitle(newTitle);
	}

	private displayCustomMsgForSelectedUrl(): void {
		if (this.customerlogin.sourceurl === "pay-my-bill" || this.customerlogin.sourceurl === "payment-extension") {
			this.message = this.CUSTOM_MSG_FOR_NO_ELIGIBLE_SERVICES;
		} else {
			this.message = this.CUSTOM_MSG_NO_SERVICES_TO_TRACK_ORDER_STATUS;
		}
	}
}
