import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "app-move-home-mobile-dialog",
	templateUrl: "./move-home-mobile-dialog.component.html",
	styleUrls: ["./move-home-mobile-dialog.component.scss"]
})
export class MoveHomeMobileDialogComponent {
	constructor(private router: Router) {}

  goToUpdateDetails(): void {
		// Redirect to "/account-introduction/account-personal";
		this.router.navigate(["account-introduction", "account-personal"])
	}
}