import { Injectable } from "@angular/core";

// rxjs
import { Observable } from "rxjs";

// model
import { IUsage } from "../models/usage.model";

// libraries
import { HttpService } from "../../../libraries/api/services/http.service";

import { environment } from "src/environment";

@Injectable({
	providedIn: "root"
  })
export class UsageService {

  constructor(
	private httpService: HttpService
  ) { }

  getUsage(serviceId, category): Observable<IUsage> {
	const _url = `${
		environment.apiURL
	}/api/v1/usagemgmt/usagesummary?serviceId=${serviceId}&category=${category}`;
	return this.httpService.get<IUsage>(_url);
  }

  getDodoUsage(serviceId, productCode, serviceType): Observable<IUsage> {
	const _url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/usagemgmt/usagesummary?serviceId=${serviceId}&productCode=${productCode}&serviceType=${serviceType}`;
	return this.httpService.get<IUsage>(_url);
  }

  getPeriodUsage(serviceId, productCode, serviceType, periodId): Observable<IUsage> {
	const _url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/usagemgmt/usagesummary?serviceId=${serviceId}&productCode=${productCode}&serviceType=${serviceType}&periodId=${periodId}`;
	return this.httpService.get<IUsage>(_url);
  }
}
