<section class="bg-color-primary height-full">
  <section class="forgot-login-details bg-color-primary">
    <div class="_forgot-login-details-container bg-white p5" *ngIf="!(isPasswordPostSuccessful || isUsernamePostSuccessful) && !(recoverUserNameSelected ||  recoverPasswordSelected)">
      <div class="__heading font-brand txt-color-primary font-semibold">
        Retrieve login details
      </div>
      <div
        class="__description font-brand txt-grey-darker txt-base font-medium py3"
      >
        Which of your details would you like to recover?
      </div>
      <form #forgotpwd="ngForm">
        <div class="py3">
          <button
            class="btn brand-btn-secondary width-full relative"
            (click)="recoverUsername()"
          >
            <span class="">RECOVER MY USERNAME</span>
          </button>
        </div>
        <div class="py3">
          <button
            class="btn brand-btn-secondary width-full relative"
            (click)="recoverPassword()"
          >
            <span class="">RECOVER MY PASSWORD</span>
          </button>
        </div>
        
        <div class="mt6">
          <a routerLink="/login" routerLinkActive="active" class="url"
            >BACK TO LOGIN</a
          >
        </div>
      </form>
    </div>
    <!-- Collecting details for recover pwd -->
    <div class="_forgot-login-details-container bg-white p5" *ngIf="!isPasswordPostSuccessful && recoverPasswordSelected">
      <!--API Error-->
      <div class="post-fail py5 pt5" *ngIf="_passwordErrorMessage">
        <div class="error txt-sm font-brand txt-color-error bg-color-error">
          <img src="/assets/images/icons/icon-error-orange.svg" class="mr1" />{{
            _passwordErrorMessage
          }}
        </div>
      </div>
      <div class="__heading font-brand txt-color-primary font-semibold">
        Retrieve Password
      </div>
      <div
        class="__description font-brand txt-grey-darker txt-base font-medium py3"
      >
        Forgot Password? No worries!
      </div>
      <div class="__subheading txt-black txt-lg">
        We will send you an email or SMS with instructions to reset your
        account.
      </div>
      <form #forgotpwd="ngForm">
        <div class="py3">
          <input
            class="input-box txt-sm"
            type="email"
            placeholder="yours@example.com"
            [(ngModel)]="_email"
            name="emailAddress"
            #emailAddress="ngModel"
          />
          <div
            *ngIf="
              emailAddress.invalid &&
              emailAddress.touched &&
              emailAddress.value !== ''
            "
            class="indicators"
          >
            Email Address is incorrect!
          </div>
        </div>
        <div class="mt0">
          <button
            class="btn brand-btn-secondary width-full relative"
            (click)="submitForgotPwdEmail()"
            [disabled]="!_email || _email === ''"
          >
            <span class="" *ngIf="!btnPwdLoader">SEND EMAIL</span>
            <span class="btn-preloader" *ngIf="btnPwdLoader"></span>
          </button>
        </div>
       
        <div class="mt6">
          <a routerLink="/login" routerLinkActive="active" class="url"
            >BACK TO LOGIN</a
          >
        </div>
      </form>
      
      <div class="auth0-msg mt2 p3">
        <i class="fas fa-exclamation-circle mr1"></i>
        Need help with your email address?
        <a class="msgurl" href="{{ getSupportFormURL() }}">Get in touch</a>.
      </div>
    </div>
    <!-- Collecting details for recover username -->
    <div class="_forgot-login-details-container bg-white p5" *ngIf="!isUsernamePostSuccessful && recoverUserNameSelected">
      <!--API Error-->
      <div class="post-fail py5 pt5" *ngIf="_userNameErrorMessage">
        <div class="error txt-sm font-brand txt-color-error bg-color-error">
          <img src="/assets/images/icons/icon-error-orange.svg" class="mr1" />{{
            _usernameErrorMessage
          }}
        </div>
      </div>
      <div class="__heading font-brand txt-color-primary font-semibold">
        Retrieve Username
      </div>
      <div
        class="__description font-brand txt-grey-darker txt-base font-medium py3"
      >
        Forgot Username? No worries!
      </div>
      <div class="__subheading txt-black txt-lg">
        We will send you an email.
      </div>
      <form #forgotpwd="ngForm">
        <div class="py3">
          <div class="inline-flex">
            <input
              class="input-box txt-sm"
              placeholder="Enter account number"
              [(ngModel)]="_accountNumber"
              name="accountNumber"
              #accountNumber="ngModel"
              required
            />
            <!--Tooltip-->
            <div class="popover-kwh">
              <img class="question-mark-icon txt-white txt-xs ml1" src="assets/images/icons/question-mark.png" (click)="toggleAccNoPopover()">
              <div [ngClass]="isAccNoPopoverActive ? 'block' : 'hide'">
                <div class="_pointer"></div>
                <div class="_popover">
                  <div class="__popover-content">
                    Please check your invoice or emails from Dodo to get your account number
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
                class="indicators block txt-xs"
                [class.hide]="accountNumber?.valid || accountNumber?.untouched"
                *ngIf="accountNumber?.errors?.required"
            >
              Account number is required
          </div>
        </div>
        <div class="py3">
          <div class="inline-flex">
            <input
              class="input-box txt-sm"
              placeholder="Enter mobile number"
              [(ngModel)]="_mobileNumber"
              name="mobileNumber"
              #mobileNumber="ngModel"
              pattern="^((61|\+61)?\s?)04[0-9]{2}\s?([0-9]{3}\s?[0-9]{3}|[0-9]{2}\s?[0-9]{2}\s?[0-9]{2})$"
              required
              maxlength="10"
              minlength="10"
            />
            <!--Tooltip-->
            <div class="popover-kwh">
              <img class="question-mark-icon txt-white txt-xs ml1" src="assets/images/icons/question-mark.png" (click)="toggleMobNoPopover()">
              <div [ngClass]="isMobNoPopoverActive ? 'block' : 'hide'">
                <div class="_pointer"></div>
                <div class="_popover">
                  <div class="__popover-content">
                    Enter mobile number registered to the account
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          class="indicators block txt-xs"
          [class.hide]="mobileNumber?.valid || mobileNumber?.untouched"
          *ngIf="mobileNumber?.errors?.required"
          >
          Mobile number is required
          </div>
        </div>
        <div class="py3">
          <re-captcha  
          id="recaptcha" 
          name="recaptcha" 
          #recaptcha="ngModel" 
          [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)">
          </re-captcha>
        </div>

        <div class="mt0">
          <button
            class="btn brand-btn-secondary width-full relative"
            (click)="submitForgotUsernameEmail()"
            [disabled]="!_mobileNumber || _mobileNumber === '' || !_accountNumber || _accountNumber === '' || !token"
          >
            <span class="" *ngIf="!btnUsernameLoader">SEND EMAIL</span>
            <span class="btn-preloader" *ngIf="btnUsernameLoader"></span>
          </button>
        </div>
        <div class="mt6">
          <a routerLink="/login" routerLinkActive="active" class="url" (click)="recaptcha.reset()" 
            >BACK TO LOGIN</a
          >
        </div>
      </form>
      
      <div class="auth0-msg mt2 p3">
        <i class="fas fa-exclamation-circle mr1"></i>
        Need help with your account?
        <a class="msgurl" href="{{ getSupportFormURL() }}">Get in touch</a>.
      </div>
    </div>
    <!-- Recover Pwd - This message comes, when verification link is sent -->
    <div class="_forgot-login-details-container bg-white p5" *ngIf="isPasswordPostSuccessful && isPwdStatus && !returnedMobileno && recoverPasswordSelected">
      <div
        class="__heading txt-center-align font-brand txt-color-primary font-semibold pb2"
      >
        <img src="assets/images/modal/green-tick-circle.svg" class="py3" />
        <span class="block pb1">Yay!</span>
        <span>We have just sent you a verification link to reset your password</span>
      </div>
      <div class="__subheading txt-black txt-lg py5 txt-center-align">
        Please check your email and follow the link to change your password
      </div>
      <div class="__subheading txt-black txt-lg pb5 txt-center-align">
        <span class="block pb1">Sent to</span>
        <span class="block txt-sm">{{ _email }}</span>
      </div>
      <div class="py3">
        <button class="btn brand-btn-secondary width-full" routerLink="/login" >
          BACK TO LOGIN
        </button>
      </div>
    </div>
    <!-- Recover Pwd - email is provided but profile is not in auth0 but it does exist in billit, sms would be sent -->
    <div class="_forgot-login-details-container bg-white p5" *ngIf="isPasswordPostSuccessful && isPwdStatus && returnedMobileno && recoverPasswordSelected">
      <div
        class="__heading txt-center-align font-brand txt-color-primary font-semibold pb2"
      >
        <img src="assets/images/modal/green-tick-circle.svg" class="py3" />
        <span class="block pb1">Yay!</span>
        <span>We have just sent you a {{ sentToText }}</span>
      </div>
      <div class="__subheading txt-black txt-lg py5 txt-center-align">
        Please use the details provided in the {{ sentToText }} and follow the prompts to log
        into My Dodo.
      </div>
      <div class="__subheading txt-black txt-lg pb5 txt-center-align">
        <span class="block pb1">Sent to</span>
        <span class="block txt-sm">{{ returnedMobileno }}</span>
      </div>
      <div class="py3">
        <button class="btn brand-btn-secondary width-full" routerLink="/login">
          BACK TO LOGIN
        </button>
      </div>
    </div>
    <!-- Recover Pwd - email associated with account verified as false -->
    <div
      class="_forgot-login-details-container bg-white p5"
      *ngIf="isPasswordPostSuccessful && !isPwdStatus"
    >
      <div class="auth0-alert p3">
        <i class="block fas fa-exclamation-circle mr3"></i>
        <span class="block">Oops! We couldn’t verify your email.</span>
      </div>

      <div class="__subheading txt-black txt-lg py5 txt-center-align">
        Still having trouble logging in?
        <a class="msgurl" href="{{ getSupportFormURL() }}"> Get in touch</a> and
        we will get in contact you within 48 hours.
      </div>
      <div class="py3 mt3">
        <button class="btn brand-btn-secondary width-full" routerLink="/login">
          BACK TO LOGIN
        </button>
      </div>
    </div>
     <!-- Recover Username - This message comes, when email is sent -->
    <div class="_forgot-login-details-container bg-white p5" *ngIf="isUsernamePostSuccessful && isUsernameStatus && recoveredEmail && recoverUserNameSelected">
      <div
        class="__heading txt-center-align font-brand txt-color-primary font-semibold pb2"
      >
        <img src="assets/images/modal/green-tick-circle.svg" class="py3" />
        <span class="block pb1">Yay!</span>
      </div>
      <div class="__subheading txt-black txt-lg py5 txt-center-align">
        We've sent you an email at {{recoveredEmail}}.
      </div>
      <div class="__subheading txt-black txt-lg pb5 txt-center-align">
        <span class="block pb1">Thank you.</span>
      </div>
      <div class="py3">
        <button class="btn brand-btn-secondary width-full" routerLink="/login" (click)="recaptcha.reset()">
          BACK TO LOGIN
        </button>
      </div>
    </div>
     <!-- Recover Username - status returned as false -->
     <div
     class="_forgot-login-details-container bg-white p5"
     *ngIf="isUsernamePostSuccessful&& !isUsernameStatus"
   >
     <div class="auth0-alert p3">
       <i class="block fas fa-exclamation-circle mr3"></i>
       <span class="block">Oops! We couldn’t retrieve your username.</span>
     </div>

     <div class="__subheading txt-black txt-lg py5 txt-center-align">
       Still having trouble logging in?
       <a class="msgurl" href="{{ getSupportFormURL() }}"> Get in touch</a> and
       we will get in contact you within 48 hours.
     </div>
     <div class="py3 mt3">
       <button class="btn brand-btn-secondary width-full" routerLink="/login" (click)="recaptcha.reset()">
         BACK TO LOGIN
       </button>
     </div>
   </div>
  </section>
</section>
