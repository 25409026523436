// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  development: true,
  test: false,
	staging: false,
  production: false,
  brand: "Dodo",
  merchant: "Dodo",
  iFrameConfig: "DodoOnePlatform",
  agentName: "Dodo support team",
  inMaintenance: false,
  inMaintenanceMsg: " <br/>We're sprucing things up a bit.  <br/>My Dodo will be unavailable from  <br/>11pm – 11:30 pm on Monday 18th July. <br/>  <br/>  <br/>",
  timeout: 90000,
  retryInterval: 30000,
  retryCount: 3,
  tealium_domain: "mydodo.dodo.com",
  configBasePath: "./assets/configs",
  apiUrlDodo: "https://retail-nonprod.api.vocus.com.au/dev",
  invoiceUrlDodo: "https://nonprod.api.vocus.com.au/dev/exp-publicselfservice",
  websiteHostServer: "https://dev.dodo.com",
  supportSiteHostServer: "https://dodo-support-dev.vocus.net",
  apiURL: "https://retail-nonprod.api.vocus.com.au/dev/exp-selfserve",
  authenticationURL: "https://retail-nonprod.api.vocus.com.au/test/oauth2-provider",
  mulesoftAuthentication: false,
  authorizeHeaders: {
	  client_id: "7ba0ddb06cb04c0f9aec1a9acc76f2ac",
		client_secret: "1dfcc17d024A4226981C50965C8c584E",
		groupId: "Dodo"
   },
   auth0Config: {
	  clientId: "VSDkIXjUDOTWo5i5Lo8IXQ1XNtjOcaVV",
	  domain: "dodo-dev.au.auth0.com",
	  callbackUrl: "/login-email",
	  returnTo: "https://mydodo-dev.nonprod-vcms.com",
	  // returnTo: "http://mydodo-spa-dev.nonprod-vcms.com:8088",
	  appMetaData: "https://my.dodo.com/app_metadata"
   },
   authorizeUnAuthHeaders: {
	  client_id: "f311918c043f4b4e820a432116a84006",
	  client_secret: "e395f02C5472484caDf929557d7aF78D",
	  groupId: "Dodo"
  },
  hasOutage: false,
  hasOutageMsg: "<i class='fas fa-exclamation-circle'></i> <span class='ml2'>My Dodo will be unavailable from 11pm AEST Tues 11<sup>th</sup> July and 12am AEST 12<sup>th</sup> July due to scheduled maintenance.</span>",
  appName: "customer-ext-mydodo",
  envName: "dev",
  unleashProxyUrl: "https://digital-self-service.unleashproxy.vocusretailcloud.com.au/proxy",
  unleashProxyClientKey: "TvpXMq$gFa6Us3C5",
  productsTimeout: 9000,
  usageTimeout: 60000,
  recaptcha: {
  siteKey: "6LdGujMlAAAAAHnOvbL9f0Q-JBqfscO4SYnD_OCd"
  },
  crdAllowedPeriod: 30,
  supportSiteEnergyChat: "https://my.dodo.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
import 'zone.js';

