<section class="bg-color-primary height-full">
  <section class="forgot-login-details bg-color-primary">
    <!-- Collecting details for reset account -->
    <div class="_forgot-login-details-container bg-white p5"
      *ngIf="!isPasswordPostSuccessful">
      <!--API Error-->
      <div class="post-fail py5 pt5" *ngIf="_passwordErrorMessage">
        <div class="error txt-sm font-brand txt-color-error bg-color-error">
          <img src="/assets/images/icons/icon-error-orange.svg" class="mr1" />{{
          _passwordErrorMessage
          }}
        </div>
      </div>
      <div class="__heading font-brand txt-color-primary font-semibold">
        Account setup
      </div>
      <div class="__description font-brand txt-grey-darker txt-base font-medium py3">
        Already a customer or have recently changed your email with us?
      </div>
      <div class="__subheading txt-black txt-lg">
        Enter your registered email and we will send you instructions to verify and reset your account.
      </div>
      <form #forgotpwd="ngForm">
        <div class="py3">
          <input class="input-box txt-sm" type="email" placeholder="yours@example.com" [ngModel]="_email" (ngModelChange)="onEmailChange($event)"
            name="emailAddress" #emailAddress="ngModel" />
          <div *ngIf="emailAddress.invalid && emailAddress.touched && emailAddress.value !== '' && !isEmailValid" class="indicators">
            Email Address is incorrect!
          </div>
        </div>
        <div class="mt0">
          <button class="btn brand-btn-secondary width-full relative" (click)="submitForgotPwdEmail()"
            [disabled]="!_email || _email == '' || !isEmailValid">
            <span class="" *ngIf="!btnPwdLoader">SEND EMAIL</span>
            <span class="btn-preloader" *ngIf="btnPwdLoader"></span>
          </button>
        </div>

        <div class="mt6">
          <a routerLink="/login" routerLinkActive="active" class="url">BACK TO LOGIN</a>
        </div>
      </form>

      <div class="auth0-msg mt2 p3">
        <i class="fas fa-exclamation-circle mr1"></i>
        Need help with your email address?
        <a class="msgurl" href="{{ getSupportFormURL() }}">Get in touch</a>.
      </div>
    </div>

    <!-- Success -->
    <div class="_forgot-login-details-container bg-white p5" *ngIf="isPasswordPostSuccessful">
      <div class="__heading txt-center-align font-brand txt-color-primary font-semibold pb2">
        <img src="assets/images/modal/green-tick-circle.svg" class="py3" />
        <span class="block pb1">Yay!</span>
        <span>We have just sent you an email</span>
      </div>
      <div class="__subheading txt-black txt-lg py5 txt-center-align">
        Please use the details provided in the email and follow the prompts to log
        into My Dodo.
      </div>
      <div class="__subheading txt-black txt-lg pb5 txt-center-align">
        <span class="block pb1">Sent to</span>
        <span class="block txt-sm">{{ destination }}</span>
      </div>
      <div class="py3">
        <button class="btn brand-btn-secondary width-full" routerLink="/login">
          BACK TO LOGIN
        </button>
      </div>
    </div>

  </section>
</section>