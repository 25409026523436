import { Injectable } from "@angular/core";
import { ProductService } from "./product.service";
import { UsageService } from "./usage.service";
import { map } from "rxjs/operators";
import { IProduct, IBillingAccount } from "../models/product.model";
import { AppStoreService } from "src/app/services/appStore.service";
import { IAddress, IBillingAccountMS } from "../models/account-details.model";
import { Observable } from "rxjs";
import { BillService } from "src/app/self-serve/services/bill.service";
import { IInvoice } from "../models/invoice.model";
import * as moment from "moment";
@Injectable({
	providedIn: "root"
})
export class BootService {
	products: IProduct[];
	billingAccounts: IBillingAccount[];
	billingAccounts$: Observable<IBillingAccount[]>;
	invoices: IInvoice[];
	currentInvoice: IInvoice;

	constructor(
		private productService: ProductService,
		private usageService: UsageService,
		private appStoreService: AppStoreService,
		private billService: BillService
	) { }

	bootDodo() {
		return this.productService
			.getDodoProductsByAssociationId(this.appStoreService.store.customer.assocId)
			.pipe(
				map(response => {
					const products = response.filter(product => {
						return this.applyDodoProductFilter(product);
					});

					products.map(product => {
						product.ban = product.id;
						product.bss = "billit";
						this.billService.getDodoAccountBalance(product.id).subscribe(balance => {
							product.currentBalance = balance;
						});
						const billObserver = {
							next: (invoices) => this.handleDodoInvoices(invoices, product),
							error: () => this.handleError(product),
						};
						this.billService.getDodoBill(product.id).subscribe(billObserver);
						this.getDodoUsage(product);
					});
					return products;
				})
			);
	}

	boot(): Observable<IBillingAccount[]> {
		const products$ = [];

		this.billingAccounts$ = new Observable(observer => {
			this.productService
				.getBillingAccounts(this.appStoreService.store.customer.accountId)
				.subscribe((billingAccountMS) => {
					billingAccountMS.map(ban => {
						const billingAccount = this.mapBillingAccount(ban);
						this.billingAccounts.push(billingAccount);
						products$.push(new Promise(resolve => {
							this.getProducts(billingAccount.accountNo, billingAccount.id, billingAccount.zouraId)
								.subscribe(products => {
									billingAccount.services = products;
									resolve(this.products || []);
								},
									(error) => {
										resolve([]);
									});
						}));
					});
					Promise.all(products$).then(value => {
						this.appStoreService.updateBillingAccounts(this.billingAccounts);
						observer.next(this.billingAccounts);
						observer.complete();
					});
				},
					(error) => {
						observer.next([]);
						observer.complete();
					});
		});

		return this.billingAccounts$;
	}

	getProducts(accountNo: string, billingAccountId: string, billingAccountZouraId: string) {
		return this.productService.getProducts(accountNo)
			.pipe(map(products => {
				products.filter(product => {
					return this.applyProductFilter(product);
				});

				products.map(product => {
					product.ban = accountNo;
					product.bss = "shelley";
					if (product.productGroupType === "Internet") {
						product.name = "NBN";
					}
					if (product.productGroupType === "Mobile") {
						product.name = "Postpaid Mobile";
						if (!this.appStoreService.store?.usageRequestTimestamp) {
							this.appStoreService.updateUsageReqTimestamp(new Date().getTime());
						}
					}

					if (product.productGroupType === "Internet" || product.productGroupType === "Mobile") {
						this.billService
							.getAccountBalance(this.appStoreService.store.customer.accountId, billingAccountId)
							.subscribe((balance) => {
								product.currentBalance = balance;
							});
						const billObserver = {
							next: (invoices) => this.handleInvoices(invoices, product),
							error: () => this.resetInvoiceAndPaymentDate(product),
						};
						this.billService.getBill(accountNo, billingAccountZouraId).subscribe(billObserver);
					}

					this.getUsage(product);
				});
				return products;
			}));
	}

	mapBillingAccount(ban: IBillingAccountMS): IBillingAccount {
		const billingAccount: IBillingAccount = {
			id: ban.id,
			name: ban.name,
			address: this.findBillingAddress(ban),
			accountNo: ban.accountRelationship?.find((account) => {
				return account.account["@type"] === "Account Number";
			}).account.id,
			zouraId: ban.financialAccount?.id,
			paymentStatus: ban.paymentStatus
		};
		return billingAccount;
	}

	findBillingAddress(billingAccountMS: IBillingAccountMS): IAddress {
		let address: IAddress;

		billingAccountMS.contact?.map((contact) => {
			const contactMedium = contact.contactMedium?.find((medium) => medium?.mediumType === "BillingAddress");
			if (contactMedium) {
				address = contactMedium.characteristic;
			}
		});

		return address;
	}

	applyProductFilter(product) {
		switch (product.status.toLowerCase()) {
			case "terminated":
				product.status = "Cancelled";
				return true;
			case "cancelled":
				product.status = "Withdrawn";
				return true;
			default:
				return true;
		}
	}

	applyDodoProductFilter(product) {
		if (product.services.length === 0) {
			return false;
		}
		if (
			product.productGroupType !== "Energy" &&
			(product.status === "Active" ||
				product.status === "NotActivatedYet" ||
				product.status === "SuspendedDueToNonPayment" ||
				product.status === "NonPayment")
		) {
			return true;
		}
		if (
			product.productGroupType === "Energy" &&
			(product.status === "Active" ||
				product.status === "NotActivatedYet" ||
				product.status === "Cancelled")
		) {
			return true;
		}
	}

	getUsage(product) {
		if (product.productGroupType === "Internet") {
			product.usage = {
				usageConsumption: [],
				totalRecordCount: null
			};
			this.updateProducts(product);
			return;
		}

		if (product.status === "Withdrawn" || product.status === "In Progress") {
			product.usage = { error: true };
			this.updateProducts(product);
			return;
		}

		this.usageService
			.getUsage(
				product.services[0].serviceId,
				product.productGroupType
			)
			.subscribe((usage) => {
				if ("usageConsumption" in usage && usage.usageConsumption.length >= 0) {
					product.usage = usage;
				} else {
					product.usage = { error: true };
				}
				this.identifyTypePeakOffpeak(product);
				this.updateProducts(product);
			},
				(error) => {
					product.usage = { error: true };
					this.updateProducts(product);
				});
	}

	updateProducts(product) {
		this.products.push(product);
		this.appStoreService.updateProducts(this.products);
	}

	getDodoUsage(product) {
		this.usageService
			.getDodoUsage(
				product.services[0].serviceId,
				product.services[0].productCode,
				product.services[0].serviceType
			)
			.subscribe((usage) => {
				if ("usageConsumption" in usage && usage.usageConsumption.length >= 0) {
					product.usage = usage;
				} else {
					product.usage = { error: true };
				}
				this.identifyTypePeakOffpeak(product);
				this.updateProducts(product);
			});
	}

	identifyTypePeakOffpeak(product) {
		if (product && product.productGroupType === "Internet" && product.usage.totalRecordCount > 0) {
			product.peakOffPeakType = false;
			product.usage.usageConsumption[0].counter.forEach((c) => {
				if (c.category === "Peak Download") {
					product.peakOffPeakType = true;
					product.usage.usageConsumption[0].peakAllowance = c.allowance;
				}
				if (c.category === "Off Peak Download") {
					product.peakOffPeakType = true;
					product.usage.usageConsumption[0].offPeakAllowance = c.allowance;
				}
			});
		} else {
			product.peakOffPeakType = false;
		}
	}

	reset() {
		this.products = [];
		this.billingAccounts = [];
	}

	updateUsageForProducts(productsWithUsage, allProduct): Observable<any> {
		this.products = allProduct;
		this.appStoreService.updateProducts(this.products);
		return (productsWithUsage.map(product => {
			if (product.bss === "shelley" && product.productGroupType === "Mobile") {
				this.appStoreService.updateUsageReqTimestamp(new Date().getTime());
				this.updateUsage(product);
			}
			return this.products;
		}));
	}

	updateUsageOfProduct(productId, usage) {
		this.appStoreService.updateUsageOfProduct(productId, usage);
	}
	updateUsage(product) {
		this.usageService
			.getUsage(
				product.services[0].serviceId,
				product.productGroupType
			)
			.subscribe((usage) => {
				if ("usageConsumption" in usage && usage.usageConsumption.length >= 0) {
					product.usage = usage;
				} else {
					product.usage = { error: true };
				}
				this.updateUsageOfProduct(product.id, product.usage);
			},
				(error) => {
					product.usage = { error: true };
					this.updateUsageOfProduct(product.id, product.usage);
				});
	}

	private handleDodoInvoices(invoices, product): void {
		if (invoices.error || invoices.bills.length === 0) {
			this.resetInvoicesAndPaymentDate(product);
		} else {
			this.invoices = this.filterValidInvoices(invoices.bills);
			if (this.invoices.length > 0) {
				this.setCurrentInvoiceAndPaymentDate(this.invoices[0], product);
			} else {
				this.resetInvoicesAndPaymentDate(product);
			}
		}
	}

	private filterValidInvoices(bills): any {
		return bills.filter(data =>
			data.statementStatus === "Sent" ||
			data.statementStatus === "Cancelled" ||
			data.statementStatus === null
		);
	}

	private setCurrentInvoiceAndPaymentDate(invoice, product): void {
		this.currentInvoice = invoice;
		product.nextPaymentDate = moment(invoice.dueDate).format('DD/MM/YYYY');
	}

	private resetInvoicesAndPaymentDate(product): void {
		this.invoices = [];
		this.currentInvoice = <IInvoice>{};
		product.nextPaymentDate = null;
	}

	private handleError(product): void {
		this.resetInvoicesAndPaymentDate(product);
	}

	private handleInvoices(invoices, product): void {
		if (invoices.bills?.length) {
			this.invoices = invoices.bills
				.filter((data) => data.statementStatus !== "Aborted")
				.sort((a, b) => b.dueDate.localeCompare(a.dueDate));
			this.currentInvoice = this.invoices[0];
			this.updateNextPaymentDate(product, this.currentInvoice);
		} else {
			this.resetInvoiceAndPaymentDate(product);
		}
	}

	private updateNextPaymentDate(product, currentInvoice): void {
		product.nextPaymentDate = moment(currentInvoice.dueDate).format('DD/MM/YYYY');
	}

	private resetInvoiceAndPaymentDate(product): void {
		this.currentInvoice = <IInvoice>{};
		product.nextPaymentDate = null;
	}
}
