import { Component } from "@angular/core";
import { Auth0Service } from "src/app/auth0/auth0.service";
import { environment } from "src/environment";

@Component({
	selector: "app-account-setup",
	templateUrl: "./account-setup.component.html",
	styleUrls: ["./account-setup.component.scss"]
})
export class AccountSetupComponent {
	public _email: string;
	public _passwordErrorMessage: string;
	public btnPwdLoader: boolean;
	public isPasswordPostSuccessful: boolean;
	public destination: string;
	public isEmailValid = false;
	public token: string | undefined;

	constructor(
		private __auth0Service: Auth0Service
	) {
		this.token = undefined;
	}

	public submitForgotPwdEmail(): void {
		this.btnPwdLoader = true;
		const data = {
			emailAddress: this._email,
			type: "Email",
			subject: "authResetPassword"
		};
		this.__auth0Service.resetPwdEmailSentStatus(data).subscribe(
			resetPwdResponse => {
				this.isPasswordPostSuccessful = true;
				this.btnPwdLoader = false;
				// Display resetPwdResponse.destination (registered) if available, else show unregistered email
				this.destination = resetPwdResponse.destination ?? this._email;
			},
			error => {
				this.isPasswordPostSuccessful = false;
				this.btnPwdLoader = false;
				console.log("Error:", error);
				if (error.error?.message) {
					this._passwordErrorMessage = error.error?.message;
				} else {
					this._passwordErrorMessage = "Sorry! We can't reset your Password at this time. Please try again later.";
				}
			}
		);
	}

	getSupportFormURL() {
		return `${environment.websiteHostServer}/online-support-request-my-dodo`;
	}

	onEmailChange(email: string): void {
		this._email = email.toLowerCase();

		const emailRegex = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
		this.isEmailValid = emailRegex.test(this._email);
	}
}
