import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

// model
import { IUsageDetails, UsageUnitOfMeasure } from "../models/usage-details.model";
import { environment } from "src/environment";

@Injectable({
  providedIn: "root"
})
export class UsageDetailsService {
  constructor(private httpClient: HttpClient) {}

  getUsageDetails(
	billingAccountNo,
	startDate,
	endDate,
	subscriptionNo
  ): Observable<any> {
	const _url = `${environment.apiURL}/api/v1/usagemgmt/usagedetails/${billingAccountNo}?fromDatetime='${startDate}'&toDatetime='${endDate}'&subscriptionNo=${subscriptionNo}`;
	return this.httpClient.get<any>(_url);
  }

  getDodoUsageDetails(
	serviceId,
	productCode,
	serviceType,
	intervalType,
	startDate,
	endDate
  ): Observable<IUsageDetails> {
	const _url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/usagemgmt/usagedetails?serviceId=${serviceId}&productCode=${productCode}&serviceType=${serviceType}&intervalType=${intervalType}&startDate=${startDate}&endDate=${endDate}`;
	return this.httpClient.get<IUsageDetails>(_url);
  }

  getTelcoUsageDetails(
	serviceId,
	productCode,
	serviceType,
	category,
	periodId
  ): Observable<any> {
	const _url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/usagemgmt/usagedetails?serviceId=${serviceId}&productCode=${productCode}&serviceType=${serviceType}&category=${category}&periodId=${periodId}`;
	return this.httpClient.get<any>(_url);
  }

  getMobileUsageDetails(
    billingAccountNo,
    endDate,
    subscriptionNo,
    startDate,
    usageMobileNumber,
    unitOfMeasure: UsageUnitOfMeasure
  ): Observable<any> {
    const _url = `${environment.apiURL}/api/v1/usagemgmt/usagedetails/${billingAccountNo}?toDatetime=${endDate}&subscriptionNo=${subscriptionNo}&fromDatetime=${startDate}&msn=${usageMobileNumber}&UnitOfMeasure=${unitOfMeasure}`;
    return this.httpClient.get<any>(_url);
  }

  getVoipUsageDetails(
	billingAccountNo,
	endDate,
	subscriptionNo,
	startDate
  ): Observable<any> {
	const _url = `${environment.apiURL}/api/v1/usagemgmt/usagedetails/${billingAccountNo}?toDatetime=${endDate}&subscriptionNo=${subscriptionNo}&fromDatetime=${startDate}&UnitOfMeasure=VOIPminute`;
	return this.httpClient.get<any>(_url);
  }
}
