import { Component, OnInit } from "@angular/core";
import { environment } from "src/environment";
import { Auth0Service } from "../../../auth0/auth0.service";
import { AppStoreService, AppStore } from "src/app/services/appStore.service";
import { Auth0MfaService } from "../../services/auth0-mfa.service";
import { IMfaContactMedium, IMfaVerifyLoginAttempts } from "../../models/mfa-contactmedium.model";
import { Router } from "@angular/router";
import { UUID } from "angular2-uuid";
@Component({
	selector: "app-auth0-mfa",
	templateUrl: "./auth0-mfa.component.html"
})
export class Auth0MfaComponent implements OnInit {
	public mfaContactlist: IMfaContactMedium[] = [];
	mfaContactlistError = true;

	public cntLoginAttempts;
	public externalIdValue = undefined;
	public _appStore: AppStore;

	constructor(
		private appStoreService: AppStoreService,
		private __auth0Service: Auth0Service,
		private auth0MfaService: Auth0MfaService,
		private router: Router
	) { }

	ngOnInit() {
		this._appStore = this.appStoreService.store;
		if (this._appStore?.mfaMediums !== undefined) {
			if (this._appStore?.mfaMediums.length > 0) {
				this.mfaContactlist = this._appStore.mfaMediums[0].contactMedium;
				this.mfaContactlistError = false;
			} else {
				window.location.replace("auth0-mfa/mfa-error");
			}
		} else {
			window.location.replace("auth0-mfa/mfa-error");
		}
	}

	sendMFAcodebySMS(mobileNo, mobileMediumCode, mobileMediumType, event: Event) {
		const eventsrcElement = event.target as HTMLElement;
		eventsrcElement.innerHTML = `<span class='btn-preloader'>${mobileNo}</span>`;
		const mfaVerifyLoginAttempts = <IMfaVerifyLoginAttempts>{};
		if (this._appStore?.mfaVerifyLoginAttempts !== undefined) {
			mfaVerifyLoginAttempts.mobileNo = mobileNo;
			mfaVerifyLoginAttempts.mobileMediumCode = mobileMediumCode;
			mfaVerifyLoginAttempts.mediumType = mobileMediumType;
			this.externalIdValue = this._appStore.mfaVerifyLoginAttempts.externalId;
		} else {
			mfaVerifyLoginAttempts.mobileNo = mobileNo;
			mfaVerifyLoginAttempts.mobileMediumCode = mobileMediumCode;
			mfaVerifyLoginAttempts.mediumType = mobileMediumType;

			if (this.externalIdValue === undefined) {
				this.externalIdValue = UUID.UUID();
				mfaVerifyLoginAttempts.externalId = this.externalIdValue;
			}
		}
		const inputDataMobile = {
			content: "OTP",
			messageType: "SMS",
			receiver: [
				{
					phoneNumber: mobileMediumCode
				}
			],
			characteristic: [
				{
					name: "externalId",
					value: this.externalIdValue
				}
			]
		};
		if (this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts === undefined || this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts === null) {
			this.cntLoginAttempts = 0;
		} else {
			this.cntLoginAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
		}
		if (this.cntLoginAttempts <= 2) {
			this.auth0MfaService.triggerMFAcodebySMS(inputDataMobile).subscribe(
				sendCodeResponse => {
					if (sendCodeResponse.state === "complete") {
						if (this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts !== undefined) {
							if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 1 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
								this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							} else
								if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 2 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
									this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								} else {
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								}
						} else {
							this.cntLoginAttempts = this.cntLoginAttempts + 1;
							mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
						}
						this.appStoreService.updMfaLoginAttempts(mfaVerifyLoginAttempts);
						eventsrcElement.innerHTML = `<span>${mobileNo}</span>`;
						this.router.navigate(["/auth0-mfa/mfa-code"]);
					} else {
						eventsrcElement.innerHTML = `<span>${mobileNo}</span>`;
						this.router.navigate(["/auth0-mfa/mfa-error"]);
					}
				},
				error => {
					if (this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts !== undefined) {
						if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 1 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
							this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
							this.cntLoginAttempts = this.cntLoginAttempts + 1;
							mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
						} else
							if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 2 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
								this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							} else {
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							}
					} else {
						this.cntLoginAttempts = this.cntLoginAttempts + 1;
						mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
					}
					this.appStoreService.updMfaLoginAttempts(mfaVerifyLoginAttempts);
					eventsrcElement.innerHTML = `<span>${mobileNo}</span>`;
					window.location.replace("auth0-mfa/mfa-error");
				}
			);
		} else {
			this.router.navigate(["/auth0-mfa/mfa-code-error"]);
		}
	}

	sendMFAcodebyEmail(emailAddress, emailMediumCode, emailMediumType, event: Event) {
		const eventsrcElement = event.target as HTMLElement;
		eventsrcElement.innerHTML = `<span class='btn-preloader'>${emailAddress}</span>`;
		const mfaVerifyLoginAttempts = <IMfaVerifyLoginAttempts>{};
		if (this._appStore?.mfaVerifyLoginAttempts !== undefined) {
			mfaVerifyLoginAttempts.emailAddress = emailAddress;
			mfaVerifyLoginAttempts.emailMediumCode = emailMediumCode;
			mfaVerifyLoginAttempts.mediumType = emailMediumType;

			this.externalIdValue = this._appStore.mfaVerifyLoginAttempts.externalId;
		} else {
			mfaVerifyLoginAttempts.emailAddress = emailAddress;
			mfaVerifyLoginAttempts.emailMediumCode = emailMediumCode;
			mfaVerifyLoginAttempts.mediumType = emailMediumType;

			if (this.externalIdValue === undefined) {
				this.externalIdValue = UUID.UUID();
				mfaVerifyLoginAttempts.externalId = this.externalIdValue;
			}
		}
		const inputDataEmail = {
			content: "OTP",
			messageType: "Email",
			receiver: [
				{
					emailAddress: emailMediumCode
				}
			],
			characteristic: [
				{
					name: "externalId",
					value: this.externalIdValue
				}
			]
		};
		if (this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts === undefined || this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts === null) {
			this.cntLoginAttempts = 0;
		} else {
			this.cntLoginAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
		}
		if (this.cntLoginAttempts <= 2) {
			this.auth0MfaService.triggerMFAcodebyEmail(inputDataEmail).subscribe(
				sendCodeResponse => {
					if (sendCodeResponse.state === "complete") {
						if (this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts !== undefined) {
							if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 1 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
								this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							} else
								if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 2 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
									this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								} else {
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								}
						} else {
							this.cntLoginAttempts = this.cntLoginAttempts + 1;
							mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
						}
						this.appStoreService.updMfaLoginAttempts(mfaVerifyLoginAttempts);
						eventsrcElement.innerHTML = `<span>${emailAddress}</span>`;
						this.router.navigate(["/auth0-mfa/mfa-code"]);
					} else {
						eventsrcElement.innerHTML = `<span>${emailAddress}</span>`;
						this.router.navigate(["/auth0-mfa/mfa-error"]);
					}
				},
				error => {
					if (this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts !== undefined) {
						if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 1 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
							this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
							this.cntLoginAttempts = this.cntLoginAttempts + 1;
							mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
						} else
							if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 2 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
								this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							} else {
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							}
					} else {
						this.cntLoginAttempts = this.cntLoginAttempts + 1;
						mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
					}
					this.appStoreService.updMfaLoginAttempts(mfaVerifyLoginAttempts);
					eventsrcElement.innerHTML = `<span>${emailAddress}</span>`;
					window.location.replace("auth0-mfa/mfa-error");
				}
			);
		} else {
			this.router.navigate(["/auth0-mfa/mfa-code-error"]);
		}
	}

	goMFAsupportForm() {
		return `${environment.websiteHostServer}/online-support-request-my-dodo`;
	}

	resetAuth0Tokens() {
		this.__auth0Service.resetAuth0Tokens();
		this.appStoreService.flushAppStoreLocalStorage();
	}
}
