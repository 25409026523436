import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// model
import { ICustomerID } from "../models/account-details.model";
import { IContactsRelatedParty } from "../models/account-details.model";

// services
import { HttpService } from "src/libraries/api/services/http.service";
import { environment } from "src/environment";

@Injectable({
  providedIn: "root"
})
export class AccountDetailsService {

	selectedConcessionType: string;
	selectedPaymenetBenefitType: string;
  constructor(
	private httpClient: HttpClient,
	private httpService: HttpService
  ) { }

  updateBillingAccount(accountId: string, id: string, data: any) {
	  const url = `${environment.apiURL}/api/v1/accountmgmt/accounts/${accountId}/billingAccounts/${id}`;
	  return this.httpService.patch(url, data);
  }

  getContactsRelatedParty(
	accountId: string
  ): Observable<IContactsRelatedParty[]> {
	console.log("passed MAIN accountId:", accountId);
	const _url = `${environment.apiURL}/api/v1/customermgmt/customer?accountId=${accountId}`;
	return this.httpService.get<IContactsRelatedParty[]>(_url);
  }

  // Self care for mobile only Patch update for all contacts including Address
  updateContactDetails(currentCid, data) {
	const url = `${environment.apiURL}/api/v1/customermgmt/customer/${currentCid}`;
	return this.httpService.patch(url, data);
  }

  // Product id is customer id for selected product
	getCustomeridDetails(currentCid: string): Observable<ICustomerID> {
		const _url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/customermgmt/customer?currentCid=${currentCid}`;
		return this.httpClient
			.get<any>(_url)
			.pipe(
			map(val =>
				val
				? val.filter(
					customer => customer.id.toString() === currentCid
					)[0] || <ICustomerID>{}
				: <ICustomerID>{}
			)
			);
	  }

  // Patch update for all contacts including Address
  updateDodoContactDetails(currentCid, relatedPartyId, contactMediumId, data) {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/customermgmt/customer/${currentCid}/individual/${relatedPartyId}/contactMedium/${contactMediumId}`;
	return this.httpClient.patch(url, data);
  }

	getDodoContactsRelatedParty(
		currentCid: string,
		relatedPartyId: string
  	): Observable<IContactsRelatedParty> {
		console.log("passed relatedPartyId:", relatedPartyId);
		const _url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/customermgmt/customer/${currentCid}/individual/${relatedPartyId}`;
		return this.httpClient.get<IContactsRelatedParty>(_url);
  	}

  // Add new contacts including Address
  postNewContactDetails(currentCid, relatedPartyId, data) {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/customermgmt/customer/${currentCid}/individual/${relatedPartyId}/contactMedium`;
	return this.httpClient.post(url, data);
  }

	public validateConcessionNumber(customerId: string, data: any): Observable<any> {
		data.type = data.concessionType === "Card" ? this.selectedConcessionType : this.selectedPaymenetBenefitType;
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/concession/validate`;
		return this.httpService.post(url, data);
	}

	public getConcessionPaymentBenfitDetails(customerId: string): Observable<any> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/concession/types`;
		return this.httpService.get<any>(url);
	}

	public saveConcessionPaymentBenefitDetails(data: any, customerId: string): Observable<any> {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/concession`;
	return this.httpClient.post(url, data);
	}

	public getAddedConcessionPaymenetBenefitDetails(customerId: string, card: string): Observable<any> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/concession?concessionType=${card}`;
		return this.httpService.get<any>(url);
	}

	public setSelectedConcessionType(typeId: string) {
		this.selectedConcessionType = typeId;
	}

	public setSelectedPaymentBenefitType(typeId: string) {
		this.selectedPaymenetBenefitType = typeId;
	}
}
