<section class="authcustomer">
  <nav class="__navigation"></nav>
  <section class="_banner">
    <div class="container">
      <section class="__form">
        <div class="row">
          <div class="col-lg-6 col-md-12 relative">
            <img src="assets/images/authentication/circle-white.svg" class="pattern left" alt="" />
            <img src="assets/images/authentication/circle-green.svg" class="pattern right" alt="" />
            <section class="sign-in">
              <img src="assets/images/authentication/corner-circle-green.svg" class="pattern inner" alt="" />
              <h2 class="my5 txt-grey-darker font-semibold">
                {{ myAccountLoginHeader }}
              </h2>
              <span class="___info">Please enter your Contact number listed with dodo OR Account
                number provided to you on your welcome email and your date of
                birth to retrieve your account.
              </span>
              <form #authcustomerForm="ngForm" novalidate class="my5">
                <section class="dodo-unauth-alert p3" *ngIf="apiError">
                  <i class="block fas fa-exclamation-circle mr2"></i>
                  <span class="block">{{ message }}</span>
                </section>
                <div class="form-grp">
                  <mat-form-field>
                    <input matInput placeholder="Contact Number/ Service Number/ Customer Id" type="integer"
                      #contactservicecustomerid="ngModel" [(ngModel)]="customerlogin.contactservicecustomerid"
                      name="contactservicecustomerid" maxlength="10" minlength="6" required pattern="[0-9]*"
                      [class.invalid]="
                        contactservicecustomerid?.invalid &&
                        contactservicecustomerid?.touched
                      " [class.valid]="
                        contactservicecustomerid?.valid &&
                        contactservicecustomerid?.touched
                      " />
                  </mat-form-field>
                  <small class="indicators block txt-xs" [class.hide]="
                      contactservicecustomerid?.valid ||
                      contactservicecustomerid?.untouched
                    " *ngIf="contactservicecustomerid?.errors?.required">Contact number/ Service Number/ Customer Id is
                    required
                  </small>

                  <small class="indicators block txt-xs" [class.hide]="
                      contactservicecustomerid?.valid ||
                      contactservicecustomerid?.untouched
                    " *ngIf="contactservicecustomerid?.errors?.pattern">You can only enter numbers
                  </small>
                </div>
                <div class="_date-selection">
                  <div class="__datepicker">
                    <mat-form-field class="example-full-width customise-datepicker font-brand">
                      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                        placeholder="date of birth dd/mm/yyyy" class="txt-black txt-base"
                        [(ngModel)]="customerlogin.customerdob" #customerdob="ngModel" name="customerdob" required />
                      <mat-datepicker-toggle matSuffix [for]="picker">
                        <mat-icon matDatepickerToggleIcon><img class="icon-absolute"
                            src="assets/images/icons/icon-datepicker.svg" /></mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-grp">
                  <button [disabled]="authcustomerForm.form.invalid" (click)="onClickMyPayBill_plan_ActStat_navigate()"
                    class="btn brand-btn-info screen-wide relative"
                    [class.brand-btn-secondary]="authcustomerForm.form.valid">
                    <span *ngIf="!btnProloader">{{
                      myAccountLoginBtntxt
                    }}</span>
                    <span *ngIf="btnProloader" class="btn-preloader"></span>
                  </button>
                </div>
              </form>
            </section>
            <img class="illustration lg-only" src="assets/images/authentication/banner.png" alt="" />
            <img class="lg-only pattern bottom" src="assets/images/authentication/pattern.svg" alt="" />
          </div>
        </div>
      </section>
    </div>
  </section>
</section>