import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MyOrderStatusComponent } from "./my-order-status.component";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [MyOrderStatusComponent]
})
export class MyOrderStatusModule {}
