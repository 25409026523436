import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppStoreService, APP_EVENT } from "./appStore.service";
import { AuthenticationService } from "./authentication.service";
import { IUrlDeeplinkDetails } from "src/app/self-serve/models/store.model";
import { filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Injectable({
	providedIn: "root"
})
export class DeepLinkingService {
	destroyed$ = new Subject<boolean>();
	constructor(
		private appStoreService: AppStoreService,
		private authenticationService: AuthenticationService,
		private router: Router
	) { }

	public storeDeeplink(sourceUrlPath: string) {
		let sourceUrlPathID = "";
		if (this.router.url.indexOf(sourceUrlPath) === 0 && this.appStoreService.store.urlDeeplinkDetails === undefined) {
			// deeplinking to handle links with ID passed
			const pathValue = this.router.url.split("/");
			console.log("pathValue:", pathValue, "pathLength", pathValue.length);
			if (pathValue.length === 3) {
				sourceUrlPathID = pathValue[2];
			} else if (pathValue.length === 4) {
				sourceUrlPathID = pathValue[3];
			} else if (pathValue.length === 2) {
				sourceUrlPathID = "0";
			} else {
				return;
			}
			const urlDeeplinkDetails = <IUrlDeeplinkDetails>{
				sourceUrlPath: this.router.url,
				sourceUrlPathID: sourceUrlPathID
			};
			this.appStoreService.updateUrlDeeplinkDetails(urlDeeplinkDetails);
			console.log("deeplinking.service.ts::", "DPGOnlyflg::", this.appStoreService.store?.DPGOnlyflg, "apiMfaContactsError::", this.appStoreService?.store.apiMfaContactsError);
			if (this.appStoreService.store?.DPGOnlyflg === false  && this.appStoreService.store?.apiMfaContactsError === false) {
				console.log("deeplinking.service.ts:: DPGOnlyflg false logic");
				if (this.authenticationService.isLoggedIn()) {
					if (this.appStoreService.store.mfaVerifyCodeEntryAttempts?.mfaLogin !== undefined) {
						if (this.appStoreService.store.mfaVerifyCodeEntryAttempts.mfaLogin === true) {
							console.log(" MFA:router.url in deeplinking.service.ts check::", this.router.url);
						} else if (this.appStoreService.store.mfaVerifyCodeEntryAttempts.mfaLogin === false) {
								this.resetDLauth0();
						}
					} else  {
						this.resetDLauth0();
				}
				} else {
					this.resetDLauth0();
				}
			} else if (this.appStoreService.store?.DPGOnlyflg === true  && this.appStoreService.store?.apiMfaContactsError === false) {
				console.log("deeplinking.service.ts:: DPGOnlyflg TRUE logic");
				if (this.authenticationService.isLoggedIn()) {
					console.log("DPG true NO-MFA router.url check::", this.router.url);
				} else {
					this.resetDLauth0();
				}
			} else { // someone comes with a direct link, example: my.dodo.com/order-status just redirect to login
				if (this.authenticationService.isLoggedIn()) {
					console.log("someone comes with a direct link, example: my.dodo.com/order-status just redirect to login::", this.router.url);
				} else {
					this.resetDLauth0();
				}

			}
		}
	}

	public openDeeplink() {
		this.appStoreService.updateDeeplinkRedirect(false);
		if (this.appStoreService.store.urlDeeplinkDetails !== undefined) {
			if (this.appStoreService.store.urlDeeplinkDetails.sourceUrlPath.indexOf(this.appStoreService.store.urlDeeplinkDetails.sourceUrlPath) === 0) {
				this.appStoreService.storeUpdated
					.pipe(filter(event => event === APP_EVENT.DEEPLINK_REDIRECT_FLG),
					takeUntil(this.destroyed$))
					.subscribe(() => {
					 window.location.replace(this.appStoreService.store.urlDeeplinkDetails.sourceUrlPath);
				});
			}
		}
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	private resetDLauth0() {
		this.router.navigate([`/login`]);
	  }
}
