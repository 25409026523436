import { Injectable } from "@angular/core";

function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: "root"
})
export class LocalStorageService {
  private isLocalStorageSupported = true;

  constructor() {
	// this.isLocalStorageSupported = typeof Storage !== "undefined";
  }

  public set(key: string, item: any): boolean {
	if (this.isLocalStorageSupported) {
		const type = typeof item;
		sessionStorage.setItem(`${key}-type`, type);
		if (type === "object") {
		item = JSON.stringify(item);
		}
		sessionStorage.setItem(key, item);
		return true;
	}
	return false;
  }

  public get(key: string): any {
	if (this.isLocalStorageSupported) {
		const type = sessionStorage.getItem(`${key}-type`);
		let item = sessionStorage.getItem(key);
		if (item && item !== undefined) {
		if (type === "object") {
			item = JSON.parse(item);
		}
		return item;
		}
	}
	return null;
  }

  public delete(key: string) {
	if (this.isLocalStorageSupported) {
		sessionStorage.removeItem(key);
		sessionStorage.removeItem(`${key}-type`);
		return true;
	}
	return false;
  }
}
