import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule, Route } from "@angular/router";

// Material components
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSelectModule } from "@angular/material/select";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";

// components
import { PayBillComponent } from "./pay-bill.component";
import { PayBillLoginComponent } from "./pay-bill-login/pay-bill-login.component";

import { PayBillSmsComponent } from "./pay-bill-sms.component";
import { MaintenanceGuard } from "src/app/maintenance.guard";

const routes: Route[] = [
	{
		path: "pay-my-bill",
		redirectTo: "/"
		// pathMatch: "full"
		// component: PayBillComponent,
		// canActivate: [MaintenanceGuard],
		// data: {
		// 	breadcrumb: "Pay my Bill"
		// },
		// children: [
		// 	{
		// 		path: "",
		// 		component: PayBillLoginComponent,
		// 		data: {
		// 			breadcrumb: "pay-bill-login"
		// 		}
		// 	}
		// ]
	},
	{
		// /:encrypted_contactservicecustomerid_dob
		path: "pay-bill",
		component: PayBillSmsComponent,
		data: {
			breadcrumb: "Pay my Bill using SMS URL"
		}
	}
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		MatSelectModule,
		MatNativeDateModule,
		MatIconModule,
		MatDatepickerModule,
		RouterModule,
		RouterModule.forChild(routes)
	],
	declarations: [PayBillComponent, PayBillLoginComponent, PayBillSmsComponent]
})
export class PayBillModule {}
