import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

// services
import { AuthcustomerService } from "src/app/services/authcustomer.service";
import { AppStoreService } from "src/app/services/appStore.service";

// Interfaces
import { ICustomerUnAuth } from "src/app/self-serve/models/store.model";

// store
import { Customerlogin } from "src/app/authentication/customerlogin";

@Component({
	selector: "dodo-pay-bill-sms",
	templateUrl: "./pay-bill-sms.component.html"
})
export class PayBillSmsComponent implements OnInit {
	public encrypted_contactservicecustomerid_dob = "";
	apiError = false;
	btnProloader = false;
	message = "";
	customerLoginError = false;
	btnPreloader: boolean;
	customerlogin = new Customerlogin("", "", "");
	constructor(
		public router: Router,
		public route: ActivatedRoute,
		private _authcustomerService: AuthcustomerService,
		private appStoreService: AppStoreService
	) { }

	ngOnInit() {
		// get	values	from	query	string
		this.encrypted_contactservicecustomerid_dob = this.route.snapshot.queryParamMap.get(
			"referenceid"
		);
		// The	querystring	puts	space	for	"+"	so	replaced	back	with	"+"
		this.encrypted_contactservicecustomerid_dob = this.encrypted_contactservicecustomerid_dob
			.trim()
			.replace(/\s/g, "+");

		if (this.encrypted_contactservicecustomerid_dob) {
			this.customerlogin.sourceurl = "pay-my-bill-sms";
			this.authenticateSMScustomer();
		}
	}

	authenticateSMScustomer() {
		this.btnProloader = true;
		if (!this.validateSMScustomer()) {
			return;
		}

		this._authcustomerService
			.authenticateSMScustomer(this.encrypted_contactservicecustomerid_dob)
			.subscribe(allProductsunauth => {
				if (allProductsunauth && allProductsunauth.length >= 1) {
					this.setSMScustomerProductInfo();	// Save	customer	info	in	app	store
					this.appStoreService.updateProductsUnAuth(allProductsunauth);	// Update	productsunauth	in	appStore
					this.btnProloader = false;
					this.router.navigate(["/select-services"]);
				} else {
					this.apiError = true;
					this.btnProloader = false;
					this.message =
						"Currently	you	don\"t	have	any	services	to	pay	your	bill";
				}
			},
				error => {
					if (error.status === 400) {
						this.apiError = false;
						this.customerLoginError = true;
						this.btnProloader = false;
					} else if (error.status === 401) {
						this.customerLoginError = false;
						this.apiError = true;
						this.message = "Your	Customerid		or	DOB	is	incorrect.";
						this.btnProloader = false;
					} else {
						this.customerLoginError = false;
						this.apiError = true;
						this.message =
							"Unfortunately	My	Dodo	is	offline	at	the	moment.	Please	try	again	later.";
						this.btnProloader = false;
					}
				}
			);
	}

	validateSMScustomer() {
		return true;
	}

	/*
		1)	Save	customerid	to	be	used	across	in	appStore
		2)	Also	store	the	retrived	details	in	IProductUnAuth	Interface
		3)	Get	the	stored	Products	in	the	next	page
	*/
	setSMScustomerProductInfo() {
		const customerunauth = <ICustomerUnAuth>{
			encrypted_contactservicecustomerid_dob: this
				.encrypted_contactservicecustomerid_dob,
			sourceurl: this.customerlogin.sourceurl
		};
		this.appStoreService.updateCustomerUnAuth(customerunauth);
	}
}
