// angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// module
import { CommomComponentModule } from "src/app/common/commom-component.module";
import { SelfServeComponentsModule } from "../components/components.module";

// components
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ProductComponent } from "./product/product.component";
import { SelfServeComponent } from "./self-serve/self-serve.component";
import { BillOverviewComponent } from "./bill/bill-overview/bill-overview.component";
import { BillDetailComponent } from "./bill/bill-detail/bill-detail.component";
import { PaymentOverviewComponent } from "./wallet/payment-overview/payment-overview.component";
import { PaymentDetailComponent } from "./wallet/payment-detail/payment-detail.component";
import { DodoPaymentDetailComponent } from "./wallet/payment-detail/dodo-payment-detail.component";
import { MakeAPaymentComponent } from "./payment/make-a-payment/make-a-payment.component";
import { PaymentAmountComponent } from "./payment/payment-amount/payment-amount.component";
import { PaymentTypeComponent } from "./payment/payment-type/payment-type.component";
import { PaymentSummaryComponent } from "./payment/payment-summary/payment-summary.component";
import { PaymentSuccessfulComponent } from "./payment/payment-successful/payment-successful.component";
import { PaymentComponent } from "./payment/payment.component";
import { UsageComponent } from "./usage/usage.component";
import { UsageDetailsComponent } from "./usage-details/usage-details.component";
import { PaymentPlanComponent } from "./payment-plan/payment-plan.component";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { MailBoxComponent } from "./mail-box/mail-box.component";
import { MailboxEditComponent } from "./mail-box/mailbox-edit/mailbox-edit.component";
import { MailboxAddComponent } from "./mail-box/mailbox-add/mailbox-add.component";

// import { PaymentPlanIntroComponent } from './payment-plan/payment-plan-intro/payment-plan-intro.component';
import { InstalmentPlanComponent } from "./instalment-plan/instalment-plan.component";
import { InstalmentPlanIntroComponent } from "./instalment-plan/instalment-plan-intro/instalment-plan-intro.component";
import { InstalmentPlanUpdateComponent } from "./instalment-plan/instalment-plan-update/instalment-plan-update.component";
import { InstalmentPlanSummaryComponent } from "./instalment-plan/instalment-plan-summary/instalment-plan-summary.component";

// Material components
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { GetStartedComponent } from "./payment-plan/get-started/get-started.component";
import { SelectServiceComponent } from "./payment-plan/select-service/select-service.component";
import { SelectOptionComponent } from "./payment-plan/select-option/select-option.component";
import { PaymentPlanOptionsComponent } from "./payment-plan/payment-plan-options/payment-plan-options.component";
import { PaymentExtensionOptionsComponent } from "./payment-plan/payment-extension-options/payment-extension-options.component";
import { IntroComponent } from "./payment-plan/intro/intro.component";
import { AccountIntroComponent } from "./account-details/account-intro/account-intro.component";
import { MailboxIntroComponent } from "./mail-box/mailbox-intro/mailbox-intro.component";
import { AccountPersonalComponent } from "./account-details/account-personal/account-personal.component";
import { PaymentPlanConfirmationComponent } from "./payment-plan/payment-plan-confirmation/payment-plan-confirmation.component";

import { TealiumModule } from "src/tealium/tealium.module";
import { CreditRefundComponent } from "./credit-refund/credit-refund.component";
import { RefundBalanceComponent } from "./credit-refund/refund-balance/refund-balance.component";
import { RefundGetStartedComponent } from "./credit-refund/refund-get-started/refund-get-started.component";
import { RefundRequestComponent } from "./credit-refund/refund-request/refund-request.component";
import { RefundConfirmationComponent } from "./credit-refund/refund-confirmation/refund-confirmation.component";
import { CreditOverviewComponent } from "./credit-refund/credit-overview/credit-overview.component";
import { TrackOrderComponent } from "./track-order/track-order.component";
import { TrackOrderDetailsComponent } from "./track-order/track-order-details/track-order-details.component";
import { TrackOrderCrdComponent } from "../components/track-order/track-order-crd/track-order-crd.component";
import { TrackOrderAppointmentComponent } from "../components/track-order/track-order-appointment/track-order-appointment.component";
import { TrackOrderOverviewComponent } from "./track-order/track-order-overview/track-order-overview.component";
import { TrackOrderStatusComponent } from "./track-order/track-order-status/track-order-status.component";
import { SupportRequestComponent } from "./support-request/support-request.component";
import { SupportRequestListComponent } from "./support-request/support-request-list/support-request-list.component";
import { SupportRequestServicesComponent } from "./support-request/services/support-request.component";
import { SupportRequestCreateComponent } from "./support-request/support-request-create/support-request-create.component";
import { SupportRequestDetailsComponent } from "./support-request/support-request-details/support-request-details.component";
import { SupportRequestSuccessComponent } from "./support-request/support-request-success/support-request-success.component";
import { MailboxListComponent } from "./mail-box/mailbox-list/mailbox-list.component";
import { SubscriptionComponent } from "./subscription/subscription.component";
import { UsageAlertComponent } from "./usage-alert/usage-alert.component";
import { AddonsComponent } from "./addons/addons.component";
import { DodoAddonsComponent } from "src/app/self-serve/containers/addons/dodo-addons.component";
import { PaymentHelpConfirmationComponent } from "./payment-plan/payment-help-confirmation/payment-help-confirmation.component";
import { OrderStatusHistoryComponent } from "./track-order/order-status-history/order-status-history.component";
import { Auth0MfaComponent } from "./auth0-mfa/auth0-mfa.component";
import { MfaCodeComponent } from "./auth0-mfa/mfa-code/mfa-code.component";
import { MfaErrorComponent } from "./auth0-mfa/mfa-error/mfa-error.component";
import { MfaCodeErrorComponent } from "./auth0-mfa/mfa-code-error/mfa-code-error.component";
import { MfaNocontactsErrorComponent } from "./auth0-mfa/mfa-nocontacts-error/mfa-nocontacts-error.component";
import { MfaBillitComponent } from "./auth0-mfa/mfa-billit/mfa-billit.component";
import { ChgUsrNameComponent } from "src/app/self-serve/containers/account-details/chg-usr-name/chg-usr-name.component";
import { ChangePlanComponent } from "./change-plan/change-plan.component";
import { NbnChangePlanComponent } from "./nbn-change-plan/nbn-change-plan.component";
import { MoreInfoDialogComponent } from "./nbn-change-plan/more-info-dialog/more-info-dialog.component";
import { InfoDialogLayoutComponent } from "./nbn-change-plan/info-dialog-layout/info-dialog-layout.component";
import { AddonsPaymentComponent } from "./addons/addons-payment/addons-payment.component";
import { MaterialFormsModule } from "src/app/common/material-form/material-forms.module";
import { DodoButtonsModule } from "src/app/common/buttons/buttons.module";
import { ManualAddressModule } from "src/app/common/manual-address/manual-address.module";
import { UsageDetailsIddComponent } from "./usage-details/usage-details-idd.component";
import { NbnChangePlanBillitComponent } from "./nbn-change-plan-billit/nbn-change-plan-billit.component";
import { MoreInfoDialogBillitComponent } from "./nbn-change-plan-billit/more-info-dialog-billit/more-info-dialog-billit.component";
import { VoipChangePlanComponent } from "./voip-change-plan/voip-change-plan.component";
import { UsageDetailsVoipComponent } from "./usage-details/usage-details-voip.component";
import { LinkifyPipe } from "./support-request/support-request-details/linkify.pipe";

@NgModule({
  imports: [
	CommonModule,
	CommomComponentModule,
	RouterModule,
	SelfServeComponentsModule,
	MatInputModule,
	MatSelectModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatIconModule,
	MatGridListModule,
	ReactiveFormsModule,
	FormsModule,
	TealiumModule,
	MaterialFormsModule,
	DodoButtonsModule,
	ManualAddressModule
  ],
  declarations: [
	DodoPaymentDetailComponent,
	DashboardComponent,
	ProductComponent,
	SelfServeComponent,
	BillOverviewComponent,
	BillDetailComponent,
	UsageComponent,
	PaymentOverviewComponent,
	PaymentDetailComponent,
	MakeAPaymentComponent,
	PaymentAmountComponent,
	PaymentTypeComponent,
	PaymentSummaryComponent,
	PaymentSuccessfulComponent,
	PaymentComponent,
	UsageDetailsComponent,
	PaymentPlanComponent,
	AccountDetailsComponent,
	MailBoxComponent,
	MailboxEditComponent,
	MailboxAddComponent,
	// PaymentPlanIntroComponent,
	InstalmentPlanComponent,
	InstalmentPlanIntroComponent,
	InstalmentPlanUpdateComponent,
	InstalmentPlanSummaryComponent,
	GetStartedComponent,
	SelectServiceComponent,
	SelectOptionComponent,
	PaymentPlanOptionsComponent,
	PaymentExtensionOptionsComponent,
	IntroComponent,
	AccountIntroComponent,
	AccountPersonalComponent,
	MailboxIntroComponent,
	PaymentPlanConfirmationComponent,
	PaymentHelpConfirmationComponent,
	CreditRefundComponent,
	RefundBalanceComponent,
	RefundGetStartedComponent,
	RefundRequestComponent,
	RefundConfirmationComponent,
	CreditOverviewComponent,
	TrackOrderComponent,
	TrackOrderStatusComponent,
	TrackOrderDetailsComponent,
	TrackOrderCrdComponent,
	TrackOrderAppointmentComponent,
	TrackOrderOverviewComponent,
	OrderStatusHistoryComponent,
	SupportRequestComponent,
	SupportRequestListComponent,
	SupportRequestServicesComponent,
	SupportRequestCreateComponent,
	SupportRequestDetailsComponent,
	SupportRequestSuccessComponent,
	MailboxListComponent,
	SubscriptionComponent,
	UsageAlertComponent,
	AddonsComponent,
	DodoAddonsComponent,
	Auth0MfaComponent,
	MfaCodeComponent,
	MfaErrorComponent,
	MfaCodeErrorComponent,
	MfaNocontactsErrorComponent,
	MfaBillitComponent,
	ChgUsrNameComponent,
	ChangePlanComponent,
	NbnChangePlanComponent,
	MoreInfoDialogComponent,
	InfoDialogLayoutComponent,
	AddonsPaymentComponent,
	UsageDetailsIddComponent,
	UsageDetailsVoipComponent,
	NbnChangePlanBillitComponent,
	MoreInfoDialogBillitComponent,
	VoipChangePlanComponent,
	LinkifyPipe
  ],
  exports: [
	DodoPaymentDetailComponent,
	DashboardComponent,
	ProductComponent,
	SelfServeComponent,
	BillOverviewComponent,
	BillDetailComponent,
	PaymentOverviewComponent,
	PaymentDetailComponent,
	PaymentPlanComponent,
	GetStartedComponent,
	SelectServiceComponent,
	SelectOptionComponent,
	PaymentPlanOptionsComponent,
	PaymentExtensionOptionsComponent,
	AccountIntroComponent,
	AccountPersonalComponent,
	MailboxIntroComponent,
	MailboxEditComponent,
	MailboxAddComponent,
	PaymentPlanComponent,
	GetStartedComponent,
	Auth0MfaComponent,
	MfaCodeComponent,
	MfaErrorComponent,
	MfaCodeErrorComponent,
	MfaNocontactsErrorComponent,
	MfaBillitComponent,
	MoreInfoDialogComponent,
	InfoDialogLayoutComponent,
	ChangePlanComponent,
	NbnChangePlanComponent,
	AddonsPaymentComponent,
	NbnChangePlanBillitComponent,
	MoreInfoDialogBillitComponent,
	VoipChangePlanComponent,
	LinkifyPipe
  ]
})
export class SelfServeContainersModule {}
