<div class="InProgressSummary" *ngIf="productSelected; else loading">
  <div class="__information pb2">
    <div class="___disclaimer p1 mt2 font-brand">
      <i class="fal fa-exclamation-circle"></i>
      <span *ngIf="preferredPaymenttypeFlg">
         Your payment plan instalments will be charged on the agreed dates.
      </span>
      If you wish to make a payment now, select the instalments you would like
      to pay and click the PAY NOW button.
    </div>
  </div>
  <form #contactDetailsForm="ngForm" novalidate>
    <div class="InProgressSummary modal-full-background">
    <div class="InProgressSummary modal-body _summary-details">
      <div
        class="__heading flex flex-justify-content-between txt-color-primary font-brand font-medium pt2 pb2"
      >
        <span class="___item font-brand">Instalment Date</span>
        <span class="___item font-brand">Amount</span>
        <span class="___item font-brand">{{ isOnePlatform ? 'Status' : 'Pay Now' }}</span>
      </div>
      <div
        class="__values flex flex-justify-content-between txt-black pt2 pb2"
        *ngFor="
          let summary of productSelected.payment.inProgressPlanSummary;
          index as i
        "
      >
        <span class="___item">{{ summary.dueDate | date: "dd/MM/yyyy" }}</span>
        <span class="___item"
          >${{ summary.amount.value | number: "0.2-2" }}</span
        >
        <span class="___item" *ngIf="isOnePlatform">
          {{ summary.status }}
        </span>
        <span class="___item" *ngIf="!isOnePlatform">
          <div
            class="flex flex-align-items-center flex-justify-content-center pl4"
            *ngIf="index !== summary.id"
          >
            <label class="container-checkbox">
              <input
                type="checkbox"
                [ngModel]="summary.isChecked"
                [ngModelOptions]="{ standalone: true }"
                [id]="['flgmakePayforInstalmentPlan' + i]"
                (ngModelChange)="checkPaymentItem(summary, i, $event)"
                [disabled]="summary.disabled"
                [checked]="summary.isChecked"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div
            class="flex flex-align-items-center flex-justify-content-center mr4"
            *ngIf="index === summary.id"
          >
            <img
              src="assets/images/common/preloader.gif"
              alt="preloader"
              width="40px"
              height="40px"
            />
          </div>
        </span>
      </div>
      <div class="__total flex flex-justify-content-between pt2 pb2">
        <span class="___item txt-black" style="font-weight: 600;">Total</span>
        <span class="___item txt-black" style="font-weight: 600;"
          >${{ totalAmtRemaining | number: "0.2-2" }}</span
        >
        <span class="___item"></span>
      </div>

      <div
        class="flex flex-justify-content-between flex-align-items-center flex-justify-content-center pt2 pb2"
      >
        <span class="___item"></span>
        <span class="___item"></span>
        <span class="___item" *ngIf="!isOnePlatform">
          <button
            (click)="continueToPayInstalments()"
            [disabled]="!btnPayNow"
            [class.brand-btn-pay-now]="btnPayNow"
            class="btn brand-btn-info-pay-now relative"
          >
            <span *ngIf="!btnProloader">PAY NOW</span>
            <span *ngIf="btnProloader" class="btn-preloader"></span>
          </button>
        </span>
      </div>
    </div>
  </div>  
  </form>
</div>
<ng-template #loading>
  <div class="modal-body">
    <div class="flex flex-align-items-center flex-justify-content-center">
      <img src="assets/images/common/preloader.gif" alt="preloader" />
    </div>
  </div>
</ng-template>
