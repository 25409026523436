<div class="payment-amount">
  <form #amountForm="ngForm" (ngSubmit)="continueToPaymentType()" novalidate>
    <div class="_amount pb5">
      <label class="label txt-grey-darker block">Amount</label>
      <div class="form-grp">
        <div class="payment-field">
          <span class="payment-unit"> $ </span
          >
          {{ paymentAmount }}
        </div>
        <div class="__error px5 pt5" *ngIf="amountError">
          <div class="error txt-sm font-brand txt-color-error bg-color-error">
            <img
              src="assets/images/icons/icon-error-orange.svg"
              class="mr1"
            />{{ errorMsg }}
          </div>
        </div>
      </div>
    </div>
    <div class="_buttons pb2">
      <app-button btnClass="brand-btn-info" (click)="backToMakeAPayment()">
        BACK</app-button
      >

      <button
        [disabled]="amountError"
        type="submit"
        [class.brand-btn-secondary]="amountError"
        class="btn brand-btn-info"
        (click)="checkAmtRange()"
      >
        CONTINUE TO PAYMENT TYPE
      </button>
    </div>
  </form>
</div>
