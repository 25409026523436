import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dodo-my-order-status",
  templateUrl: "./my-order-status.component.html",
  styleUrls: ["./my-order-status.component.scss"]
})
export class MyOrderStatusComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
