import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { HttpApiErrorHandlerService } from "../../../app/services/http-api-error-handler.service";

@Injectable()
export class HttpApiErrorInterceptor implements HttpInterceptor {

	constructor(private httpApiErrorHandlerService: HttpApiErrorHandlerService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
				}
			}),
			catchError((error: HttpErrorResponse) => {
				return this.httpApiErrorHandlerService.handleError(error);
			})
		);
	}
}
