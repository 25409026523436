import { Component, OnInit, OnDestroy } from "@angular/core";
import { Auth0Service } from "../../../../auth0/auth0.service";
import { environment } from "src/environment";
import { AppStoreService, AppStore, APP_EVENT } from "src/app/services/appStore.service";
import { IMfaContactMedium, IMfaVerifyLoginAttempts, IMfaVerifyCodeEntryAttempts } from "../../../models/mfa-contactmedium.model";
import { Router } from "@angular/router";
import { Auth0MfaService } from "../../../services/auth0-mfa.service";
import { DeepLinkingService } from "src/app/services/deeplinking.service";
import { ICustomer } from "src/app/self-serve/models/store.model";
import { LoadingService } from "src/app/self-serve/services/loading.service";
import { filter } from "rxjs/operators";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
	selector: "app-mfa-code",
	templateUrl: "./mfa-code.component.html"
})
export class MfaCodeComponent implements OnInit, OnDestroy {
	btnLoader: boolean;
	public mfaContactlist: IMfaContactMedium[] = [];
	public selectedMobileEmail: string;
	public cntLoginAttempts;
	public externalIdValue = undefined;
	public inputDataMobileEmail;

	codeEntered: string;
	public inputDataCodeValidate;
	public cntCodeEntryAttempts;
	public errCustomMsg: string;
	public errApiMsg: string;
	public passCodeError = false;
	public _appStore: AppStore;
	_customer: ICustomer;
	destroyed$ = new Subject<boolean>();

	constructor(
		private appStoreService: AppStoreService,
		private __auth0Service: Auth0Service,
		private router: Router,
		private auth0MfaService: Auth0MfaService,
		private deepLinkingService: DeepLinkingService,
		private loadingService: LoadingService
	) { }

	ngOnInit() {
		this._appStore = this.appStoreService.store;
		this._customer = this._appStore.customer;
		if (this._appStore?.mfaVerifyLoginAttempts !== undefined) {
			if (this._appStore?.mfaVerifyLoginAttempts.cntLoginAttempts > 0) {
				if (
					this._appStore?.mfaVerifyLoginAttempts?.mediumType === "Mobile"
				) {
					this.selectedMobileEmail =
						this._appStore?.mfaVerifyLoginAttempts?.mobileNo;
				} else if (
					this._appStore?.mfaVerifyLoginAttempts?.mediumType ===
					"EmailAddress"
				) {
					this.selectedMobileEmail =
						this._appStore?.mfaVerifyLoginAttempts?.emailAddress;
				} else {
					window.location.replace("auth0-mfa/mfa-error");
				}
			} else {
				window.location.replace("auth0-mfa/mfa-error");
			}

		} else {
			window.location.replace("auth0-mfa/mfa-error");
		}

		// this.appStoreService.storeUpdated
		// 	.pipe(filter(eventTrigger => eventTrigger === APP_EVENT.DEEPLINK_OPEN_FLG),
		// 		takeUntil(this.destroyed$))
		// 	.subscribe(() => {
		// 		if (this.appStoreService?.store?.urlDeeplinkDetails !== undefined) {
		// 			this.btnLoader = false;
		// 			this.deepLinkingService.openDeeplink();
		// 		}
		// 	});

		// this.appStoreService.storeUpdated
		// 	.pipe(filter(eventTrigger => eventTrigger === APP_EVENT.PRODUCTS_FLG),
		// 		takeUntil(this.destroyed$))
		// 	.subscribe(() => {
		// 		this.btnLoader = false;
		// 		window.location.replace("/dashboard");
		// 	});
	}

	public resendMFAcode(event: Event): void {
		this.codeEntered = "";
		const eventsrcElement = event.target as HTMLElement;
		eventsrcElement.innerHTML = "<span class='mfa-btn-preloader'>Didn’t get a code? Resend code</span>";
		const mfaVerifyLoginAttempts = <IMfaVerifyLoginAttempts>{};
		if (this._appStore?.mfaVerifyLoginAttempts !== undefined) {
			this.externalIdValue = this._appStore.mfaVerifyLoginAttempts.externalId;

			if (this._appStore?.mfaVerifyLoginAttempts?.cntLoginAttempts === undefined || this._appStore?.mfaVerifyLoginAttempts?.cntLoginAttempts === null) {
				window.location.replace("auth0-mfa/mfa-error");
			} else {
				this.cntLoginAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
			}
			if (this._appStore.mfaVerifyLoginAttempts.mediumType === "Mobile") {
				mfaVerifyLoginAttempts.mobileNo = this._appStore.mfaVerifyLoginAttempts.mobileNo;
				mfaVerifyLoginAttempts.mobileMediumCode = this._appStore.mfaVerifyLoginAttempts.mobileMediumCode;
				mfaVerifyLoginAttempts.mediumType = this._appStore.mfaVerifyLoginAttempts.mediumType;

				this.inputDataMobileEmail = {
					content: "OTP",
					messageType: "SMS",
					receiver: [
						{
							phoneNumber: this._appStore?.mfaVerifyLoginAttempts.mobileMediumCode
						}
					],
					characteristic: [
						{
							name: "externalId",
							value: this._appStore.mfaVerifyLoginAttempts.externalId
						}
					]
				};
			} else if (
				this._appStore?.mfaVerifyLoginAttempts.mediumType ===
				"EmailAddress"
			) {
				mfaVerifyLoginAttempts.emailAddress = this._appStore.mfaVerifyLoginAttempts.emailAddress;
				mfaVerifyLoginAttempts.emailMediumCode = this._appStore.mfaVerifyLoginAttempts.emailMediumCode;
				mfaVerifyLoginAttempts.mediumType = this._appStore.mfaVerifyLoginAttempts.mediumType;
				this.inputDataMobileEmail = {
					content: "OTP",
					messageType: "Email",
					receiver: [
						{
							emailAddress: this._appStore.mfaVerifyLoginAttempts.emailMediumCode
						}
					],
					characteristic: [
						{
							name: "externalId",
							value: this.externalIdValue
						}
					]
				};
			}

			if (this.cntLoginAttempts <= 2) {
				this.auth0MfaService.triggerMFAcodebySMS(this.inputDataMobileEmail).subscribe(
					sendCodeResponse => {
						if (sendCodeResponse.state === "complete") {
							if (this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts !== undefined) {
								if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 1 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
									this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								} else
									if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 2 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
										this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
										this.cntLoginAttempts = this.cntLoginAttempts + 1;
										mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
									} else {
										this.cntLoginAttempts = this.cntLoginAttempts + 1;
										mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
									}
							} else {
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							}

							this.appStoreService.updMfaLoginAttempts(mfaVerifyLoginAttempts);
							eventsrcElement.innerHTML = "<span>Didn’t get a code? Resend code</span>";
						} else {
							eventsrcElement.innerHTML = "<span>Didn’t get a code? Resend code</span>";
							this.router.navigate(["/auth0-mfa/mfa-error"]);
						}
					},
					error => {
						if (this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts !== undefined && this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts !== undefined) {
							if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 1 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
								this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
								this.cntLoginAttempts = this.cntLoginAttempts + 1;
								mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
							} else
								if (this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts === 2 && this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts > this.cntLoginAttempts) {
									this.cntLoginAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								} else {
									this.cntLoginAttempts = this.cntLoginAttempts + 1;
									mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
								}
						} else {
							this.cntLoginAttempts = this.cntLoginAttempts + 1;
							mfaVerifyLoginAttempts.cntLoginAttempts = this.cntLoginAttempts;
						}
						this.appStoreService.updMfaLoginAttempts(mfaVerifyLoginAttempts);
						eventsrcElement.innerHTML = "<span>Didn’t get a code? Resend code</span>";
						window.location.replace("auth0-mfa/mfa-error");
					}
				);
			} else {
				this.router.navigate(["/auth0-mfa/mfa-code-error"]);
			}
		} else {
			window.location.replace("auth0-mfa/mfa-error");
		}
	}

	public validateCodeEntered(event: Event): void {
		this.btnLoader = true;
		const eventsrcElement = event.target as HTMLElement;
		eventsrcElement.innerHTML = `<span class='mfa-btn-preloader'>Let's go</span>`;
		const mfaVerifyCodeEntryAttempts = <IMfaVerifyCodeEntryAttempts>{};

		if (this._appStore.mfaVerifyLoginAttempts.mediumType === "Mobile") {
			this.externalIdValue = this._appStore.mfaVerifyLoginAttempts.externalId;
			const mobileMediumCode = this._appStore.mfaVerifyLoginAttempts.mobileMediumCode;
			this.inputDataCodeValidate = {
				type: "OTP",
				passcode: this.codeEntered,
				externalId: this.externalIdValue,
				code: mobileMediumCode,
				messageType: "SMS"
			};
			mfaVerifyCodeEntryAttempts.mobileNo = this._appStore.mfaVerifyLoginAttempts.mobileNo;
			mfaVerifyCodeEntryAttempts.mobileMediumCode = this._appStore.mfaVerifyLoginAttempts.mobileMediumCode;
			mfaVerifyCodeEntryAttempts.mediumType = this._appStore.mfaVerifyLoginAttempts.mediumType;
			mfaVerifyCodeEntryAttempts.externalId = this.externalIdValue;
		} else if (
			this._appStore?.mfaVerifyLoginAttempts.mediumType ===
			"EmailAddress"
		) {
			this.externalIdValue = this._appStore.mfaVerifyLoginAttempts.externalId;
			const emailMediumCode = this._appStore.mfaVerifyLoginAttempts.emailMediumCode;
			this.inputDataCodeValidate = {
				type: "OTP",
				passcode: this.codeEntered,
				externalId: this.externalIdValue,
				code: emailMediumCode,
				messageType: "Email"
			};
			mfaVerifyCodeEntryAttempts.emailAddress = this._appStore.mfaVerifyLoginAttempts.emailAddress;
			mfaVerifyCodeEntryAttempts.emailMediumCode = this._appStore.mfaVerifyLoginAttempts.emailMediumCode;
			mfaVerifyCodeEntryAttempts.mediumType = this._appStore.mfaVerifyLoginAttempts.mediumType;
			mfaVerifyCodeEntryAttempts.externalId = this.externalIdValue;
		}

		if (this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts === undefined || this._appStore.mfaVerifyCodeEntryAttempts?.cntCodeEntryAttempts === null) {
			this.cntCodeEntryAttempts = 0;
		} else {
			this.cntCodeEntryAttempts = this._appStore.mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts;
		}

		if (this.cntCodeEntryAttempts <= 2) {
			this.auth0MfaService.validateMFAcode(this.inputDataCodeValidate).subscribe(
				(validateCodeResponse) => {
					if (validateCodeResponse.success === true) {
						if (this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts !== undefined) {
							if (this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts === 1 && this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts > this.cntCodeEntryAttempts) {
								this.cntCodeEntryAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
								mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
							} else
								if (this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts === 2 && this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts > this.cntCodeEntryAttempts) {
									this.cntCodeEntryAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
									mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
								} else
									if (this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts === 3 && this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts > this.cntCodeEntryAttempts) {
										this.cntCodeEntryAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
										mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
									} else {
										this.cntCodeEntryAttempts = this.cntCodeEntryAttempts + 1;
										mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
									}
						} else {
							this.cntCodeEntryAttempts = this.cntCodeEntryAttempts + 1;
							mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
						}
						mfaVerifyCodeEntryAttempts.mfaLogin = true;
						this.appStoreService.updMfaCodeEntryAttempts(mfaVerifyCodeEntryAttempts);
						eventsrcElement.innerHTML = `<span>Let's go</span>`;
						this._customer = this.appStoreService.store.customer;
						if (this._customer.email_verified === true) {
							// Promise.all(this.loadingService.fetchProducts())
							// 	.then(allproducts => {
							// 		this.preloadProductCompleted(allproducts);
							// 	})
							// 	.catch(error => {
							// 		console.log(`Error in loading the products ${error}`);
							// 		this.router.navigate(["/auth0-mfa/mfa-error"]);
							// 	});
							window.location.replace("/dashboard");
						} else if (this._customer.email_verified === false) {
							this.router.navigate(["/auth0-mfa/mfa-billit"]);
						} else {
							this.router.navigate(["/auth0-mfa/mfa-error"]);
						}
					}
				},
				(error) => {
					this.btnLoader = false;
					if (this._appStore.mfaVerifyLoginAttempts?.cntLoginAttempts !== undefined) {
						if (this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts === 1 && this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts > this.cntCodeEntryAttempts) {
							this.cntCodeEntryAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
							mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
						} else
							if (this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts === 2 && this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts > this.cntCodeEntryAttempts) {
								this.cntCodeEntryAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
								mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
							} else
								if (this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts === 3 && this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts > this.cntCodeEntryAttempts) {
									this.cntCodeEntryAttempts = this._appStore.mfaVerifyLoginAttempts.cntLoginAttempts;
									mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
									// this.router.navigate(["/auth0-mfa/mfa-code-error"]);
								} else {
									this.cntCodeEntryAttempts = this.cntCodeEntryAttempts + 1;
									mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
								}
					} else {
						this.cntCodeEntryAttempts = this.cntCodeEntryAttempts + 1;
						mfaVerifyCodeEntryAttempts.cntCodeEntryAttempts = this.cntCodeEntryAttempts;
					}
					mfaVerifyCodeEntryAttempts.mfaLogin = false;
					this.appStoreService.updMfaCodeEntryAttempts(mfaVerifyCodeEntryAttempts);
					eventsrcElement.innerHTML = `<span>Let's go</span>`;
					if (this.cntCodeEntryAttempts <= 2) {
						if (error.error.status === "400") {
							if (error.error.code === "400-35") {
								this.passCodeError = true;
								this.errApiMsg = error.error.message;
								this.errCustomMsg = "Passcode Expired. Check the code and try again.";
							} else
								if (error.error.code === "400-34") {
									this.passCodeError = true;
									this.errApiMsg = error.error.message;
									this.errCustomMsg = "Incorrect code. Check the code and try again.";

								} else {
									this.router.navigate(["/auth0-mfa/mfa-error"]);
								}
						} else {
							this.router.navigate(["/auth0-mfa/mfa-error"]);
						}
					} else if (this.cntCodeEntryAttempts === 3) {
						if (error.error.status === "400") {
							if (error.error.code === "400-35") {
								this.passCodeError = false; // deliberate to hide in UI
								this.errApiMsg = error.error.message;
								this.errCustomMsg = "Passcode Expired. Check the code and try again.";
								this.router.navigate(["/auth0-mfa/mfa-code-error"]);
							} else
								if (error.error.code === "400-34") {
									this.passCodeError = false; // deliberate to hide in UI
									this.errApiMsg = error.error.message;
									this.errCustomMsg = "Incorrect code. Check the code and try again.";
									this.router.navigate(["/auth0-mfa/mfa-code-error"]);
								} else {
									this.router.navigate(["/auth0-mfa/mfa-error"]);
								}
						} else {
							this.router.navigate(["/auth0-mfa/mfa-error"]);
						}
					}
				}
			);
		} else {
			this.router.navigate(["/auth0-mfa/mfa-code-error"]);
		}
	}

	public onFocusEvent(event: any) {
		this.passCodeError = false;
	}

	public goMFAsupportForm(): any {
		return `${environment.websiteHostServer}/online-support-request-my-dodo`;
	}

	public resetAuth0Tokens(): void {
		this.__auth0Service.resetAuth0Tokens();
		this.appStoreService.flushAppStoreLocalStorage();
	}

	private preloadProductCompleted(allproducts): void {
		if (allproducts && allproducts.length > 0 && this.appStoreService?.store?.urlDeeplinkDetails !== undefined) {
			const currentTimeStamp = Math.ceil(Date.now() / 1000);
			this.appStoreService.updateProductsReqTimestamp(currentTimeStamp);
			this.appStoreService.updateDeeplinkOpen(true);
		} else if (allproducts && allproducts.length > 0 && this.appStoreService?.store?.urlDeeplinkDetails === undefined) {
			const currentTimeStamp = Math.ceil(Date.now() / 1000);
			this.appStoreService.updateProductsReqTimestamp(currentTimeStamp);
			this.appStoreService.updateProductsflg(true);
		} else {
			this.router.navigate(["/auth0-mfa/mfa-error"]);
		}
	}

	ngOnDestroy(): void {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
