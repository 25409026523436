import { Injectable } from "@angular/core";

// service
import { HttpService } from "src/libraries/api/services/http.service";

// rxjs
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// model
import { IBill, IBalance, IInstallmentPlan } from "../models/invoice.model";
import { ICreditRefundPost } from "../models/credit-refund.model";
import { environment } from "src/environment";
import { HttpClient } from "@angular/common/http";
import { IDebitNote } from "../models/invoice.model";

@Injectable({
  providedIn: "root"
})
export class BillService {
  constructor(
	private httpClient: HttpClient,
	private httpService: HttpService
  ) {}

  getBill(accountId, externalId): Observable<IBill> {
	const url = `${
		environment.apiURL
	}/api/v1/billingmgmt/bill?accountId=${accountId}&externalId=${externalId}&billType=Invoice`;

	return this.httpService.get<IBill>(url);
  }

  getDebitNote(accountId, externalId): Observable<IDebitNote> {
	const url = `${
		environment.apiURL
	}/api/v1/billingmgmt/bill?accountId=${accountId}&externalId=${externalId}&billType=DebitMemo`;
	return this.httpService.get<IDebitNote>(url);
  }

  getAccountBalance(customerAccountId, BillingAccountId): Observable<IBalance> {
	const url = `${
		environment.apiURL
	}/api/v1/accountmgmt/accounts/${customerAccountId}/billingAccounts/${BillingAccountId}/balance`;
	return this.httpService
		.get<any>(url)
		.pipe(
		map(val =>
			val.accountBalance
			? val.accountBalance.filter(
				account => account.type === "Balance"
				)[0] || <IBalance>{}
			: <IBalance>{}
		)
		);
  }

  getDodoBill(productId): Observable<IBill> {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/billingmgmt/bill?productId=${productId}`;
	return this.httpService.getSlience<IBill>(url);
  }

  getDodoAccountBalance(productId): Observable<IBalance> {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/accountmgmt/customer/${productId}`;
	return this.httpService
		.getSlience<any>(url)
		.pipe(
		map(val =>
			val.accountBalance
			? val.accountBalance.filter(
				account => account.type === "NotifiedBalance"
				)[0] || <IBalance>{}
			: <IBalance>{}
		)
		);
  }

  getDodoCreditRefundRunningBalance(productId): Observable<IBalance> {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/accountmgmt/customer/${productId}`;
	return this.httpClient
		.get<any>(url)
		.pipe(
		map(val =>
			val.accountBalance
			? val.accountBalance.filter(
				account => account.type === "RunningBalance"
				)[0] || <IBalance>{}
				: <IBalance>{}
		)
		);

  }

  getDodoCreditRefundPendingBalance(productId): Observable<IBalance> {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/accountmgmt/customer/${productId}`;
	return this.httpClient
		.get<any>(url)
		.pipe(
		map(val =>
			val.accountBalance
			? val.accountBalance.filter(
				account => account.type === "RefundPendingBalance"
				)[0] || <IBalance>{}
				: <IBalance>{}
		)
		);

  }

  postDodoCreditRefund(productId, data): Observable<ICreditRefundPost> {
		const url = `${
			environment.apiUrlDodo
		}/exp-dodoselfservice/api/v1/paymentmgmt/customer/${productId}/refund`;
		return this.httpClient.post<ICreditRefundPost>(url, data);
  }

  getDodoInstallmentPlan(productId): Observable<IInstallmentPlan> {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/accountmgmt/customer/${productId}`;
	return this.httpService
		.getSlience<any>(url)
		.pipe(
		map(response =>
			response.paymentPlan
			? response.paymentPlan.filter(
				plans => plans.type === "InstalmentPlan"
				)[0] || <IInstallmentPlan>{}
			: <IInstallmentPlan>{}
		)
		);
  }

  savePDF(data: any) {
	// It is necessary to create a new blob object with mime-type explicitly to support different browsers
	const blob = new Blob([data], { type: "application/pdf" });
	// For IE, it is necessary to use msSaveOrOpenBlob
	if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
		(window.navigator as any).msSaveOrOpenBlob(blob, "invoice.pdf");
		return;
	}

	if ((window.navigator as any).msSaveOrOpenBlob) {
		// IE 11+
		(window.navigator as any).msSaveOrOpenBlob(blob, "invoice.pdf");
	} else if (window.navigator.userAgent.match("FxiOS")) {
		// FF iOS
		alert("Cannot display on FF iOS");
	} else if (window.navigator.userAgent.match("CriOS")) {
		// Chrome iOS
		const reader = new FileReader();
		reader.onloadend = function () {
			const file: string = reader.result as string;
			window.open(file);
		};
		reader.readAsDataURL(blob);
	} else if (
		window.navigator.userAgent.match("/iPad/i") ||
		navigator.userAgent.match("/iPhone/i")
	) {
		// Safari & Opera iOS
		const urlIpad = window.URL.createObjectURL(blob);
		window.location.href = urlIpad;
	} else {
		const url = window.URL.createObjectURL(blob);
		window.open(url, "_blank");
	}
}

}
