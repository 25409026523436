<div class="payment-summary">
  <div
    class="_heading font-brand txt-color-primary px3 py2"
    *ngIf="step !== 'THIRD'"
  >
    Payment Summary
  </div>
  <div class="_subheading txt-black txt-base" *ngIf="step === 'THIRD'">
      Check that your payment details are correct.
  </div>

  <div class="_service-selected p3">
    <div class="__heading txt-grey-darker pb3">Service selected</div>
    <div class="__service pb2">
        <img [src]="getIconPath(payment.selectedProduct.productGroupType)" [alt]="payment.selectedProduct.productGroupType" class="" alt=""
        *ngIf="payment.selectedProduct.productGroupType!=='Energy'" />
      <img [src]="getIconPath(payment.selectedProduct.name)" [alt]="payment.selectedProduct.name" class="" alt="" *ngIf="payment.selectedProduct.productGroupType==='Energy'" />
      <span class="___service-name mr2 txt-black font-brand">
        <span
          *ngIf="
            payment.selectedProduct.productGroupType === 'Energy';
            else: telco
          "
          >{{ payment.selectedProduct.name }}</span
        >
        <ng-template #telco>
          <span>{{ payment.selectedProduct.productGroupType }}</span>
        </ng-template>
      </span>
      <span class="___service-id txt-grey-darker txt-sm">{{
        payment.selectedProduct.services[0].uniqueId
      }}</span>
      <span class="background-gradient"></span>
    </div>
  </div>
  <div class="_amount p3" *ngIf="step !== 'FIRST'">
    <div class="__heading txt-grey-darker pb3">Amount</div>
    <div class="__value pb2">
      <span class="txt-black font-brand"
        >${{ payment.amount | number: "0.2-2" }}</span
      >
    </div>
  </div>
  <div
    class="_payment-type-selected p3"
    *ngIf="step !== 'FIRST' && step !== 'SECOND'"
  >
    <div class="__heading txt-grey-darker pb3">Payment Type</div>
    <div class="__payment-method pb2">
      <img class="_card-icon mr2" src="assets/images/icons/icon-bills.svg" />
      <div class="_card-details txt-black txt-sm" *ngIf="payment.selectedPaymentMethod.type==='Credit Card' || payment.selectedPaymentMethod.type==='tokenizedCard'">
        <span class="__card-name block mb1" >{{
          payment.selectedPaymentMethod.details.nameOnCard
        }}</span>
        <span class="__card-number"
          >**** **** ****
          {{ payment.selectedPaymentMethod.details.lastFourDigits }}</span
        >
      </div>
      <div class="_card-details txt-black txt-sm" *ngIf="payment.selectedPaymentMethod.type==='Direct Debit' || payment.selectedPaymentMethod.type==='bankAccountDebit'">
          <span class="__card-name block mb1" >{{
            payment.selectedPaymentMethod.details.owner | titlecase
          }}</span>
          <span class="__card-number"
            >*****
            {{ payment.selectedPaymentMethod.details.lastFourDigits }}</span
          >
        </div>
    </div>
  </div>
  <section *ngIf="errorMessage">
    <div class="error txt-sm font-brand txt-color-error bg-color-error">
      <img class="mr1" src="assets/images/icons/icon-error.svg" />
      {{ errorMessage }}
    </div>
  </section>
  <section class="_buttons" *ngIf="step === 'THIRD'">
    <app-button btnClass="brand-btn-info" (click)="continueToPaymentType()"
      >BACK</app-button
    >

    <button
      class="btn brand-btn-secondary relative"
      (click)="continueToSecurePayment()"
      [disabled]="busy"
    >
      <span *ngIf="!busy">MAKE A SECURE PAYMENT</span>
      <span *ngIf="busy" class="btn-preloader"></span>
    </button>
  </section>
</div>
