<section class="bg-color-primary height-full">
  <section class="mfa-nocontacts-error bg-color-primary">
    <div class="_mfa-nocontacts-error-container bg-white">
      <div class="mydodo-custom-header">
        <div class="auth0-lock-header-welcome">
          <img
            alt="MyDodo"
            class="auth0-lock-header-logo"
            src="assets/images/brand-primary.svg"
          />
        </div>
        <h2>No contact details found</h2>
        <p class="mfa-msg">
          There is no mobile/email address registered on this account for
          verification.
        </p>
        <p class="mfa-msg mt2">Please request support below.</p>
      </div>
      <form #mfanocontactserror="ngForm">
        <div class="mt2 mb4 pl5 pr5">
          <button
            type="button"
            class="btn brand-btn-secondary btn-width relative"
            (click)="goMFAsupportForm()"
          >
            <span>REQUEST SUPPORT</span>
          </button>
        </div>
        <div class="mt2 mb4 pl5 pr5">
          <button
            type="button"
            class="btn brand-btn-info btn-width relative"
            (click)="backHomePage()"
          >
            <span>BACK TO DODO.COM</span>
          </button>
        </div>
      </form>
    </div>
  </section>
</section>
