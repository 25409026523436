export enum FeatureFlag {
	DisableNbnChangePlan = "disable_nbn_change_plan",
	NbnFibreOnDemand = "nbn_fibre_on_demand",
	ChangeUserName = "mydodo_change_username",
	IpsiOutageNotification = "ipsi_outage_notification",
	DisableEmailAddress = "disable_email_address",
	BillExplainerDashboard = "bill_explainer_dashboard",
	BillExplainerFaq = "bill_explainer_faq",
	EBRActive = "energy_bill_relief",
	EnergyBestOffer = "energy_best_offer",
	PWABiometric = "pwa_biometric",
	EnableErrorMonitoring = "enable_error_monitoring"
}
