import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "./services/authentication.service";
import { Auth0Service } from "src/app/auth0/auth0.service";
import { AppStoreService } from "src/app/services/appStore.service";
@Injectable({
	providedIn: "root"
})
export class AuthGuard  {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private auth0Service: Auth0Service,
		private appStoreService: AppStoreService
	) { }

	canActivate(): boolean {
		if (this.appStoreService.store?.DPGOnlyflg === false && this.appStoreService.store?.apiMfaContactsError === false) {
			if (this.authenticationService.isLoggedIn()) {
				if (this.auth0Service.isTokenExpired()) {
					if (this.appStoreService.store.mfaVerifyCodeEntryAttempts?.mfaLogin !== undefined) {
						if (this.appStoreService.store.mfaVerifyCodeEntryAttempts.mfaLogin === true) {
							return true;
						} else if (this.appStoreService.store.mfaVerifyCodeEntryAttempts.mfaLogin === false) {
							this.resetAuth0andStore();
							return false;
						}
					} else {
						this.resetAuth0andStore();
						return false;
					}
				} else {
					this.auth0MfaLogout();
					return false;
				}
			} else {
				this.resetAuth0andStore();
				return false;
			}
		} else if (this.appStoreService.store?.DPGOnlyflg === true && this.appStoreService.store?.apiMfaContactsError === false) {
			if (this.authenticationService.isLoggedIn()) {
				if (this.auth0Service.isTokenExpired()) {
					return true;
				} else {
					this.auth0MfaLogout();
					return false;
				}
			} else {
				this.authenticationService.LoggedOut();
				this.router.navigate([`/login`]);
				return false;
			}
		}
	}

	private resetAuth0andStore(): void {
		this.authenticationService.LoggedOut();
		this.auth0Service.logout();
		this.router.navigate([`/login`]);
	}

	private auth0MfaLogout(): void {
		this.authenticationService.LoggedOut();
		this.auth0Service.logout();
	}
}
