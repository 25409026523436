import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// model
import { IMailBox, IWebHosting } from "../models/mail-box.model";

import { environment } from "src/environment";

@Injectable({
  providedIn: "root"
})
export class MailBoxService {

  constructor(
	private httpClient: HttpClient
  ) {}

  // Get My Mail Box Data
  getMailBox(currentCid: string): Observable<IMailBox[]> {
	console.log("passed currentCid:", currentCid);
	const _url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/resourceinventorymgmt/customer/${currentCid}/resources`;
	return this.httpClient
		.get<any>(_url)
		.pipe(
		map(val =>
			val
			? val.filter(
				mailbox =>
				mailbox.type === "Mailbox"
				) || <IMailBox>{}
			: <IMailBox>{}
		)
		);
  }

   // Add new MailBox
   postNewMailBox(currentCid, data) {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/resourceinventorymgmt/customer/${currentCid}/resources`;
	return this.httpClient.post(url, data);
  }

  // Patch update for passed MailBox
  patchUpdateMailBox(currentCid, mailboxId, data) {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/resourceinventorymgmt/customer/${currentCid}/resources/${mailboxId}`;
	return this.httpClient.patch(url, data);
  }

   // delete Mailbox. Not tested
   deleteMailBox(currentCid, mailboxId) {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/resourceinventorymgmt/customer/${currentCid}/resources/${mailboxId}`;
	return this.httpClient.delete(url);
  }

  // Get My Web Hosting Data
  getWebHosting(currentCid: string): Observable<IWebHosting> {
	console.log("passed currentCid:", currentCid);
	const _url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/resourceinventorymgmt/customer/${currentCid}/resources`;
	return this.httpClient
		.get<any>(_url)
		.pipe(
		map(val =>
			val
			? val.filter(
				mailbox =>
				mailbox.type === "WebHosting"
				) || <IWebHosting>{}
			: <IWebHosting>{}
		)
		);
  }

  // Patch update for passed MailBox
  patchUpdateWebHosting(currentCid, mailboxId, data) {
	const url = `${
		environment.apiUrlDodo
	}/exp-dodoselfservice/api/v1/resourceinventorymgmt/customer/${currentCid}/resources/${mailboxId}`;
	return this.httpClient.patch(url, data);
  }

}
