export interface IApiError {
	code: any;
	correlationId: any;
	reason: string;
	message: string;
	status: any;
	source: any;
	method: string;
}

export class AppError {
  message: string;
  event?: any;
  apiError?: IApiError;
}

export class FeatureCode {
	feature: Feature;
	code: string;
	description: string;
}

export enum Feature {
	LOGIN,
	CUSTOMER_DETAILS,
	MOBILE_NUMBER,
	ADDRESS,
	CONTACT_DETAILS,
	BILLING_ACCOUNT,
	ACCOUNT_BALANCE,
	BILLS,
	INVOICE,
	PRODUCT,
	USAGE,
	USAGE_ALERT,
	PAYMENT_METHOD,
	IPSI,
	MAKE_PAYMENT,
	ORDER,
	UNKNOWN
}

export class ValidationError extends AppError {
	errorType: InputValidationErrorType;
}

export enum InputValidationErrorType {
	REQUIRED,
	MAX_LEN,
	MIN_LEN,
	ALPHA_NUMBERIC,
	ALPHA,
	NUMERIC,
	UNKNOWN
}

export enum InputValidationComponent {
	CONTACT_ADDRESS
}
