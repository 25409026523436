import { Injectable } from "@angular/core";
import { IMutableContext, UnleashClient } from "unleash-proxy-client";

@Injectable({
	providedIn: "root"
})
export class FeatureFlagService {
	unleash = new UnleashClient({
		url: "https://digital-self-service.unleashproxy.vocusretailcloud.com.au/proxy",
		clientKey: "TvpXMq@GgFa6Us3C5",
		appName: "unleash-proxy",
		refreshInterval: 50000
	});

	constructor() {}

	start(): void {
		this.unleash.start();
	}

	stop(): void {
		this.unleash.stop();
	}

	updateContext(contextObj: IMutableContext): void {
		this.unleash.updateContext(contextObj);
	}

	isEnabled(feature: string): boolean {
		return this.unleash.isEnabled(feature);
	}
}
