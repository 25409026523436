import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { IBillingAccount } from "src/app/self-serve/models/product.model";
import { TealiumService } from "src/tealium/services/tealium.service";
import { PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES } from "./payment-amount-entry.enum";
@Component({
	selector: "app-payment-amount-entry",
	templateUrl: "./payment-amount-entry.component.html",
	styleUrls: ["./payment-amount-entry.component.scss"]
})
export class PaymentAmountEntryComponent implements OnChanges {
	paymentAmount: string;
	amountError = false;
	errorMsg = "";

	@Input() billingAccount: IBillingAccount;
	@Output() continue = new EventEmitter();

	constructor(
		private router: Router, private tealiumService: TealiumService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.billingAccount = changes["billingAccount"].currentValue;
		this.router.navigate(["/make-a-payment/payment-type", this.billingAccount.id]);
		this.paymentAmount = +this.billingAccount.currentPayment?.amount < 0
			? "0"
			: this.billingAccount.currentPayment?.amount;
	}

	public continueToPaymentType(): void {
		const amount = this.paymentAmount;
		if (+amount?.trim() >= 1 && +amount?.trim() <= 9999) {
			this.continue.emit(amount);
		}
	}

	public checkAmtRange(): void {
		const amount = this.paymentAmount;
		const accountBalance = this.billingAccount.accountBalance?.amount?.value;
		if (!amount || +amount?.trim() < 1 && accountBalance > 0) {
			this.amountError = true;
			this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.AMT_MORE_THAN_ONE_DOLLAR_ERROR;
			this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
		} else if (+amount?.trim() > +accountBalance && accountBalance > 0) {
			this.amountError = true;
			this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.AMT_ABOVE_OUTSTANDING_BALANCE_ERROR;
			this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
		} else if (+amount?.trim() >= 0 && accountBalance <= 0) {
			this.amountError = true;
			this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.NO_OUTSTANDING_BALANCE_ERROR;
			this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
		} else {
			this.amountError = false;
		}
	}

	public backToMakeAPayment(): void {
		this.router.navigate(["/make-a-payment/"]);
	}
}
