<div class="payment-amount">
  <form #amountForm="ngForm" (ngSubmit)="continueToPaymentType()" novalidate>
    <div class="_subheading pb5 txt-black">Please enter the amount you wish to pay</div>
    <div class="_amount pb5">
      <label class="label txt-grey-darker block">Amount</label>
      <div class="form-grp">
        <div class="payment-field">
          <span class="payment-unit"> $ </span
          ><input
            type="text"
            name="amountVal"
            min="0"
            class="input txt-black txt-base font-brand"
            placeholder="00.00"
            [(ngModel)]="paymentAmount"
            pattern="^\d*\.?\d{0,2}"
            maxlength="7"
            #amountVal="ngModel"
            [class.invalid]="amountVal.invalid && amountVal.touched"
            [class.valid]="amountVal.valid && amountVal.touched"
            required
            (blur)="checkAmtRange()"
          />
        </div>
        <small
          class="indicators inline-block"
          [class.hide]="amountVal.valid || amountVal.untouched"
          >{{errInvalidAmount}}</small
        >
        <div class="__error px5 pt5" *ngIf="amountError">
          <div class="error txt-sm font-brand txt-color-error bg-color-error">
            <img
              src="assets/images/icons/icon-error-orange.svg"
              class="mr1"
            />{{ errorMsg }}
          </div>
        </div>
      </div>
    </div>
    <div class="_buttons pb2">
      <app-button btnClass="brand-btn-info" (click)="backToMakeAPayment()">
        BACK</app-button
      >
      <button
        [disabled]="amountForm.form.invalid || amountError"
        type="submit"
        [class.brand-btn-secondary]="amountForm.form.valid || amountError"
        class="btn brand-btn-info"
      >
        CONTINUE TO PAYMENT TYPE
      </button>
    </div>
  </form>
</div>
