import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";

import { HttpService } from "src/libraries/api/services/http.service";
import { environment } from "src/environment";
import { TealiumService } from "src/tealium/services/tealium.service";
@Component({
	selector: "dodo-view-my-bill",
	templateUrl: "./view-my-bill.component.html"
})

export class ViewMyBillComponent implements OnInit {
	btnPreloader = false;
	public encrypted_invoicenumber = "";
	public pdfSrc;
	public printurlflg = false;
	private downLoadPDF = false;
	public btnDownloadRequired = true;
	public blob;
	constructor(
		private httpService: HttpService,
		public route: ActivatedRoute,
		private tealiumService: TealiumService
	) {}

	ngOnInit() {
		// get values from query string
		this.encrypted_invoicenumber = this.route.snapshot.queryParamMap.get(
			"referenceid"
		);

		if (this.encrypted_invoicenumber) {
			// The querystring puts space for "+" so replaced back with "+"
			this.encrypted_invoicenumber = this.encrypted_invoicenumber
				.trim()
				.replace(/\s/g, "+");
			// Download button should not be visible in case of chrome for iOS
			if (
				window.navigator.userAgent.match("iPad") ||
				navigator.userAgent.match("iPhone")
			) {
				if (
					window.navigator.userAgent.match("FxiOS") ||
					window.navigator.userAgent.match("CriOS")
				) {
					this.btnDownloadRequired = false;
				}
			}
			this.UnAuthGetInvoice(this.encrypted_invoicenumber);
		} else {
			console.log("Encrypted StatmentID passed is null");
		}
	}
	UnAuthGetInvoice(encrypted_invoicenumber) {
		// this.btnPreloader = true;
		const headers = new HttpHeaders({
			Accept: "application/pdf",
			CONTENT_DISPOSITION: "attachment; filename=invoice.pdf"
		});

		const url = `${environment.invoiceUrlDodo}/api/v1/billingmgmt/bill/${encrypted_invoicenumber}`;

		this.httpService
			.get(url, { headers, responseType: "blob" })
			.subscribe((pdf: Blob) => {
				if (pdf) {
					// this.btnPreloader = false;
					if (this.downLoadPDF) {
						// Code to download invoice in iOS devices
						const newUrl = (window.URL || window["webkitURL"]).createObjectURL(
							pdf
						);
						this.downloadInvoice(newUrl);
						this.downLoadPDF = false;
					} else {
						// Code to show invoice in All devices
						this.savePDF(pdf);
						if (this.printurlflg === true) {
							this.tealiumService.trackInvoiceloader(); // this is to fire when the loader completed  online .pdf is generated
						}
					}
				}
			});
	}

	savePDF(data: any) {
		// It is necessary to create a new blob object with mime-type explicitly to support different browsers
		this.blob = new Blob([data], { type: "application/pdf" });
		this.pdfSrc = window.URL.createObjectURL(this.blob);
		this.printurlflg = true;
	}

	// this is called on clicking of Download Invoice
	downloadpdfInvoice() {
		this.btnPreloader = true;
		if (
			window.navigator.userAgent.match("iPad") ||
			navigator.userAgent.match("iPhone")
		) {
			// Code to download invoice in iOS devices, no need here as the download button will be disabled
			this.downLoadPDF = true;
			this.UnAuthGetInvoice(this.encrypted_invoicenumber);
		} else {
			// Code to download invoice in Other devices
			this.downloadInvoice(this.pdfSrc);
			this.tealiumService.trackInvoiceDownloadBtnclick();
		}
		this.btnPreloader = false;
	}

	downloadInvoice(url) {
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		a.download = "dodo_invoice.pdf";
		document.body.appendChild(a);
		a.click();
		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		}, 10000);
	}
}
