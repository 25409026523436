import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environment";
@Injectable()
export class MaintenanceGuard  {
  constructor(
	private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
  if (environment.inMaintenance) {
	this.router.navigate(["maintenance"]);
	return false;
  } else {
	return true;
  }
  }

}
