<section
  class="bg-color-primary height-full"
  *ngIf="mfaContactlist; else preloader"
>
  <section class="auth0-mfa bg-color-primary">
    <div
      class="_auth0-mfa-container bg-white"
      *ngIf="mfaContactlistError === false"
    >
      <div class="mydodo-auth0-mfa-header">
        <div class="auth0-lock-header-welcome">
          <img
            alt="MyDodo"
            class="auth0-lock-header-logo"
            src="assets/images/brand-primary.svg"
          />
        </div>
        <div>
          <h3>Hey there!</h3>
          Log in securely using a verification code sent to your phone or email
          address. Choose an option below to continue
        </div>
      </div>
      <form #auth0mfa="ngForm">
        <div *ngFor="let contactlist of mfaContactlist; index as i">
          <div class="mt3 mb3 pl5 pr5" *ngIf="contactlist.medium.number">
            <button
              type="button"
              class="btn brand-btn-secondary width-full relative"
              (click)="
                sendMFAcodebySMS(
                  contactlist.medium.number,
                  contactlist.medium.code,
                  contactlist.medium.type,
                  $event
                )
              "
            >
              <span class="">TEXT&nbsp;{{ contactlist.medium?.number }}</span>
            </button>
          </div>
          <div class="mt3 mb3 pl5 pr5" *ngIf="contactlist.medium.emailAddress">
            <button
              type="button"
              class="btn brand-btn-secondary width-full relative"
              (click)="
                sendMFAcodebyEmail(
                  contactlist.medium.emailAddress,
                  contactlist.medium.code,
                  contactlist.medium.type,
                  $event
                )
              "
            >
              <span class="">EMAIL&nbsp;{{ contactlist.medium?.emailAddress }}</span>
            </button>
          </div>
        </div>
      </form>
      <div class="mydodo-custom-footer">
        <a href="{{ goMFAsupportForm() }}" (click)="resetAuth0Tokens()"
          >Need help logging in?</a
        >
      </div>
    </div>
  </section>
</section>
<ng-template #preloader>
  <main>
    <div class="flex flex-align-items-center flex-justify-content-center">
      <img src="assets/images/common/preloader.gif" alt="preloader" />
    </div>
  </main>
</ng-template>
