import { Component, OnInit } from "@angular/core";
import { environment } from "src/environment";
import { Auth0Service } from "../../../../auth0/auth0.service";
import { AppStoreService } from "src/app/services/appStore.service";
@Component({
	selector: "app-mfa-code-error",
	templateUrl: "./mfa-code-error.component.html"
})
export class MfaCodeErrorComponent implements OnInit {
	constructor(private appStoreService: AppStoreService,
		private __auth0Service: Auth0Service) { }

	ngOnInit() {
		this.resetAuth0Tokens();
	}

	goMFAsupportForm() {
		return `${environment.websiteHostServer}/online-support-request-my-dodo`;
	}
	backHomePage() {
		window.location.replace("https://www.dodo.com/");
	}

	resetAuth0Tokens() {
		this.__auth0Service.resetAuth0Tokens();
		this.appStoreService.flushAppStoreLocalStorage();
	}
}
