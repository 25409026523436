// Move everything to usage.service.ts, once final API calls are provided
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";

// model
import { IProductOfferings } from "../models/addons.model";

// libraries
import { HttpService } from "../../../libraries/api/services/http.service";

import { environment } from "src/environment";

@Injectable()
export class AddonsService {
  constructor(
	private httpService: HttpService,
	private httpClient: HttpClient
  ) {}

  getAddonsProductOffering(customerId): Observable<IProductOfferings> {
	const _url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/productcatalogmgmt/productoffering?customerId=${customerId}`;
	return this.httpService.get<IProductOfferings>(_url);
  }

  // API's to call Data Pack, Call Pack and MMS Pack
  postSelectedAddons(data): Observable<IProductOfferings> {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/productorderingmgmt/productorder`;
	return this.httpClient.post<IProductOfferings>(url, data);
  }

   // get existing topups
   fetchTopupDetails(data): Observable<any> {
	const url = `${environment.apiURL}/api/v1/quoteMgmt/quote?structure=flat&showHiddenProducts=false&showHiddenCharacteristics=false`;
	return this.httpClient.post(url, data);
	}

  // trigger  topup Quote to make payments
	triggerTopupQuote(data, quoteid): Observable<any> {
	const url = `${environment.apiURL}/api/v1/quoteMgmt/quote/${quoteid}?structure=flat&showHiddenProducts=false&showHiddenCharacteristics=false`;
	return this.httpClient.put(url, data);

	}
}
