import {
	Inject,
	Injectable,
	Directive,
	HostListener,
	Input,
	Optional
} from "@angular/core";
import { TealiumService } from "../services/tealium.service";

@Injectable()
@Directive({
	// tslint:disable-next-line:directive-selector
	selector: "[TealiumContext]",
	providers: [TealiumContextDirective]
})
export class TealiumContextDirective {
	// tslint:disable-next-line:no-input-rename
	@Input("TealiumContext")
	tealiumData: object;
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: "[TealiumLink]" })
export class TealiumLinkDirective {
	// tslint:disable-next-line:no-input-rename
	@Input("TealiumLink")
	tealiumData: object;
	constructor(
		@Optional()
		@Inject(TealiumContextDirective)
		private context: TealiumContextDirective,
		private service: TealiumService
	) {}

	@HostListener("click", ["$event"])
	onClick(event: any) {
		const defaults = {
			tealium_event: "click"
		};
		const data = Object.assign(
			{},
			defaults,
			this.contextData(),
			this.tealiumData
		);
		/* console.debug(
			"sending",
			event,
			this.contextData(),
			this.tealiumData
		); */

		this.service.trackPageEvent(event, data);
	}

	contextData() {
		return this.context ? this.context.tealiumData : {};
	}
}
