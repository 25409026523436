<!--Mobile and tablet Nav-->
<div class="mobile-nav" *ngIf="globalNav" data-utag-zone="Mobile Navigation">
  <div
    [ngClass]="{
      'opaque-background': isActive === true || isMobSubmenuActive === true
    }"
    (click)="closeNav('none')"
  ></div>
  <div class="nav-header pl3 pr2">
    <div class="_hamburger-icon" (click)="openNav()">
      <img src="assets/images/common/hamburger-icon.svg">
    </div>
    <div class="_logo">
      <span id="dodoLogo">
        <span id="dodoLogoPurple">
          <a routerLink="/dashboard">
            <img
              class="_logo-mobile"
              src="assets/images/brand-top-nav.svg"
              alt=""
            />
          </a>
        </span>
      </span>
    </div>
    <div class="_account">
      <div class="__heading" (click)="loadNav('Account', 0)">
        <img src="../../../assets/images/icons/nav-account-icon.png" />
        <span class="ml1 txt-sm font-brand txt-top-nav">Account</span>
      </div>
    </div>
  </div>
  <div
    class="nav-submenu nav-position fixed-mobile-height"
    [ngClass]="isActive ? 'block' : 'hide'"
  >
    <div
      class="_close-submenu bg-color-primary txt-white txt-sm p4"
      (click)="closeNav('none')"
    >
      <i class="fal fa-times mr1"></i>Close
    </div>
    <div class="_mobile-menu font-brand bg-white">
      <div class="txt-lg txt-blue-base font-semibold bg-grey-light">
        Main menu
      </div>
      <div class="__heading txt-black txt-base">
        <a
          routerLink="/dashboard"
          (click)="closeNav('none')"
          class="flex flex-justify-content-between width-full"
          >Dashboard
          <i class="fa fa-angle-right txt-base txt-color-primary"></i>
        </a>
      </div>
      <div
        class="__heading txt-black txt-base"
        *ngFor="let mainMenuObj of mainMenuObjs; index as i"
        (click)="loadNav(mainMenuObj.mainMenu, i)"
      >
        {{ mainMenuObj.mainMenu }}
        <i class="fa fa-angle-right txt-base txt-color-primary"></i>
      </div>
    </div>
  </div>
  <div
    class="nav-sub-submenu fixed-mobile-height bg-white"
    [ngClass]="{
      block: isMobSubmenuActive,
      hide: !isMobSubmenuActive,
      'nav-position-right': isAccountMobActive,
      'nav-position-left': !isAccountMobActive
    }"
  >
    <div
      class="_close-submenu bg-color-primary txt-white txt-sm p4"
      (click)="closeMobSubNav()"
      *ngIf="!isAccountMobActive"
    >
      Back to main menu
    </div>
    <div
      class="_close-submenu bg-color-primary txt-white txt-sm p4"
      (click)="closeNav('none')"
      *ngIf="isAccountMobActive"
    >
      <i class="fal fa-times mr1"></i>Close
    </div>

    <div class="_submenu-links font-brand">
      <div
        class="__item font-brand txt-base"
        *ngFor="let submenuObj of submenuObjs"
      >
        <div class="___submenu-header txt-color-primary bg-grey-light">
          {{ submenuObj.submenuTitle }}
        </div>
        <div class="" *ngFor="let submenuLink of submenuObj.submenuLinks">
          <a
            class="___submenu-link txt-black block bg-white"
            *ngIf="submenuLink.external === 'false' || !submenuLink.external"
            [routerLink]="submenuLink.href"
            (click)="closeNav(submenuLink)"
          >
            {{ submenuLink.submenuLinksTitle }}
            <span
              class="___submenu-subtitle ml2 txt-blue-dark txt-sm font-primary"
              >{{ submenuLink.subtitle }}</span
            ><span class="linear-gradient"></span>
          </a>

          <a
            class="___submenu-link txt-black block bg-white external-link"
            *ngIf="submenuLink.external === 'true'"
            [href]="submenuLink.href"
            (click)="closeNav(submenuLink)"
            target="_blank"
          >
            {{ submenuLink.submenuLinksTitle }}

            <img
              src="assets/images/icons/icon-external-link-primary.svg"
              class="ml1"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Desktop nav-->

<div class="desktop-nav" *ngIf="globalNav" data-utag-zone="Desktop Navigation">
  <div class="nav-header p4">
    <div class="_logo">
      <a routerLink="/dashboard"><img src="assets/images/brand-top-nav.svg" alt=""/></a>
    </div>
    <div class="_menu">
      <a
        class="__heading txt-sm font-brand txt-top-nav block"
        routerLink="/dashboard"
        (click)="closeNav('none')"
      >
        Dashboard
      </a>

      <div
        class="__heading txt-sm font-brand txt-top-nav"
        *ngFor="let mainMenuObj of mainMenuObjs; index as i"
        (click)="loadNav(mainMenuObj.mainMenu, i)"
        [ngClass]="{ '__heading-active': headingActive === i }"
      >
        {{ mainMenuObj.mainMenu }}
        <i
          [id]="i"
          class="ml1"
          [ngClass]="{
            ____down: isActive,
            '___icon-up': headingActive === i,
            '___icon-down': headingActive != i
          }"
        ></i>
      </div>
    </div>

    <!--  <div class="__heading">
          <img src="../../../assets/images/icons/nav-account-icon.png">
          <span class="ml1 txt-sm font-brand txt-blue-dark">Account</span>
      </div> -->
  </div>
  <!-- <div class="nav-submenu nav-position" [ngClass]="isActive ? 'block': 'hide'" (mouseover)="openNav()" (mouseout)="closeNav()"> -->

  <!--For faded dark background when submenu is open-->
  <div
    class="opaque-background"
    [ngClass]="isActive ? 'block' : 'hide'"
    (click)="closeNav('none')"
  ></div>
  <div class="nav-submenu nav-position" [ngClass]="isActive ? 'block' : 'hide'">
    <div class="container _submenu-options py5">
      <div class="__item font-brand" *ngFor="let submenuObj of submenuObjs">
        <div class="___header txt-color-primary pt5 pb2 mb4">
          {{ submenuObj.submenuTitle }}
        </div>
        <div *ngFor="let submenuLink of submenuObj.submenuLinks">
          <a
            class="___links txt-black mb4 block"
            *ngIf="submenuLink.external === 'false' || !submenuLink.external"
            [routerLink]="submenuLink.href"
            (click)="closeNav(submenuLink)"
          >
            {{ submenuLink.submenuLinksTitle }}

            <span class="txt-blue-dark txt-sm font-primary block py1">{{
              submenuLink.subtitle
            }}</span>
          </a>

          <a
            class="___links external-link txt-black mb4 block"
            *ngIf="submenuLink.external === 'true'"
            [href]="submenuLink.href"
            (click)="closeNav(submenuLink)"
            target="_blank"
          >
            {{ submenuLink.submenuLinksTitle }}
            <img
              src="assets/images/icons/icon-external-link-primary.svg"
              class="ml1"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="nav-footer">
      <div
        class="_close bg-color-primary txt-white txt-base"
        (click)="closeNav('none')"
      >
        <i class="fal fa-times mr2"></i>Close menu
      </div>

      <!--<div class="container _submenu-footer" *ngFor="let footerObj of footerObjs">
      <div class="__info" *ngIf="footerObj.footerText">
        <span class="txt-base txt-blue-dark mr3">In credit:</span>
        <span class="txt-base txt-color-primary mr3">Electricity $189</span>
      </div>
      <div class="__quick-links">
        <span class="txt-base txt-blue-dark mr3">Quick links:</span>
        <app-button btnClass="brand-btn-info" *ngFor="let footerQuickLink of footerObj.footerQuickLinks" class="mr3">{{footerQuickLink.footerLinkTitle}}</app-button>
      </div>
    </div>-->
    </div>
  </div>
</div>

<!--Special: Make a payment nav -->
<div class="bg-color-primary" *ngIf="paymentActionNav">
  <div class="container make-payment-nav">
    <div class="_logo">
      <img class="mydodo-logo" src="assets/images/brand-white.svg" />
      <a class="sm-only txt-base txt-white" (click)="gotoBack()">
        <i class="fas fa-angle-left txt-base txt-white"></i
      ></a>
    </div>
    <div class="_main-nav txt-white txt-base font-brand font-semibold">
      <div class="__first-item sm-hide">
        <img
          src="../../../assets/images/icons/icon-home-white.svg"
          class="mr2"
        />
        <a class="" routerLink="/dashboard">Back to Dashboard</a>
      </div>
      <div class="__second-item">
        <img
          src="../../../assets/images/icons/icon-make-payment-white.svg"
          class="mr2"
        />
        <span class="">Make a payment</span>
      </div>
    </div>
  </div>
</div>

<!--Special: Other action nav -->
<div class="bg-white" *ngIf="otherActionNav">
  <div class="container other-action-nav">
    <div class="_logo">
      <img class="mydodo-logo" src="assets/images/brand-primary.svg" />
      <a class="sm-only txt-base txt-color-primary" (click)="gotoBack()">
        <i class="fas fa-angle-left txt-base txt-color-primary"></i
      ></a>
    </div>
    <div class="_main-nav txt-color-primary txt-base font-brand font-semibold">
      <div class="__first-item sm-hide">
        <img
          src="../../../assets/images/icons/icon-home-primary.svg"
          class="mr2"
        />
        <a class="" routerLink="/dashboard">Back to Dashboard</a>
      </div>
      <!--For review instalment plan-->
      <div
        class="__second-item"
        *ngIf="otherActionNavMsg === 'Review instalment plan'"
      >
        <img
          src="../../../assets/images/icons/icon-instalment-plan.svg"
          class="mr2"
        />
        <span class="">{{ otherActionNavMsg }}</span>
      </div>

      <!--Desktop: For payment plan/ extension-->
      <div
        class="__second-item sm-hide"
        *ngIf="otherActionNavMsg === 'Help paying my bill'"
      >
        <img
          src="../../../assets/images/icons/icon-payment-plan.svg"
          class="mr2"
        />
        <span class="">{{ otherActionNavMsg }}</span>
      </div>
      <!--Desktop: For credit refund-->
      <div
        class="__second-item sm-hide"
        *ngIf="otherActionNavMsg === 'Credit refund request'"
      >
        <img
          src="../../../assets/images/icons/icon-make-payment.svg"
          class="mr2"
        />
        <span class="">{{ otherActionNavMsg }}</span>
      </div>
      <!--Mobile: For payment plan/ extension and credit refund-->
      <div
        class="txt-center-align sm-only"
        *ngIf="
          otherActionNavMsg === 'Help paying my bill' ||
          otherActionNavMsg === 'Credit refund request'
        "
      >
        <span class="block txt-sm txt-grey-darker pb2 font-primary">{{
          otherActionNavMsg
        }}</span>
        <span class="block txt-base font-brand txt-black font-semibold">{{
          otherActionNavSubMsg
        }}</span>
      </div>
    </div>
  </div>
</div>
