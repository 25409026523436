import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { TealiumService } from "../../tealium/services/tealium.service";

@Injectable({
	providedIn: "root"
})
export class HttpApiErrorHandlerService {

	constructor(private tealiumService: TealiumService) { }

	handleError(error: HttpErrorResponse): Observable<never> {
		let errorMessage = "An unknown error occurred!";
		let errorCode: any;
		let msAPIcallflg = false;

		if (error.error instanceof ErrorEvent) {
			errorMessage = `Client-side error: ${error.error.message}`;
		} else {
			errorCode = error.error?.code || "Unknown";
			errorMessage = `Server-side error: errorstatus:${error.status}, error message: ${error.message}, errorcode:${error.error.code}`;

		}

		if (error?.status !== error.error?.code) {
			msAPIcallflg = true;
		}
		const errorHttpDetails = {
			errorCode: error.status,
			errorMessage: error.message,
			pageUrl: window.location.href,
			timestamp: new Date().toISOString()
		};

		const errorApiDetails = {
			errorCode: error.error.code,
			errorMessage: error.message,
			pageUrl: window.location.href,
			timestamp: new Date().toISOString()
		};

		if (msAPIcallflg === false) {
			this.tealiumService.trackHttpErrorPages(errorHttpDetails);
		} else {
			this.tealiumService.trackHttpErrorPages(errorHttpDetails);
			this.tealiumService.trackApiErrorPages(errorApiDetails);
		}

		return throwError(() => new Error(errorMessage));
	}
}
