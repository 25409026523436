import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentService } from "src/app/self-serve/services/payment.service";
import { IPaymentSubmitMessage } from "src/app/self-serve/models/payment.model";
import { AppStoreService } from "src/app/services/appStore.service";
import { ISelectedPayment } from "src/app/self-serve/models/store.model";
import { IPaymentPlanInProgressSummary } from "src/app/self-serve/models/payment-plan.model";
import { TealiumService } from "src/tealium/services/tealium.service";
import { PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES } from "./payment-summary-verification.enum";

@Component({
	selector: "dodo-payment-summary-verification",
	templateUrl: "./dodo-payment-summary-verification.component.html",
	styleUrls: ["./payment-summary-verification.component.scss"]
})
export class DodoPaymentSummaryVerificationComponent implements OnInit {
	@Input() step;
	product: {};
	payment: ISelectedPayment;
	productId;
	data = {};
	message: IPaymentSubmitMessage;
	public errorMessage;
	public busy = false;
	paymentPlanSummary: IPaymentPlanInProgressSummary[];

	constructor(
		private router: Router,
		private paymentService: PaymentService,
		private appStoreService: AppStoreService, private tealiumService: TealiumService
	) { }

	ngOnInit() {
		this.payment = this.appStoreService.store.customer.payment;
	}

	public continueToSecurePayment(): void {
		this.busy = true;
		const paymentPlanExturl = this.appStoreService.store
			.productPaymentPlanExtPath.paymentPlanExturl;

		if (paymentPlanExturl === "payment-amount-entry") {
			this.data = {
				paymentMethodId: this.payment.selectedPaymentMethod.id,
				totalAmount: { value: +this.payment.amount, unit: "AUD" }
			};
		} else if (paymentPlanExturl === "in-progress-summary") {
			this.paymentPlanSummary = this.payment.selectedProduct.payment.inProgressPlanSummary;
			const selectedPayments = this.payment.selectedProduct.payment.inProgressPlanSummary
				.filter((objInProgressSummmary) => objInProgressSummmary.selected)
				.map((objInProgressSummmary) => {
					return {
						item: { id: objInProgressSummmary.id.toString(), type: "paymentInstalmentId" },
						amount: { value: objInProgressSummmary.amount.value, unit: "AUD" }
					};
				});

			this.data = {
				name: "PaymentPlan",
				paymentMethodId: this.payment.selectedPaymentMethod.id,
				totalAmount: { value: +this.payment.amount, unit: "AUD" },
				paymentItem: selectedPayments
			};
		}

		this.paymentService
			.postDodoPaymentDetails(this.payment.selectedProduct.id, this.data)
			.subscribe(
				(dataMessage) => {
					this.busy = false;
					this.message = dataMessage;
					if (this.message.status === "success") {
						this.router.navigate([
							"/make-a-payment/payment-successful/",
							this.payment.selectedProduct.id
						]);
					} else {
						this.errorMessage = PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES.CANNOT_PROCESS_PAYMENT_API_ERROR;
						this.tealiumService.setFlashErrorMsg(this.errorMessage, undefined);
					}
				},
				(error) => {
					this.busy = false;
					if (error.status === 400) {
						if (error.error.code === 20) {
							this.errorMessage = error.error.message;
							this.tealiumService.setFlashErrorMsg(this.errorMessage, error?.error?.code);
						} else {
							this.errorMessage = PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES.CANNOT_PROCESS_PAYMENT_API_ERROR;
							this.tealiumService.setFlashErrorMsg(this.errorMessage, error?.error?.code);
						}
					} else {
						this.errorMessage = PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES.CANNOT_PROCESS_PAYMENT_API_ERROR;
						this.tealiumService.setFlashErrorMsg(this.errorMessage, error?.error?.code);
					}
				}
			);
	}

	public continueToPaymentType(): void {
		this.router.navigate([
			"/make-a-payment/payment-type/",
			this.payment.selectedProduct.id
		]);
	}

	public getIconPath(icon): string {
		if (icon === "Homephone") {
			icon = "home-phone";
		}
		return `assets/images/icons/icon-${icon.toLowerCase()}.svg`;
	}
}
