// angular
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";

// services
import { environment } from "../../../environment";

@Injectable()
export class PacketInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
	request: HttpRequest<any>,
	next: HttpHandler
  ): Observable<HttpEvent<any>> {
	const requestURL = request.url;
	const index = requestURL.search("exp-publicselfservice");
	if (index === -1) {
		if (requestURL.search("assets") < 0) {
		const headers = request.headers
			.set("client_id", `${environment.authorizeHeaders.client_id}`)
			.set("Cache-Control", "no-cache, no-store, must-revalidate")
			.set("Pragma", "no-cache")
			.set("Expires", "0")
			.set(
			"client_secret",
			`${environment.authorizeHeaders.client_secret}`
			)
			.set("destination", "auth0")
			.set("groupId", environment.brand)
			.set("source", " experience-api")
			.set("content-type", "application/json");
		const authorizedRequest = request.clone({ headers });
		return next.handle(authorizedRequest);
		} else {
		return next.handle(request);
		}
	} else {
		const headers = request.headers
		.set("client_id", `${environment.authorizeUnAuthHeaders.client_id}`)
		.set("Cache-Control", "no-cache, no-store, must-revalidate")
		.set("Pragma", "no-cache")
		.set("Expires", "0")
		.set(
			"client_secret",
			`${environment.authorizeUnAuthHeaders.client_secret}`
		)
		.set("groupId", environment.brand)
		.set("Content-Type", "application/json");
		const authorizedRequest = request.clone({ headers });
		return next.handle(authorizedRequest);
	}
  }
}
