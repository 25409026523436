export enum MODAL_MESSAGES {
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700002 = "Unfortunately you can't setup a payment plan or extension as you haven't received your first invoice.",
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700006 = "Unfortunately you are not currently eligible for a payment plan or extension as your account balance is $0 or lower.",
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700008_700009 = "Unfortunately you are not currently eligible for a payment plan or extension as you already have an existing one setup.",
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700011 = "Unfortunately you are not currently eligible for a payment plan or extension, as you have reached the maximum number of payment plans or extensions for ",
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_UNAUTH_700011 = "Unfortunately you are not currently eligible for a payment plan or extension, as you have reached the maximum number of payment plans or extensions for 60 days (max limit of 2).",
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700012 = "Unfortunately you are not currently eligible for a payment extension, as your account balance is less than the minimum instalment amount required ($10).",
  NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700013 = "Unfortunately you are not currently eligible for a payment plan or extension, as your account balance is higher than $1,000.",
  NOTIFY_MSG_PAYMENT_PLAN_DEFAULT = "Unfortunately you are not currently eligible for a payment plan or extension.",
  ERROR_MSG_UNKNOWN_FAILURE = "Something went wrong at this time, try again later"
}
