import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { YesNoButtonsComponent } from "./yes-no-buttons/yes-no-buttons.component";
import { ButtonGroupComponent } from "./button-group/button-group.component";

@NgModule({
	imports: [CommonModule, MatButtonModule],
	declarations: [
		ButtonGroupComponent,
		YesNoButtonsComponent
	],
	exports: [
		ButtonGroupComponent,
		YesNoButtonsComponent
	]
})
export class DodoButtonsModule { }
