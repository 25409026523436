import { Component, OnInit } from "@angular/core";
import {
	parseCreationOptionsFromJSON,
	create,
	get,
	parseRequestOptionsFromJSON,
	RegistrationPublicKeyCredential
} from "@github/webauthn-json/browser-ponyfill";
import { PublicKeyCredentialDescriptorJSON } from "@github/webauthn-json";
import type { RegistrationResponseJSON } from "@github/webauthn-json/browser-ponyfill";

@Component({
	selector: "app-pwa-biometric",
	templateUrl: "./pwa-biometric.component.html",
	styleUrls: ["./pwa-biometric.component.scss"]
})
export class PWABiometricComponent implements OnInit {
	ngOnInit(): void { }

	public async createPasskey() {
		if (!navigator.credentials || !navigator.credentials.create || !navigator.credentials.get) {
			return alert("Your browser does not support the Web Authentication API");
		}

		const credentials = await parseCreationOptionsFromJSON({
			publicKey: {
				challenge: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
				rp: { name: "MyDodo", id: window.location.hostname },
				user: { id: "IIIIIIIIIII", name: "pratik.sawant@vocus.com.au", displayName: "Pratik Sawant" },
				pubKeyCredParams: [],
				authenticatorSelection: { userVerification: "discouraged" },
				extensions: { credProps: true }
			}
		});

		this.saveRegistration(await create(credentials));
	}

	public async verifyPasskey() {
		try {
			const credentials = await get(parseRequestOptionsFromJSON({
				publicKey: {
					challenge: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
					allowCredentials: this.registeredCredentials()
				}
			}));
			console.log(credentials);
			window.location.replace("/dashboard");
		} catch (err) {
			alert(err);
		}
	}

	public isUserProfileAvailable(): boolean {
		return localStorage.getItem("biometric-user") === "" || localStorage.getItem("biometric-user") === null;
	}

	public saveRegistration(
		registration: RegistrationPublicKeyCredential
	): void {
		const registrations = this.getRegistrations();
		registrations.push(registration.toJSON());
		this.setRegistrations(registrations);
	}

	public getRegistrations(): RegistrationResponseJSON[] {
		const registrations = JSON.parse(
			localStorage.getItem("biometric-user") || "[]"
		);
		return registrations;
	}

	public setRegistrations(
		registrations: RegistrationResponseJSON[]
	): void {
		localStorage.setItem("biometric-user", JSON.stringify(
			registrations,
			null,
			"  "
		));
		this.displayRegistrations();
	}

	public displayRegistrations() {
		console.log(JSON.stringify(this.getRegistrations(), null, "  "));
	}

	public registeredCredentials(): PublicKeyCredentialDescriptorJSON[] {
		return this.getRegistrations().map((reg) => ({
			id: reg.rawId,
			type: reg.type
		}));
	}
}
