import { Injectable } from "@angular/core";
// store
import { AppStoreService } from "../services/appStore.service";

@Injectable({
	providedIn: "root"
})
export class AuthenticationService {
	constructor(
		private appStoreService: AppStoreService
	) { }

	public isLoggedIn() {
		if (this.appStoreService.store.customer) {
			if (!!this.appStoreService.store.customer.token && !!this.appStoreService.store.customer.email_verified) {
				return true;
			}
			return false;
		} else { return false; }
	}
	LoggedOut() {
		this.appStoreService.flushAppStoreLocalStorage();
	}
}
