import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { IMfaMedium, IMfaCodeTriggeredbySMSresponse, IMfaCodeTriggeredbyEmailresponse, IMfaCodeValidateResponse } from "../models/mfa-contactmedium.model";
import { environment } from "src/environment";
@Injectable({
	providedIn: "root"
})
export class Auth0MfaService {
	constructor(
		private httpClient: HttpClient
	) { }

	getMfaContacts(): Observable<IMfaMedium> {
		const url = `${environment.apiURL
			}/api/v1/customermgmt/contactMedium`;
		return this.httpClient.get<IMfaMedium>(url);
	}

	public triggerMFAcodebySMS(data): Observable<IMfaCodeTriggeredbySMSresponse> {
		const url = `${environment.apiURL}/api/v1/mfamgmt/communicationMessage`;
		return this.httpClient.post<IMfaCodeTriggeredbySMSresponse>(url, data);
	}

	public triggerMFAcodebyEmail(data): Observable<IMfaCodeTriggeredbyEmailresponse> {
		const url = `${environment.apiURL}/api/v1/mfamgmt/communicationMessage`;
		return this.httpClient.post<IMfaCodeTriggeredbyEmailresponse>(url, data);
	}

	public validateMFAcode(data): Observable<IMfaCodeValidateResponse> {
		const url = `${environment.apiURL}/api/v1/mfamgmt/validate`;
		return this.httpClient.post<IMfaCodeValidateResponse>(url, data);
	}

}
