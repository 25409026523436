<div class="container">
  <div class="progress-bar-images my5 txt-sm lg-only">
    <div class="_back-button txt-color-primary" (click)="gotoBack()">
      <i class="fas fa-angle-left mr1"></i
      ><span class="txt-grey-darker"> Back</span>
    </div>
    <img src="assets/images/payment/progress-desktop-1.svg" />
  </div>
  <div class="progress-bar-images my5 txt-sm md-only">
    <div class="_back-button txt-color-primary" (click)="gotoBack()">
      <i class="fas fa-angle-left mr1"></i>
    </div>
    <img src="assets/images/payment/progress-tablet-1.svg" />
  </div>
  <div class="progress-bar-images bg-white pb5 sm-only">
    <img src="assets/images/payment/progress-mobile-1.svg" />
  </div>

  <section *ngIf="billingAccount">
    <ng-container *ngIf="loadingplan === false; else paymentloader">
      <div class="make-payment bg-white">
        <section class="heading">
          <app-payment-header
            ><span
              >Today's balance is ${{
                billingAccount.accountBalance?.amount?.value | number : "0.2-2"
              }}</span
            >
            <div
              class="_outage-message p2 mt2 font-brand"
              *ngIf="hasOutage === true"
            >
              <i class="fas fa-exclamation-circle"></i>
              {{ msgHasOutage }}
            </div>
          </app-payment-header>
        </section>
        <section class="payment-amount-step1">
          <app-payment-amount-entry
            [billingAccount]="billingAccount"
            (continue)="continueToPayment($event)"
          ></app-payment-amount-entry>
        </section>

        <section class="summary-step4">
          <app-payment-summary-verification
            step="FIRST"
            [billingAccount]="billingAccount"
          ></app-payment-summary-verification>
        </section>
      </div>
    </ng-container>
  </section>

  <!-- For Billit Customers -->
  <section *ngIf="!billingAccount">
    <ng-container *ngIf="payment; else paymentloader">
      <ng-container
        *ngIf="loadingplan === true; then paymentloader; else paymentplan"
      >
      </ng-container>

      <ng-template #paymentplan>
        <div
          *ngIf="
            showPaymentPlan &&
            !apiInProgressPaymentPlanExtError &&
            isDataAvailable
          "
        >
          <dodo-in-progress-payment-plans
            productId="{{ productId }}"
          ></dodo-in-progress-payment-plans>
          <div
            class="_question font-brand txt-black flex flex-align-items-center flex-justify-content-between pl3 pb2 pr3 pt2"
          >
            <span
              >You have an active payment plan. If you wish to pay a bill not
              included in your payment plan, select this box.</span
            >
            <div class="flex flex-align-items-center">
              <label class="container-checkbox">
                <input
                  type="checkbox"
                  name="makePayforRegularBill"
                  [(ngModel)]="flgmakePayforRegularBill"
                  (ngModelChange)="hideActivePaymentPlans()"
                  [checked]="flgmakePayforRegularBill"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </ng-template>

      <div
        class="make-payment bg-white"
        *ngIf="
          (loadingplan === false && flgmakePayforRegularBill) ||
          (loadingplan === false &&
            !flgmakePayforRegularBill &&
            !apiInProgressPaymentPlanExtError &&
            !isDataAvailable)
        "
      >
        <section class="heading">
          <app-payment-header
            ><span
              *ngIf="
                payment.selectedProduct &&
                payment.selectedProduct.accountBalance
              "
              >Today's balance is ${{
                payment.selectedProduct.accountBalance.amount.value
                  | number : "0.2-2"
              }}</span
            >
            <div class="___disclaimer p2 mt2 font-brand">
              <i class="fas fa-exclamation-circle"></i>
              Payment cannot be more than the account balance.
            </div>
          </app-payment-header>
        </section>
        <section class="payment-amount-step1">
          <dodo-payment-amount-entry
            [isDataAvailable]="isDataAvailable"
            flgmakePayforRegularBill="{{ flgmakePayforRegularBill }}"
          ></dodo-payment-amount-entry>
        </section>

        <section class="summary-step4">
          <dodo-payment-summary-verification
            step="FIRST"
          ></dodo-payment-summary-verification>
        </section>
      </div>
    </ng-container>
  </section>

  <section class="cancel-payment" *ngIf="loadingplan === false">
    <app-cancel-payment></app-cancel-payment>
  </section>

  <ng-template #paymentloader>
    <section class="py5">
      <div class="flex flex-align-items-center flex-justify-content-center my5">
        <img src="assets/images/common/preloader.gif" alt="preloader" />
      </div>
    </section>
  </ng-template>
</div>
