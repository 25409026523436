import { Injectable } from "@angular/core";
import * as auth0 from "auth0-js";
import { HttpService } from "src/libraries/api/services/http.service";
import { ICustomer } from "../self-serve/models/store.model";
import { AppStoreService, APP_EVENT, AppStore } from "../services/appStore.service";
import { Observable } from "rxjs";
import { IResetPwdResponse } from "../auth0/auth0.config";
import { environment } from "src/environment";
import { Auth0MfaService } from "../self-serve/services/auth0-mfa.service";
import { IMfaMedium } from "../self-serve/models/mfa-contactmedium.model";
import { filter } from "rxjs/operators";
import { DeepLinkingService } from "src/app/services/deeplinking.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoadingService } from "src/app/self-serve/services/loading.service";
import { FeatureFlag } from "../services/feature-flags.enum";
import { FeatureFlagService } from "../services/feature-flag.service";
@Injectable({
	providedIn: "root"
})
export class Auth0Service {
	[x: string]: any;
	private __idToken: string;
	private __idTokenPayload: any;
	private __accessToken: string;

	private __auth0: auth0.WebAuth;
	isProducdUpdated = false;
	public mfaMediums: IMfaMedium[] = [];
	public _appStore: AppStore;
	destroyed$ = new Subject<boolean>();

	constructor(
		private __httpService: HttpService,
		public appStoreService: AppStoreService,
		private auth0MfaService: Auth0MfaService,
		public deepLinkingService: DeepLinkingService,
		private loadingService: LoadingService,
		private featureFlagService: FeatureFlagService
	) {
		this.__idToken = "";
		this.__accessToken = "";

		this.__auth0 = new auth0.WebAuth({
			clientID: environment.auth0Config.clientId,
			domain: environment.auth0Config.domain,
			audience: `https://mydodoexp.api.vocus.com.au`,
			responseType: "token id_token",
			redirectUri: `${window.location.origin}${environment.auth0Config.callbackUrl}`,
			scope: "write:identity mydodo:user openid email profile"
		});
	}

	get accessToken(): string {
		return this.__accessToken;
	}

	get idToken(): string {
		return this.__idToken;
	}

	public login(): void {
		this.appStoreService.updateAuth(false);
		this.__auth0.authorize();
	}

	public handleAuthentication(): void {
		this.__auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				window.location.hash = "";
				this.localLogin(authResult);
				this.__storeUserDetails(authResult.expiresIn);
				if (this.__idTokenPayload.email_verified) {
					if (this.appStoreService.store.urlDeeplinkDetails === undefined) {
						this.getAuth0MfaContacts();
					}
				} else if (this.__idTokenPayload.email_verified === false) {
					console.log("new billit customer is being migrated:", this.__idTokenPayload.email_verified);
				}
			} else {
				this.logout();
			}
		});
	}

	public handleAuthenticationDeepLinking(): boolean {
		this.__auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				window.location.hash = "";
				this.localLogin(authResult);
				this.__storeUserDetails(authResult.expiresIn);
				if (this.__idTokenPayload.email_verified) {
					return true;
				}
			} else {
				this.logout();
			}
		});
		return false;
	}

	/**
	 * submitAuth0Email
	 *
	 */
	public submitAuth0Email(email: string): Observable<boolean> {
		const username = this.__idTokenPayload[
			environment.auth0Config.appMetaData
		].username;
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/usermgmt/user/${username}`;
		const body = {
			sub: this.__idTokenPayload.sub,
			email: email
		};
		return this.__httpService.patch(url, body);
	}

	private __storeUserDetails(tokenExpiresIn) {
		const userProfile = this.__idTokenPayload[
			environment.auth0Config.appMetaData
		];

		const customer = <ICustomer>{
			tokenPayload: this.__accessToken,
			token: this.__accessToken,
			username: userProfile.username,
			customerId: userProfile.cid,
			assocId: userProfile.assoc_id,
			email: userProfile.email,
			email_verified: this.__idTokenPayload["email_verified"],
			associatedEmails: userProfile.rel_emails,
			auth0Sub: this.__idTokenPayload.sub,
			usernamedisplay: this.__idTokenPayload["name"],
			accountId: userProfile.sf_account_id,
			tokenExpiryTime: Math.ceil(Date.now() / 1000) + tokenExpiresIn
		};

		this.appStoreService.updateCustomer(customer);
	}

	private localLogin(authResult: any): void {
		this.__accessToken = authResult.accessToken;
		this.__idTokenPayload = authResult.idTokenPayload;
		this.__idToken = authResult.idToken;
	}

	public renewTokens(): void {
		this.__auth0.checkSession({}, (err: any, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				this.localLogin(authResult);
			} else if (err) {
				alert(
					`Could not get a new token (${err.error}: ${err.error_description}).`
				);
				this.logout();
			}
		});
	}

	public logout(): void {
		this.resetTokens();
		this.__auth0.logout({
			returnTo: environment.auth0Config.returnTo
		});
	}

	public resetAuth0Tokens(): void {
		this.resetTokens();
	}

	private resetTokens(): void {
		this.__accessToken = "";
		this.__idToken = "";
	}

	public resetPwdEmailSentStatus(data): Observable<IResetPwdResponse> {
		const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/communicationmgmt/communicationMessage`;
		return this.__httpService.post(url, data);
	}

	public recoverUsername(data): Observable<IResetPwdResponse> {
		const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/communicationmgmt/communicationMessage`;
		return this.__httpService.post(url, data);
	}

	public changePassword(data): Observable<any> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer`;
		return this.__httpService.patch(url, data);
	}

	public getUserInfo(getUserdata): Observable<any> {
		const url = `${environment.apiURL}/api/v1/federatedIdMgmt/userInfo`;
		return this.__httpService.patch(url, getUserdata);
	}

	public getAuth0MfaContacts() {
		this._appStore = this.appStoreService.store;
		this.DPGOnlyflg = false;
		this.apiMfaContactsError = false;

		this.auth0MfaService
			.getMfaContacts()
			.subscribe(
				mfaMediums => {
					if (mfaMediums.hasOwnProperty("characteristics") === true) {
						mfaMediums.characteristics.map(characteristics => {
							if (characteristics.name === "DPGOnly" && characteristics.value === "true") {
								this.DPGOnlyflg = true;
								this.appStoreService.updMfaDPGnErrflg(this.DPGOnlyflg, this.apiMfaContactsError);
							} else
								if (characteristics.name === "DPGOnly" && characteristics.value === "false") {
									this.DPGOnlyflg = false;
									this.appStoreService.updMfaDPGnErrflg(this.DPGOnlyflg, this.apiMfaContactsError);
								}
						});
					} else {
						window.location.replace("auth0-mfa/mfa-error");
					}
					this.mfaMediums.push(mfaMediums);
					this.appStoreService.updateMfaMediums(this.mfaMediums);
				},
				(error) => {
					this.apiMfaContactsError = true;
					this.appStoreService.updMfaDPGnErrflg(this.DPGOnlyflg, this.apiMfaContactsError);
					window.location.replace("auth0-mfa/mfa-error");
				},
				() => {
					console.log("subscriber for getMfaContacts completed");
				}
			);
		this.appStoreService.storeUpdated
			.pipe(filter(event => event === APP_EVENT.MFA_DPGONLY_AND_ERROR_FLG),
				takeUntil(this.destroyed$))
			.subscribe(() => {
				if (this._appStore.apiMfaContactsError === true) {
					window.location.replace("auth0-mfa/mfa-error");
				} else {
					this.appStoreService.storeUpdated
						.pipe(filter(event => event === APP_EVENT.MFA_CONTACT_MEDIUM_UPDATED),
							takeUntil(this.destroyed$))
						.subscribe(async () => {
							const nameOnePlatform = this._appStore.mfaMediums[0]?.characteristics[1]?.name;
							const valueOnePlatform = this._appStore.mfaMediums[0]?.characteristics[1]?.value;
							const nameBillit = this._appStore.mfaMediums[0]?.characteristics[2]?.name;
							const valueBillit = this._appStore.mfaMediums[0]?.characteristics[2]?.value;
							if (this._appStore.DPGOnlyflg === false) {
								if (this._appStore?.mfaMediums !== undefined) {
									if (this._appStore?.mfaMediums.length > 0) {
										if (this._appStore?.mfaMediums[0]?.contactMedium?.length > 0 &&
											this._appStore?.mfaMediums[0]?.contactMedium !== undefined && this._appStore?.mfaMediums[0]?.contactMedium !== null) {
												if (this.featureFlagService.isEnabled(FeatureFlag.PWABiometric)) {
													window.location.replace("/dashboard");
												} else {
													window.location.replace("/auth0-mfa");
												}
										} else {
											if (nameOnePlatform === "OnePlatform" && valueOnePlatform === "true") {
												window.location.replace("auth0-mfa/mfa-error");
											} else if (nameBillit === "Billit" && valueBillit === "true") {
												window.location.replace("auth0-mfa/mfa-error");
											} else {
												// making sure to only show the  no contact error message, if no system error.
												window.location.replace("auth0-mfa/mfa-nocontacts-error");
											}
										}
									} else {
										window.location.replace("auth0-mfa/mfa-error");
									}
								}
							} else { // for DPGonly
								try {
									const allProducts = await Promise.all(this.loadingService.fetchProducts());
									// this.preloadCompleted(allProducts);
									// const currentTimeStamp = Math.ceil(Date.now() / 1000);
									// this.appStoreService.updateProductsReqTimestamp(currentTimeStamp);
									if (this.appStoreService?.store?.urlDeeplinkDetails !== undefined) {
										// this.deepLinkingService.openDeeplink();
										window.location.replace("/dashboard");
									} else {
										window.location.replace("/dashboard");
									}
								} catch (err) {
									window.location.replace("auth0-mfa/mfa-error");
								}
							}
						});
				}
			});
		this.appStoreService.storeUpdated
			.pipe(filter(eventTrigger => eventTrigger === APP_EVENT.DEEPLINK_OPEN_FLG))
			.subscribe(() => {
				if (this.__appStoreService?.store?.urlDeeplinkDetails !== undefined) {
					this.deepLinkingService.openDeeplink();
				}
			});
		this.appStoreService.storeUpdated
			.pipe(filter(eventTrigger => eventTrigger === APP_EVENT.PRODUCTS_FLG))
			.subscribe(() => {
				window.location.replace("/dashboard");
			});
	}

	public chkAuth0MfaContacts() {
		this._appStore = this.appStoreService.store;
		this.DPGOnlyflg = false;
		this.apiMfaContactsError = false;

		this.auth0MfaService
			.getMfaContacts()
			.subscribe(
				(mfaMediums) => {
					if (mfaMediums.hasOwnProperty("characteristics") === true) {
						mfaMediums.characteristics.map(characteristics => {
							if (characteristics.name === "DPGOnly" && characteristics.value === "true") {
								this.DPGOnlyflg = true;
								this.appStoreService.updMfaDPGnErrflgMigrate(this.DPGOnlyflg, this.apiMfaContactsError);
							} else
								if (characteristics.name === "DPGOnly" && characteristics.value === "false") {
									this.DPGOnlyflg = false;
									this.appStoreService.updMfaDPGnErrflgMigrate(this.DPGOnlyflg, this.apiMfaContactsError);
								}
						});
					} else {
						window.location.replace("auth0-mfa/mfa-error");
					}
				},
				(error) => {
					this.apiMfaContactsError = true;
					this.appStoreService.updMfaDPGnErrflgMigrate(this.DPGOnlyflg, this.apiMfaContactsError);
					window.location.replace("auth0-mfa/mfa-error");
				}
			);
	}

	public submitAuth0EmailMigrate(email: string, username: string, auth0Sub: any): Observable<boolean> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/usermgmt/user/${username}`;
		const body = {
			sub: auth0Sub,
			email: email
		};
		return this.__httpService.patch(url, body);
	}

	public isTokenExpired(): boolean {
		const sessionLoginStartTime = Math.ceil(Date.now() / 1000);
		return sessionLoginStartTime > this.appStoreService.store.customer.tokenExpiryTime ? false : true;
	}

	private preloadCompleted(allproducts) {
		if (allproducts && allproducts.length > 0 && this.appStoreService?.store?.urlDeeplinkDetails !== undefined) {
			const currentTimeStamp = Math.ceil(Date.now() / 1000);
			this.appStoreService.updateProductsReqTimestamp(currentTimeStamp);
			this.appStoreService.updateDeeplinkOpen(true);
		} else if (allproducts && allproducts.length > 0 && this.appStoreService?.store?.urlDeeplinkDetails === undefined) {
			const currentTimeStamp = Math.ceil(Date.now() / 1000);
			this.appStoreService.updateProductsReqTimestamp(currentTimeStamp);
			this.appStoreService.updateProductsflg(true);
		} else {
			this.router.navigate(["/auth0-mfa/mfa-error"]);
		}
	}
}
