import { Injectable } from "@angular/core";

// libraries
import { HttpService } from "../../libraries/api/services/http.service";

// rxjs
import { Observable } from "rxjs";

// store
import { AppStoreService } from "../services/appStore.service";
import { environment } from "src/environment";

@Injectable({
  providedIn: "root"
})
export class AuthcustomerService {
  constructor(
	private httpService: HttpService,
	private appStoreService: AppStoreService
  ) {}

  authenticateCustomer(
	contactservicecustomerid,
	customerdob
  ): Observable<any[]> {
	const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/customermgmt/customer?referenceText=${contactservicecustomerid}&dateOfBirth=${customerdob}`;
	return this.httpService.get<any[]>(url);
  }

  public UnAuthisLoggedIn() {
	if (this.appStoreService.store.customerunauth) {
		return true;
	} else { return false; }
  }

  UnAuthLoggedOut() {
	this.appStoreService.flushAppStoreLocalStorage();
  }

  authenticateSMScustomer(
	encrypted_contactservicecustomerid_dob
  ): Observable<any[]> {
	const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/customermgmt/customer?encrytedText=${encrypted_contactservicecustomerid_dob}`;
	return this.httpService.get<any[]>(url);
  }

}
