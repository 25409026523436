import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClickOutsideDirective } from "./clickoutside.directive";
import { CustomScrollDirective } from "./custom-scroll.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [
        ClickOutsideDirective,
        CustomScrollDirective
    ],
    exports: [
        ClickOutsideDirective,
        CustomScrollDirective
    ]
})
export class DodoDirectivesModule {}
