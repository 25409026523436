
<div class="modal-dialog move-home-mobile" role="document">
  <div class="modal-content">
    <!-- Header -->
    <div class="modal-header flex justify-content-center position-relative">
      <div class="flex flex-justify-content-between flex-align-items-center txt-white font-semibold">
        Moving service(s)
      </div>
      <button
        type="button"
        class="close heading-bottom"
        data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <!-- Content -->
    <div class="modal-body py5 px4 w-100">
      <div class="header pb5 font-brand font-semibold txt-color-primary text-center">
        Moving your mobile service?
      </div>
      <div class="description pb5 text-center">
        Please update your address details in mydodo account.
      </div>
      
      <div class="flex justify-content-center">
        <button
          class="option block btn brand-btn-info py2"
          (click)="goToUpdateDetails()"
          data-dismiss="modal">
          UPDATE DETAILS
        </button>
      </div>
    </div>

  </div>
  <!-- <div class="modal-content">
    <div class="modal-header">
      <div class="description font-brand font-semibold flex flex-justify-content-between flex-align-items-center">
        <span class="txt-color-primary">Moving your mobile service?</span>
      </div>
      <button
        type="button"
        class="close heading-bottom"
        data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body pb5">
      <div class="heading font-brand font-semibold txt-color-primary">
        Please update your address details in mydodo account.
      </div>

      <div class="description p3 txt-black">
        <button
          class="block btn brand-btn-secondary py2"
          (click)="goToUpdateDetails()"
          data-dismiss="modal">
          UPDATE DETAILS
        </button>
      </div>
    </div>

  </div> -->

</div>