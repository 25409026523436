<section class="bg-color-primary height-full" *ngIf="_customer; else preloader">
  <!--When user has not set email address-->
  <section
    class="mfa-billit bg-color-primary"
    *ngIf="!_customer.email_verified && !isEmailValid"
  >
    <!--This is for successful post message-->
    <div class="_login-container bg-white p5" *ngIf="isPostSuccessful">
      <div
        class="__heading txt-center-align font-brand txt-color-primary font-semibold pb2"
      >
        <img src="assets/images/modal/green-tick-circle.svg" class="py3" />
        <span class="block pb1">Yay!</span>
        <span>You’re all set up.</span>
      </div>

      <div class="__subheading txt-black txt-lg py5 txt-center-align">
        You should have received an email to verify your email address. Just
        follow the link in the email.
      </div>
      <div class="__subheading txt-black txt-lg pb5 txt-center-align">
        <span class="block pb1">Sent to</span>
        <span class="block txt-sm">{{ _notDPGonlyNewEmail }}</span>
      </div>
      <div class="py3">
        <button class="btn brand-btn-secondary width-full" routerLink="/login">
          BACK TO LOGIN
        </button>
      </div>
    </div>
  </section>
</section>
<ng-template #preloader>
  <main>
    <div
      class="flex flex-align-items-center flex-justify-content-center screen-preloader"
    >
      <img
        src="../../../assets/images/common/preloader.gif"
        alt="new email send preloader"
      />
    </div>
  </main>
</ng-template>
