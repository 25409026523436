<section class="pay-bill-sms">
  <section class="_banner">
    <div class="container">
      <section class="__form">
        <div class="row">
          <div class="col-lg-6 col-md-12 relative">
            <!-- pattern start -->
            <img src="assets/images/authentication/circle-white.svg" class="pattern left" alt="" />
            <img src="assets/images/authentication/circle-green.svg" class="pattern right" alt="" />
            <section class="sign-in">
              <img src="assets/images/authentication/corner-circle-green.svg" class="pattern inner" alt="" />
              <h2 class="my5 txt-grey-darker font-semibold">
                Loading your page..
              </h2>
              <span class="___info">Please wait till your product details are loaded
              </span>
            </section>
            <!-- sign-in end -->
            <img class="illustration lg-only" src="assets/images/authentication/banner.png" alt="" />
            <img class="lg-only pattern bottom" src="assets/images/authentication/pattern.svg" alt="" />
          </div>
        </div>
      </section>
    </div>
  </section>
</section>