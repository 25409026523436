import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";

// service
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";
import { AppStoreService } from "src/app/services/appStore.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
	private _authenticationService: AuthenticationService, private router: Router,
	private appStoreService: AppStoreService) {}

  intercept(
	request: HttpRequest<any>,
	next: HttpHandler
  ): Observable<HttpEvent<any>> {
	if ( this.appStoreService.store.customer) {
	const token = this.appStoreService.store.customer.tokenPayload;
	if (!!token  && !request.url.includes("exp-publicselfservice") ) {
		const headers = request.headers.set("authorization", `Bearer ${token}`);
		const authorizedRequest = request.clone({ headers });
		return next.handle(authorizedRequest);
	} else {
		if (!this._authenticationService.isLoggedIn()) {
		this.appStoreService.flushAppStoreLocalStorage();
		}
		this.router.navigate(["/login"]);
		return next.handle(request);
	}
  } else { return next.handle(request); }
  }
}
