<div
  class="cancel block txt-primary-color txt-sm font-semibold font-brand mb5"
  *ngIf="!flgBackToDashboard; else dashboard"
>
  <a routerLink="/make-a-payment">
    <i class="fas fa-times mr1"></i><span>CANCEL</span></a
  >
</div>
<ng-template #dashboard>
  <div
    class="cancel block txt-primary-color txt-sm font-semibold font-brand mb5"
  >
    <a routerLink="/dashboard">
      <i class="fas fa-times mr1"></i><span>CANCEL</span></a
    >
  </div>
</ng-template>
