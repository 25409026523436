export enum FLASH_TYPES {
	ERROR = "error",
	SUCCESS = "success",
	WARNING = "warning",
	NOTIFY = "notify"
}

export enum MODAL_TYPES {
	ERROR = "error",
	SUCCESS = "success",
	WARNING = "warning",
	NOTIFY = "notify"
}

export enum PAGE_NOT_FOUND {
	ERROR_CODE = 404,
	ERROR_MESSAGE = "404 - Page Not Found"
}

export enum FIELD_VALIDATION {
	EVENT_TRIGGER = "error",
	TYPE = "field validation"
}

export enum FLASH_MESSAGES {
	EVENT_TRIGGER = "flash message"
}

export enum MODAL_MESSAGES {
	EVENT_TRIGGER = "modal",
	EVENT_MESSAGE = "No data provided to the dialog."
}

export enum HTTP_ERROR_PAGES {
	EVENT_TRIGGER = "error",
	TYPE = "http response"
}

export enum API_ERROR_PAGES {
	EVENT_TRIGGER = "error",
	TYPE = "system error"
}
