import { NgModule } from "@angular/core";

import { TealiumLinkDirective, TealiumContextDirective } from "./directives/tealium.directive";

const components = [
	TealiumLinkDirective,
	TealiumContextDirective
];

@NgModule({
	imports: [],
	declarations: [...components],
	exports: [...components]
})
export class TealiumModule {}
