import { Injectable } from "@angular/core";

// rxjs
import { Observable } from "rxjs";

// model
import { IProduct } from "../models/product.model";

// libraries
import { HttpService } from "../../../libraries/api/services/http.service";

import { environment } from "src/environment";
import { IBillingAccountMS } from "../models/account-details.model";

@Injectable({
	providedIn: "root"
  })
export class ProductService {

	constructor(
	private httpService: HttpService
	) { }

	getProducts (billingAccountNo): Observable<IProduct[]> {
		const url = `${
			environment.apiURL
		}/api/v1/productinventorymgmt/products?billingAccountNo=${billingAccountNo}`;
		return this.httpService.get<IProduct[]>(url);
		}

	getBillingAccounts(customerId): Observable<IBillingAccountMS[]> {
		const url = `${
			environment.apiURL
		}/api/v1/accountmgmt/accounts/${customerId}/billingAccounts`;
		return this.httpService.get<IBillingAccountMS[]>(url);
		}

	getDodoProductsByAssociationId(associationId): Observable<IProduct[]> {
		const url = `${
			environment.apiUrlDodo
		}/exp-dodoselfservice/api/v1/productinventorymgmt/product?associationId=${associationId}`;
		return this.httpService.get<IProduct[]>(url);
	}

	getDodoProducts(customerId): Observable<IProduct[]> {
		const url = `${
			environment.apiUrlDodo
		}/exp-dodoselfservice/api/v1/productinventorymgmt/product?customerId=${customerId}`;
		return this.httpService.get<IProduct[]>(url);
	}

}
