export enum HTTP_STATUS_CODE {
	OK = 200,
	CREATED = 201,
	NOT_FOUND = 404,
	UNAUTHORISED= 401,
	ACCESS_FORBIDDEN = 403,
	SERVER_ERROR = 500
}

export enum HTTP_METHOD {
	GET,
	POST,
	PATCH,
	PUT,
	DELETE
}
