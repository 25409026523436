
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { AppStoreService } from "../../services/appStore.service";
import { BootService } from "../services/boot.service";

import { environment } from "src/environment";
import { HelperService } from "../../services/helper.service";

@Injectable({
	providedIn: "root"
})
export class LoadingService {

	constructor(
		private appStoreService: AppStoreService,
		private bootService: BootService,
		private helperService: HelperService
	) { }

	fetchProducts(): any[] {
		const products$ = [];
		this.bootService.reset();
		if (!this.helperService.checkProductsTimestamp()) {
			console.log("1");
		if (this.appStoreService.store.customer.accountId) {
			console.log("2");
			if (this.helperService.updateUsageSummary()) { // mobile usageReq time is more than 1 min
				console.log("3");
				const storeProducts = this.appStoreService.store.products;
				const productsWithUsage = [];
				const allProducts = [];
				storeProducts.map(
					product => {
						if (product.bss === "shelley" && product.productGroupType === "Mobile" && product.status === "Active") {
							delete product.usage;
							productsWithUsage.push(product);
							allProducts.push(product);
						} else {
							allProducts.push(product);
						}
					}
				);
				if (productsWithUsage.length > 0) {
					products$.push(new Promise(resolve => {
						const updatedProducts = this.bootService.updateUsageForProducts(productsWithUsage, allProducts);
							resolve(updatedProducts[0] || []);
					}));
				} else {
					// to handle if shelly with mobile is not active
					products$.push(new Promise(resolve => {
						this.loadProducts()
						.subscribe(billingAccounts => {
							let products = [];
								billingAccounts
								.filter(account => !!account.services)
								.map(billingAccount =>
								products = [...products, ...billingAccount.services]
								);
							resolve(products || []);
						});
					}));
				}
			} else {
				console.log("else 3");
			products$.push(new Promise(resolve => {
				this.loadProducts()
				.subscribe(billingAccounts => {
					let products = [];
						billingAccounts
						.filter(account => !!account.services)
						.map(billingAccount =>
						products = [...products, ...billingAccount.services]
						);
					resolve(products || []);
				});
			}));
		}
	}
	console.log("not 2");
		if (environment.brand.toLowerCase() === "dodo"
			&& this.appStoreService.store.customer.assocId !== 0) {
			products$.push(new Promise(resolve => {
				this.loadDodoProducts()
				.subscribe(products => {
					resolve(products || []);
				});
			}));
		}
	} else {
		console.log("else 1");
		if (this.appStoreService.store.customer.accountId) {
			console.log("else 1 2");
			products$.push(new Promise(resolve => {
				this.loadProductsfromAPI()
				.subscribe(billingAccounts => {
					let products = [];
						billingAccounts
						.filter(account => !!account.services)
						.map(billingAccount =>
						products = [...products, ...billingAccount.services]
						);
					resolve(products || []);
				});
			}));
		}

		if (environment.brand.toLowerCase() === "dodo"
			&& this.appStoreService.store.customer.assocId !== 0) {
				console.log("else 1 not 2 1");
			products$.push(new Promise(resolve => {
				this.loadDodoProductsfromAPI()
				.subscribe(products => {
					resolve(products || []);
				});
			}));
		}
		const currentTimeStamp = Math.ceil(Date.now() / 1000);
		this.appStoreService.updateProductsReqTimestamp(currentTimeStamp);
	}
		return products$;
	}

	private loadProducts(): Observable<any> {
		if (
			this.appStoreService.store.billingAccounts &&
			this.appStoreService.store.billingAccounts.length > 0
		) {
			return of(this.appStoreService.store.billingAccounts);
		} else {
			return this.bootService.boot();
		}
	}

	private loadDodoProducts(): any {
		if (
			this.appStoreService.store.products &&
			this.appStoreService.store.products.length > 0
		) {
			return of(this.appStoreService.store.products.filter(
				product => product.bss === "billit"
			));
		} else {
			return this.bootService.bootDodo();
		}

	}

	public fetchAddonsProducts(): any[] {
		const products$ = [];
		this.bootService.reset();
		if (this.appStoreService.store.customer.accountId) {
			products$.push(new Promise(resolve => {
				this.bootService.boot()
				.subscribe(billingAccounts => {
					let products = [];
	  				billingAccounts
	  				.filter(account => !!account.services)
	  				.map(billingAccount =>
						products = [...products, ...billingAccount.services]
	  				);
					resolve(products || []);
				});
			}));
		}

		if (environment.brand.toLowerCase() === "dodo"
			&& this.appStoreService.store.customer.assocId !== 0) {
			products$.push(new Promise(resolve => {
				this.bootService.bootDodo()
				.subscribe(products => {
					resolve(products || []);
				});
			}));
		}
		return products$;
	}

	private loadProductsfromAPI(): any {
			return this.bootService.boot();
		}

	private loadDodoProductsfromAPI(): any {
			return this.bootService.bootDodo();
	}
}
