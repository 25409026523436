import { SimkitDialogComponent } from "./simkit-dialog/simkit-dialog.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// module
import { CommomComponentModule } from "src/app/common/commom-component.module";
import { GoogleChartsModule } from "angular-google-charts";

// Dashboard
import { DashboardAdvertisementComponent } from "./dashboard/advertisement/advertisement.component";
import { DashboardBannerComponent } from "./dashboard/banner/banner.component";
import { QuickLinkComponent } from "./dashboard/quick-link/quick-link.component";
import { ProductCardComponent } from "./dashboard/product/product.component";
import { FaqComponent } from "./dashboard/faq/faq.component";
// Product
import { ProductBannerComponent } from "./product/banner/product-banner.component";
import { ProductPlanComponent } from "./product/plan/product-plan.component";
import { ProductNavigationComponent } from "./product/navigation/navigation.component";
import { ProductPrepaidComponent } from "./product/prepaid/prepaid.component";
import { ProductHeaderComponent } from "./product/header/product-header.component";
// Bill
import { BillTabComponent } from "./bill/tabs/bill-tab.component";
import { BillLatestInvoiceComponent } from "./bill/bill-latest-invoice/bill-latest-invoice.component";
import { BillPreviousInvoiceComponent } from "./bill/bill-previous-invoice/bill-previous-invoice.component";
import { BillHeaderComponent } from "./bill/header/bill-header.component";
import { PreviousDebitnoteComponent } from "./bill/previous-debitnote/previous-debitnote.component";
// Payment
import { PaymentOverviewTileComponent } from "./payment/payment-overview-tile/payment-overview-tile.component";
import { HeaderComponent } from "./payment/header/header.component";
import { PaymentSummaryVerificationComponent } from "./payment/payment-summary-verification/payment-summary-verification.component";
import { PaymentAmountEntryComponent } from "./payment/payment-amount-entry/payment-amount-entry.component";
import { PaymentTypeCardComponent } from "./payment/payment-type-card/payment-type-card.component";
import { PaymentSuccessfulMessageComponent } from "./payment/payment-successful-message/payment-successful-message.component";
import { CancelPaymentComponent } from "./payment/cancel-payment/cancel-payment.component";
import { AddPaymentTypeIpsiComponent } from "./payment/add-payment-type-ipsi/add-payment-type-ipsi.component";
import { CancelPaymentPlanComponent } from "./payment/cancel-payment-plan/cancel-payment-plan.component";

// Usage
import { UsageBannerComponent } from "./usage/usage-banner/usage-banner.component";
import { UsageTabsComponent } from "./usage/usage-tabs/usage-tabs.component";
import { UsageYearlyComponent } from "./usage/usage-yearly/usage-yearly.component";
import { UsageMonthlyComponent } from "./usage/usage-monthly/usage-monthly.component";
import { UsageDailyComponent } from "./usage/usage-daily/usage-daily.component";
import { UsageProgressbarComponent } from "./usage/usage-progressbar/usage-progressbar.component";
import { UsageBreakdownComponent } from "./usage/usage-breakdown/usage-breakdown.component";
import { UsageTelcoComponent } from "./usage/usage-telco/usage-telco.component";
import { UsageYearlyBreakdownComponent } from "./usage/usage-yearly-breakdown/usage-yearly-breakdown.component";

// Usage details
import { BannerComponent } from "./usage-details/banner/banner.component";
import { BannerMobileComponent } from "./usage-details/banner/banner-mobile.component";
import { DodoBreakdownComponent } from "./usage-details/breakdown/dodo-breakdown.component";
import { BreakdownComponent } from "./usage-details/breakdown/breakdown.component";

// Payment details
import { InstalmentComponent } from "./wallet/instalment/instalment.component";
import { PaymentSetupComponent } from "./wallet/payment-setup/payment-setup.component";

import { PrimaryPaymentTypeComponent } from "./wallet/primary-payment-type/primary-payment-type.component";
import { OtherPaymentTypeComponent } from "./wallet/other-payment-type/other-payment-type.component";
import { AddPaymentTypeComponent } from "./wallet/add-payment-type/add-payment-type.component";
import { BankAccountComponent } from "./wallet/bank-account/bank-account.component";
import { ModalAddPaymentMethodComponent } from "./wallet/modal-add-payment-method/modal-add-payment-method.component";

import { DodoPrimaryPaymentTypeComponent } from "./dodo-wallet/primary-payment-type/primary-payment-type.component";
import { DodoOtherPaymentTypeComponent } from "./dodo-wallet/other-payment-type/other-payment-type.component";
import { DodoAddPaymentTypeComponent } from "./dodo-wallet/add-payment-type/add-payment-type.component";
import { DodoBankAccountComponent } from "./dodo-wallet/bank-account/bank-account.component";
import { DodoInstalmentComponent } from "./dodo-wallet/instalment/instalment.component";
import { DodoPaymentSetupComponent } from "./dodo-wallet/payment-setup/payment-setup.component";
import { DodoModalAddPaymentMethodComponent } from "./dodo-wallet/modal-add-payment-method/modal-add-payment-method.component";

// DateTimePicker
import { DlDateTimePickerModule } from "@philipab/angular-bootstrap-datetimepicker";

// Tealium
import { TealiumModule } from "../../../tealium/tealium.module";

// Material components
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";

// Custom pipe
import { DodoMinutesToHoursPipe } from "./usage-details/breakdown/dodo-breakdown.component";
import { MinutesToHoursPipe } from "./usage-details/breakdown/breakdown.component";

import { PlanGetStartedComponent } from "./payment-plan/plan-get-started/plan-get-started.component";
import { InProgressPlansComponent } from "./payment-plan/in-progress-plans/in-progress-plans.component";
import { PaymentPlanBannerComponent } from "./payment-plan/payment-plan-banner/payment-plan-banner.component";
import { PaymentPlanTabsComponent } from "./payment-plan/payment-plan-tabs/payment-plan-tabs.component";

import { InstalmentProgressBarsComponent } from "./instalment-plan/instalment-progress-bars/instalment-progress-bars.component";
import { InstalmentCancelComponent } from "./instalment-plan/instalment-cancel/instalment-cancel.component";
import { EligibilityModalComponent } from "./instalment-plan/eligibility-modal/eligibility-modal.component";
import { PaymentPlanProgressBarsComponent } from "./payment-plan/payment-plan-progress-bars/payment-plan-progress-bars.component";
import { ServiceTileComponent } from "./payment-plan/service-tile/service-tile.component";
import { PaymentPlanCancelComponent } from "./payment-plan/payment-plan-cancel/payment-plan-cancel.component";
import { PaymentPlanSummaryComponent } from "./payment-plan/payment-plan-summary/payment-plan-summary.component";
import { InProgressSummaryModalComponent } from "./payment-plan/in-progress-summary-modal/in-progress-summary-modal.component";
import { CreditProgressBarsComponent } from "./credit-refund/credit-progress-bars/credit-progress-bars.component";
import { CreditCancelComponent } from "./credit-refund/credit-cancel/credit-cancel.component";

import { ActivateSimComponent } from "./activate-sim/activate-sim.component";
// Account Personal Details
import { PersonalInfoBannerComponent } from "./account-details/personal-info-banner/personal-info-banner.component";
import { PersonalCustomerIdComponent } from "./account-details/personal-customer-id/personal-customer-id.component";

// mail-box
import { MailboxInfoBannerComponent } from "./mail-box/mailbox-info-banner/mailbox-info-banner.component";
import { MailboxSettingsComponent } from "./mail-box/mailbox-settings/mailbox-settings.component";
import { MailboxAddmailComponent } from "./mail-box/mailbox-addmail/mailbox-addmail.component";
import { MailboxListBannerComponent } from "./mail-box/mailbox-list-banner/mailbox-list-banner.component";
// Usage Alert
import { AlertCardsComponent } from "./usage-alert/alert-cards/alert-cards.component";

// add-ons
import { AddonsBannerComponent } from "./addons/addons-banner/addons-banner.component";
import { AddonsBreakdownComponent } from "./addons/addons-breakdown/addons-breakdown.component";
import { DodoAddonsBreakdownComponent } from "./addons/addons-breakdown/dodo-addons-breakdown.component";
import { AddonsPaymentBannerComponent } from "./addons/addons-payment-banner/addons-payment-banner.component";
import { DodoPreviousInvoiceComponent } from "./bill/bill-previous-invoice/dodo-previous-invoice.component";
import { DodoPaymentOverviewTileComponent } from "./payment/payment-overview-tile/dodo-payment-overview-tile.component";
import { PersonalAddressComponent } from "./account-details/personal-address/personal-address.component";
import { PersonalContactComponent } from "./account-details/personal-contact/personal-contact.component";
import { DodoPersonalCustomerIdComponent } from "./account-details/personal-customer-id/dodo-personal-customer-id.component";

import { InProgressPaymentPlansComponent } from "./payment-plan/in-progress-payment-plans/in-progress-payment-plans.component";
import { InProgressSummaryComponent } from "./payment-plan/in-progress-summary/in-progress-summary.component";
import { DodoPaymentAmountEntryComponent } from "./payment/payment-amount-entry/dodo-payment-amount-entry.component";
import { DodoPaymentSummaryVerificationComponent } from "./payment/payment-summary-verification/dodo-payment-summary-verification.component";
import { DodoPaymentTypeCardComponent } from "./payment/payment-type-card/dodo-payment-type-card.component";
import { DodoAddPaymentTypeIpsiComponent } from "./payment/add-payment-type-ipsi/dodo-payment-type-ipsi.component";
import { DodoPaymentSuccessfulMessageComponent } from "./payment/payment-successful-message/dodo-payment-successful-message.component";
import { DodoServiceTileComponent } from "./payment-plan/service-tile/dodo-service-tile.component";
import { UpcomingChangesComponent } from "./dashboard/upcoming-changes/upcoming-changes.component";
import { FaqMigrationComponent } from "./dashboard/faq-migration/faq-migration.component";
import { BannerLandingComponent } from "./dashboard/banner-landing/banner-landing.component";
import { ProductBannerMobileComponent } from "./product/banner/product-banner-mobile.component";

import { ChangePlanBannerComponent } from "./change-plan/change-plan-banner/change-plan-banner.component";
import { ChangePlanInfoComponent } from "./change-plan/change-plan-info/change-plan-info.component";
import { InternationalRatesDialogComponent } from "./change-plan/international-rates-dialog/international-rates-dialog.component";
import { ReplaceSimComponent } from "./replace-sim/replace-sim.component";
import { MaterialFormsModule } from "src/app/common/material-form/material-forms.module";
import { DodoButtonsModule } from "src/app/common/buttons/buttons.module";
import { SanitizeHtmlPipe } from "src/app/common/pipe/sanitize.pipe";
import { NbnChangePlanInfoComponent } from "./nbn-change-plan/nbn-change-plan-info/nbn-change-plan-info.component";
import { NbnChangePlanBannerComponent } from "./nbn-change-plan/nbn-change-plan-banner/nbn-change-plan-banner.component";

import { ManualAddressModule } from "src/app/common/manual-address/manual-address.module";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ActivateRepSimComponent } from "./activate-rep-sim/activate-rep-sim.component";
import { MigrationContentComponent } from "./dashboard/migration-content/migration-content.component";
import { UsageGraphYearlyComponent } from "./usage/usage-graph-yearly/usage-graph-yearly.component";
import { UsageGraphMonthlyComponent } from "./usage/usage-graph-monthly/usage-graph-monthly.component";
import { UsageGraphDailyComponent } from "./usage/usage-graph-daily/usage-graph-daily.component";
import { ConcessionTermsDialogComponent } from "./account-details/personal-customer-id/concession-terms-dialog/concession-terms-dialog.component";

import { DodoDirectivesModule } from "./../../common/directives/directives.module";
import { NullDateCheckPipe } from "./account-details/personal-customer-id/pipe/null-date-check.pipe";
import { MaskLastFourDigitsPipe } from "src/app/common/pipe/mask-last-four-digits.pipe";

import { VoipChangePlanInfoComponent } from "./voip-change-plan/voip-change-plan-info/voip-change-plan-info.component";
import { VoipChangePlanBannerComponent } from "./voip-change-plan/voip-change-plan-banner/voip-change-plan-banner.component";
import {
  VoipBreakdownComponent,
  VoipMinutesToHoursPipe
} from "./usage-details/breakdown/voip-breakdown.component";
import { BannerVoipComponent } from "./usage-details/banner/banner-voip.component";
import { CustomDatePipe } from "src/app/common/pipe/custom-date.pipe";
import { ProductPlanMobileComponent } from "./product/plan/product-plan-mobile.component";
import { ProductBannerInternetComponent } from "./product/banner/product-banner-internet.component";
import { ProductPlanInternetComponent } from "./product/plan/product-plan-internet.component";
import { ServiceDisclaimerComponent } from "./dashboard/disclaimer/service-disclaimer.component";
import { ProductStatusBadgeComponent } from "./product-status-badge/product-status-badge.component";
import { ActivateRoamingDialogComponent } from "./product/banner/activate-roaming-dialog/activate-roaming-dialog.component";
import { NotActivatedRoamingDialogComponent } from "./product/banner/not-activated-roaming-dialog/not-activated-roaming-dialog.component";
import { RoamingApiErrorDialogComponent } from "./product/banner/roaming-api-error-dialog/roaming-api-error-dialog.component";
import { CannotActivateRoamingDialogComponent } from "./product/banner/cannot-activate-roaming-dialog/cannot-activate-roaming-dialog.component";
import { CountryListDialogComponent } from "./product/banner/country-list-dialog/country-list-dialog.component";
import { DayOfMonthPipe } from "src/app/common/pipe/day-of-month.pipe";
import { CurrencyFormatPipe } from "src/app/common/pipe/currency-format.pipe";
import { PercentageFormatPipe } from "src/app/common/pipe/percentage-format.pipe";

@NgModule({
	imports: [
		CommonModule,
		CommomComponentModule,
		RouterModule,
		GoogleChartsModule,
		FormsModule,
		ReactiveFormsModule,
		DlDateTimePickerModule,
		TealiumModule,
		MatInputModule,
		MatButtonModule,
		MatDialogModule,
		MaterialFormsModule,
		DodoButtonsModule,
		ManualAddressModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		DodoDirectivesModule
	],
	declarations: [
		ReplaceSimComponent,
		SimkitDialogComponent,
		DodoPersonalCustomerIdComponent,
		DodoPreviousInvoiceComponent,
		DodoPaymentOverviewTileComponent,
		DodoPrimaryPaymentTypeComponent,
		DodoOtherPaymentTypeComponent,
		DodoAddPaymentTypeComponent,
		DodoBankAccountComponent,
		DodoInstalmentComponent,
		DodoPaymentSetupComponent,
		DodoModalAddPaymentMethodComponent,
		DodoPaymentAmountEntryComponent,
		DodoPaymentSummaryVerificationComponent,
		DodoPaymentTypeCardComponent,
		DodoAddPaymentTypeIpsiComponent,
		DodoPaymentSuccessfulMessageComponent,
		DodoServiceTileComponent,
		DashboardAdvertisementComponent,
		DashboardBannerComponent,
		QuickLinkComponent,
		ProductCardComponent,
		FaqComponent,
		ProductBannerComponent,
		ProductPlanComponent,
		ProductNavigationComponent,
		ProductPrepaidComponent,
		ProductHeaderComponent,
		BillTabComponent,
		BillLatestInvoiceComponent,
		BillPreviousInvoiceComponent,
		BillHeaderComponent,
		UsageBannerComponent,
		UsageTabsComponent,
		UsageYearlyComponent,
		UsageMonthlyComponent,
		UsageDailyComponent,
		UsageProgressbarComponent,
		UsageBreakdownComponent,
		UsageTelcoComponent,
		UsageYearlyBreakdownComponent,
		PaymentOverviewTileComponent,
		HeaderComponent,
		PaymentSummaryVerificationComponent,
		PaymentAmountEntryComponent,
		PaymentTypeCardComponent,
		PaymentSuccessfulMessageComponent,
		CancelPaymentComponent,
		AddPaymentTypeIpsiComponent,
		PaymentPlanTabsComponent,
		PrimaryPaymentTypeComponent,
		OtherPaymentTypeComponent,
		AddPaymentTypeComponent,
		BankAccountComponent,
		InstalmentComponent,
		PaymentSetupComponent,
		ModalAddPaymentMethodComponent,
		BannerComponent,
		BreakdownComponent,
		MinutesToHoursPipe,
		PaymentPlanBannerComponent,
		PersonalInfoBannerComponent,
		MailboxInfoBannerComponent,
		InProgressPlansComponent,
		PlanGetStartedComponent,
		InstalmentProgressBarsComponent,
		InstalmentCancelComponent,
		EligibilityModalComponent,
		PaymentPlanProgressBarsComponent,
		ServiceTileComponent,
		PaymentPlanCancelComponent,
		PaymentPlanSummaryComponent,
		InProgressSummaryModalComponent,
		PersonalCustomerIdComponent,
		PersonalContactComponent,
		PersonalAddressComponent,
		CreditProgressBarsComponent,
		CreditCancelComponent,
		MailboxSettingsComponent,
		MailboxAddmailComponent,
		MailboxListBannerComponent,
		ActivateSimComponent,
		AlertCardsComponent,
		AddonsBannerComponent,
		AddonsBreakdownComponent,
		InProgressPaymentPlansComponent,
		InProgressSummaryComponent,
		CancelPaymentPlanComponent,
		PreviousDebitnoteComponent,
		UpcomingChangesComponent,
		FaqMigrationComponent,
		BannerLandingComponent,
		ProductBannerMobileComponent,
		DodoAddonsBreakdownComponent,
		AddonsPaymentBannerComponent,
		ChangePlanBannerComponent,
		ChangePlanInfoComponent,
		InternationalRatesDialogComponent,
		SanitizeHtmlPipe,
		NbnChangePlanInfoComponent,
		NbnChangePlanBannerComponent,
		ActivateRepSimComponent,
		DodoBreakdownComponent,
		DodoMinutesToHoursPipe,
		BannerMobileComponent,
		MigrationContentComponent,
		UsageGraphYearlyComponent,
		UsageGraphMonthlyComponent,
		UsageGraphDailyComponent,
		ConcessionTermsDialogComponent,
		NullDateCheckPipe,
		MaskLastFourDigitsPipe,
		VoipChangePlanInfoComponent,
		VoipChangePlanBannerComponent,
		VoipBreakdownComponent,
		VoipMinutesToHoursPipe,
		BannerVoipComponent,
		ProductStatusBadgeComponent,
		CustomDatePipe,
		ProductPlanMobileComponent,
		ProductBannerInternetComponent,
		ProductPlanInternetComponent,
		ServiceDisclaimerComponent,
		DayOfMonthPipe,
		CurrencyFormatPipe,
		PercentageFormatPipe,
		CountryListDialogComponent,
		ActivateRoamingDialogComponent,
		NotActivatedRoamingDialogComponent,
		CannotActivateRoamingDialogComponent,
		RoamingApiErrorDialogComponent
	],
	exports: [
		DodoPersonalCustomerIdComponent,
		DodoPreviousInvoiceComponent,
		DodoPaymentOverviewTileComponent,
		DodoPrimaryPaymentTypeComponent,
		DodoOtherPaymentTypeComponent,
		DodoAddPaymentTypeComponent,
		DodoBankAccountComponent,
		DodoInstalmentComponent,
		DodoPaymentSetupComponent,
		DodoModalAddPaymentMethodComponent,
		DodoPaymentAmountEntryComponent,
		DodoPaymentSummaryVerificationComponent,
		DodoPaymentTypeCardComponent,
		DodoAddPaymentTypeIpsiComponent,
		DodoPaymentSuccessfulMessageComponent,
		DodoServiceTileComponent,
		DashboardAdvertisementComponent,
		DashboardBannerComponent,
		QuickLinkComponent,
		ProductCardComponent,
		FaqComponent,
		ProductBannerComponent,
		ProductPlanComponent,
		ProductNavigationComponent,
		ProductPrepaidComponent,
		ProductHeaderComponent,
		BillTabComponent,
		BillLatestInvoiceComponent,
		BillPreviousInvoiceComponent,
		BillHeaderComponent,
		UsageBannerComponent,
		UsageTabsComponent,
		UsageProgressbarComponent,
		UsageBreakdownComponent,
		UsageTelcoComponent,
		UsageYearlyComponent,
		UsageMonthlyComponent,
		UsageDailyComponent,
		UsageYearlyBreakdownComponent,
		PaymentOverviewTileComponent,
		HeaderComponent,
		PaymentSummaryVerificationComponent,
		PaymentAmountEntryComponent,
		PaymentTypeCardComponent,
		PaymentSuccessfulMessageComponent,
		CancelPaymentComponent,
		AddPaymentTypeIpsiComponent,
		PrimaryPaymentTypeComponent,
		OtherPaymentTypeComponent,
		AddPaymentTypeComponent,
		BankAccountComponent,
		InstalmentComponent,
		PaymentSetupComponent,
		ModalAddPaymentMethodComponent,
		BannerComponent,
		BreakdownComponent,
		MinutesToHoursPipe,
		PaymentPlanBannerComponent,
		PersonalInfoBannerComponent,
		MailboxInfoBannerComponent,
		MailboxSettingsComponent,
		MailboxAddmailComponent,
		MailboxListBannerComponent,
		PaymentPlanTabsComponent,
		InProgressPlansComponent,
		PlanGetStartedComponent,
		InstalmentProgressBarsComponent,
		InstalmentCancelComponent,
		EligibilityModalComponent,
		PaymentPlanProgressBarsComponent,
		ServiceTileComponent,
		PaymentPlanCancelComponent,
		PaymentPlanSummaryComponent,
		InProgressSummaryModalComponent,
		PersonalCustomerIdComponent,
		PersonalContactComponent,
		PersonalAddressComponent,
		CreditProgressBarsComponent,
		CreditCancelComponent,
		ActivateSimComponent,
		AlertCardsComponent,
		AddonsBannerComponent,
		AddonsBreakdownComponent,
		InProgressPaymentPlansComponent,
		InProgressSummaryComponent,
		CancelPaymentPlanComponent,
		PreviousDebitnoteComponent,
		FaqMigrationComponent,
		BannerLandingComponent,
		ProductBannerMobileComponent,
		DodoAddonsBreakdownComponent,
		AddonsPaymentBannerComponent,
		ChangePlanBannerComponent,
		ChangePlanInfoComponent,
		ReplaceSimComponent,
		InternationalRatesDialogComponent,
		SanitizeHtmlPipe,
		NbnChangePlanInfoComponent,
		NbnChangePlanBannerComponent,
		DodoBreakdownComponent,
		DodoMinutesToHoursPipe,
		BannerMobileComponent,
		UsageGraphYearlyComponent,
		UsageGraphMonthlyComponent,
		UsageGraphDailyComponent,
		MaskLastFourDigitsPipe,
		VoipChangePlanInfoComponent,
		VoipChangePlanBannerComponent,
		VoipBreakdownComponent,
		VoipMinutesToHoursPipe,
		BannerVoipComponent,
		ProductStatusBadgeComponent,
		CustomDatePipe,
		ProductPlanMobileComponent,
		ProductBannerInternetComponent,
		ProductPlanInternetComponent,
		ServiceDisclaimerComponent,
		DayOfMonthPipe,
		CurrencyFormatPipe,
		PercentageFormatPipe,
		CountryListDialogComponent,
		ActivateRoamingDialogComponent,
		NotActivatedRoamingDialogComponent,
		CannotActivateRoamingDialogComponent,
		RoamingApiErrorDialogComponent
	]
})
export class SelfServeComponentsModule { }
