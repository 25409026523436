<section class="bg-color-primary height-full">
  <section class="mfa-error bg-color-primary">
    <div class="_mfa-error-container bg-white">
      <div class="mydodo-custom-header">
        <div class="auth0-lock-header-welcome">
          <img
            alt="MyDodo"
            class="auth0-lock-header-logo"
            src="assets/images/brand-primary.svg"
          />
        </div>
        <h2>Sorry, we’re experiencing some technical difficulties.</h2>
        <p class="mfa-msg"></p>
        <p class="mfa-msg mt1">
          Please try again later. If you continue to have trouble accessing your
          account, request support by clicking the link below.
        </p>
      </div>
      <form #mfaerror="ngForm">
        <div class="mt2 mb4 pl5 pr5">
          <button
            type="button"
            class="btn brand-btn-secondary width-full relative"
            (click)="backHomePage()"
          >
            <span>BACK TO DODO.COM</span>
          </button>
        </div>
      </form>
      <div class="mydodo-custom-footer">
        <a href="{{ goMFAsupportForm() }}">Need help logging in?</a>
      </div>
    </div>
  </section>
</section>
