import { Component, OnInit } from "@angular/core";
import { AppStoreService } from "src/app/services/appStore.service";
@Component({
  selector: "app-cancel-payment",
  templateUrl: "./cancel-payment.component.html",
  styleUrls: ["./cancel-payment.component.scss"]
})

export class CancelPaymentComponent implements OnInit {
  public flgBackToDashboard = false;
  constructor(private appStoreService: AppStoreService) {
    if (this.appStoreService.store.productPaymentPath.paymentPathSource !== undefined) {
      this.flgBackToDashboard = true;
    }
  }
  ngOnInit() {
  }
}
