import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// services
import { AuthenticationService } from "./services/authentication.service";

// custom store
import { AppStoreService } from "src/app/services/appStore.service";

@Injectable({
  providedIn: "root"
})
export class StateGuard  {
  constructor(
	private authenticationService: AuthenticationService,
	private router: Router,
	private appStoreService: AppStoreService
  ) {}

  canActivate(): boolean {
		if (!this.appStoreService.store) { this.authenticationService.LoggedOut();
		this.router.navigate([`/login`]);
		return false;
	} else { return true; }
  }

}
