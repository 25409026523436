import { Injectable } from "@angular/core";
import { AppStoreService } from "./appStore.service";
import { IBillingAccount, IProductPrices } from "../self-serve/models/product.model";
import { IAddress } from "../self-serve/models/account-details.model";
import { environment } from "src/environment";
import * as moment from "moment";
import { IProduct } from "../self-serve/models/product.model";
import { Router } from "@angular/router";
import { IServiceCharacteristic } from "../self-serve/models/product.model";
import { IProductDiscountPrices } from "../self-serve/models/product.model";
import { signal } from "@angular/core";
import { DISCOUNT_TYPES } from "../self-serve/components/product/plan/plan-discounts.enum";
@Injectable({
	providedIn: "root"
})

export class HelperService {
	private extendedDate: string;
	constructor(private appStoreService: AppStoreService, private router: Router) { }

	public getBillingAccountById(billingAccountId: string): IBillingAccount {
		const billingAccounts = this.appStoreService.store.billingAccounts;
		return billingAccounts?.find((billingAccount) =>
			billingAccount.id === billingAccountId
		);
	}

	public getBillingAccountByBan(ban: string): IBillingAccount {
		const billingAccounts = this.appStoreService.store.billingAccounts;
		return billingAccounts?.find((billingAccount) =>
			billingAccount.accountNo === ban
		);
	}

	public getCompositeAddress(address: IAddress): string {
		let composite = address.country;
		composite = address.postCode + " " + composite;
		composite = address.state + " " + composite;
		composite = address.city + " " + composite;
		composite = address.street2 ? (address.street2 + " " + composite) : composite;
		composite = address.street1 + " " + composite;
		return composite;
	}

	public checkProductsTimestamp(): boolean {
		const currentTimeStamp = Math.ceil(Date.now() / 1000);
		return (this.appStoreService.store.productsTimestamp && (this.appStoreService.store.productsTimestamp + environment.productsTimeout) < currentTimeStamp);
	}

	public updateUsageSummary(): boolean {
		const currentTimeStamp = new Date().getTime();
		return (this.appStoreService.store.usageRequestTimestamp && (this.appStoreService.store.usageRequestTimestamp + environment.usageTimeout) < currentTimeStamp);
	}

	public getContractDuration(endDate: string): string | boolean {
		const endMoment = moment(endDate);
		const duration = moment.duration(endMoment.diff(moment()));

		const years = duration.years();
		const months = duration.months();
		const days = duration.days();

		let period = '';

		if (years > 0) {
			period += `${years} year${years > 1 ? 's' : ''} `;
		}
		if (months > 0) {
			period += `${months} month${months > 1 ? 's' : ''} `;
		}
		if (days > 0) {
			period += `${days} day${days > 1 ? 's' : ''} `;
		}

		return period.trim() || false;
	}

	public convertBytes(x: string): string {
		const value = parseFloat(x);
		return !x.includes('.0') ? `${Math.round(value * 1024)} MB` : `${Math.round(value * 1048576)} KB`;
	}

	public getWebsiteHostServerURL(): string {
		return `${environment.websiteHostServer}`;
	}

	public findProduct(products: IProduct[], productId): IProduct {
		const selectedProduct = products?.find(
			(product) => product?.id === productId
		);
		return selectedProduct || undefined;
	}

	public pageRedirect(): void {
		this.router.navigate(["/page-not-found"]);
	}

	public scrollTop(): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}

	public findBillingCycleStartDay(productPrices): string | undefined {
		const productPrice = productPrices.find(productPrice =>
			productPrice.priceType === "Recurring" &&
			productPrice.name !== "Static IP Address Charge" &&
			productPrice.name !== "VOIP International plan charge" &&
			productPrice.name !== "VOIP Everyday plan charge"
		);
		return productPrice?.billCycleDay;
	}

	public isAmountOverdue(billingAccount: IBillingAccount): boolean {
		return billingAccount?.paymentStatus === 'in collections';
	}

	public isPaymentExtended(billingAccount: IBillingAccount): boolean {
		const characteristic = this.getPaymentExtensionCharacteristic(billingAccount);
		if (characteristic) {
			this.extendedDate = characteristic.value;
			return this.formatExtendedDate();
		}
		return false;
	}

	private getPaymentExtensionCharacteristic(billingAccount: IBillingAccount): IServiceCharacteristic | undefined {
		return billingAccount?.services?.[0]?.characteristic?.find(characteristic => characteristic.name === 'Payment Extension Date');
	}

	private formatExtendedDate(): boolean {
		if (this.extendedDate) {
			this.extendedDate = moment(this.extendedDate).format("DD/MM/YYYY");
			return true;
		}
		return false;
	}

	public getExtendedPaymentDate(): string {
		return this.extendedDate;
	}

	public hasDiscountPrice(productPrices): boolean {
		return productPrices.some(item => item.priceType === "Discount");
	}
	public findDiscounts(productPrices: IProductPrices[], discountType: string): { taxIncludedAmount: number; effectiveEndDate: string | null }[] | false {
		const uniqueProducts = signal(
			Array.from(new Set(productPrices.map(item => JSON.stringify(item))))
				.map(item => JSON.parse(item) as IProductDiscountPrices)
		);
		const discounts = signal(
			uniqueProducts().filter(item => item.priceType === "Discount" && item.name.includes(discountType))
				.map(item => ({
					taxIncludedAmount: item.price.taxIncludedAmount,
					effectiveEndDate: item.price.effectiveEndDate
						? `(end date: ${moment(item.price.effectiveEndDate).format("DD/MM/YYYY")})`
						: null,
					discountDollarPercentLabel: discountType === DISCOUNT_TYPES.DOLLAR ? DISCOUNT_TYPES.DISCOUNT_LABEL_1 : DISCOUNT_TYPES.DISCOUNT_PERCENTAGE_LABEL_1,
					discountPercentageLabel: discountType === DISCOUNT_TYPES.DOLLAR ? null : DISCOUNT_TYPES.DISCOUNT_PERCENTAGE_LABEL_2,
				}))
		);
		return discounts().length > 0 ? discounts() : false;
	}

}


