export class Customerlogin {
	constructor(
		public contactservicecustomerid: string,
		public customerdob: any,
		public sourceurl: string
	) {}
}
export interface IPasswordSMSstatus {
	status: string;
	message: string;
}
