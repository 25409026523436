import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";

import { MatInputModule } from "@angular/material/input";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ManualAddressComponent } from "./manual-address.component";
import { MaterialFormsModule } from "../material-form/material-forms.module";
import { MaterialModule } from "../material-form/material.module";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatProgressSpinnerModule,
		MatInputModule,
		MaterialModule,
		MaterialFormsModule
	],
	declarations: [ManualAddressComponent],
	exports: [ManualAddressComponent]
})
export class ManualAddressModule {}
