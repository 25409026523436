import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { APIConfiguration } from "./models/api.models";
import { HttpService } from "./services/http.service";

import { Interceptors } from "./interceptors/main.interceptor";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { HttpApiErrorInterceptor } from "./interceptors/htttp-api-error-handling.interceptor";

@NgModule({ declarations: [], imports: [CommonModule], providers: [Interceptors, HttpService, provideHttpClient(withInterceptorsFromDi())] })
export class ApiModule {
  static forRoot(apiConfig: APIConfiguration) {
	return {
		ngModule: ApiModule,
		providers: [
		{
			provide: APIConfiguration,
			useValue: apiConfig
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpApiErrorInterceptor,
			multi: true
		}
		]
	};
	}
}
