import { HttpErrorResponse } from "@angular/common/http";

export interface JsonServiceError {
  jsonFilePath: string;
  error: HttpErrorResponse;
}

export class APIConfiguration {
  timeout?: number;
  retryInterval?: number;
  retryCount?: number;
  configBasePath?: string;
}
