import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { TealiumService } from "../tealium/services/tealium.service";
import { HeaderTagService } from "./services/headerTag.service";
import { environment } from "src/environment";
import { FeatureFlagService } from "./services/feature-flag.service";
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
	title = "My Self Service";

	constructor(
		private router: Router,
		tealiumService: TealiumService,
		headerTagService: HeaderTagService,
		private readonly featureFlagService: FeatureFlagService) {
		if (environment.envName === "prod") {
			this.disableConsoleLog();
		}
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(({ urlAfterRedirects }: NavigationEnd) => {
				headerTagService.setTag(urlAfterRedirects);
				tealiumService.trackPageView(urlAfterRedirects);
			});
	}
	ngOnInit(): void {
		this.featureFlagService.start();
	}
	private disableConsoleLog(): void {
		console.log = function () { };
		// console.warn = function() {};
		// console.error = function() {};
	}
}
