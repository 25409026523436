<section class="view-my-bill">
  <section class="_banner">
    <div class="container">
      <div class="pdf-container" *ngIf="printurlflg; else preloader">
        <button
          *ngIf="btnDownloadRequired"
          class="btn brand-btn-info relative mb2 mt2"
          [class.brand-btn-secondary]="printurlflg"
          (click)="downloadpdfInvoice()"
        >
          <span *ngIf="!btnPreloader">Download Invoice</span>
          <span *ngIf="btnPreloader" class="btn-preloader"></span>
        </button>

        <div>
          <pdf-viewer [src]="pdfSrc"
            [original-size]="false"
            style="width: 100%; height: 100vh;"
          ></pdf-viewer>
        </div>
      </div>
      <ng-template #preloader>
        <div
          class="flex flex-align-items-center flex-justify-content-center screen-preloader"
        >
          <img
            src="../../../../assets/images/common/preloader.gif"
            alt="preloader"
          />
        </div>
      </ng-template>
      <section class="__form" style="position: relative; z-index: 100;">
        <div class="row" *ngIf="!printurlflg">
          <div class="col-lg-6 col-md-12 relative">
            <!-- pattern start -->
            <img
              src="assets/images/authentication/circle-white.svg"
              class="pattern left"
              alt=""
            />
            <img
              src="assets/images/authentication/circle-green.svg"
              class="pattern right"
              alt=""
            />
            <section class="sign-in">
              <img
                src="assets/images/authentication/corner-circle-green.svg"
                class="pattern inner"
                alt=""
              />

              <h2 class="my5 txt-grey-darker font-semibold">
                Loading your Invoice..
              </h2>
              <!-- <span class="txt-color-error font-brand txt-sm block pb2"
                ><i class="mr1 fal fa-exclamation-circle"></i> Always keep
                pop-ups enabled in your browser settings to view your
                invoice</span
              > -->
              <span class="___info"
                >Please wait till your Invoice Details are loaded
              </span>
            </section>
            <!-- sign-in end -->
            <img
              class="illustration lg-only"
              src="assets/images/authentication/banner.png"
              alt=""
            />
            <img
              class="lg-only pattern bottom"
              src="assets/images/authentication/pattern.svg"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  </section>
</section>
