import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoggingInterceptor } from "./log.interceptor";
import { TimeOutInterceptor } from "./timeout.interceptor";
import { PacketInterceptor } from "./packet.interceptor";
import { TokenInterceptor } from "./token.interceptor";
import { ErrorHandlingInterceptor } from "./error-handling.interceptor";
import { ErrorAuth0mfaInterceptor } from "./error-auth0mfa.interceptor";

export const Interceptors = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: LoggingInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ErrorHandlingInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: TimeOutInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: PacketInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: TokenInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: ErrorAuth0mfaInterceptor,
		multi: true
	}
];
