import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// model
import {
  IPaymentPlanEligibility,
  IPaymentPlanPostObject,
  IPaymentPlanInProgressSummary
} from "../models/payment-plan.model";
import { IInstallmentPlan } from "../models/invoice.model";

// libraries
import { HttpService } from "../../../libraries/api/services/http.service";

import { environment } from "src/environment";

@Injectable({
  providedIn: "root"
})
export class PaymentPlanService {

  constructor(
	private httpClient: HttpClient,
	private httpService: HttpService
  ) {}

  getEligibility(accountId, billingAccountNo, planType): Observable<IPaymentPlanEligibility> {
	const _url =
	`${environment.apiURL}/api/v1/accountmgmt/accounts/${accountId}/billingAccounts/${billingAccountNo}/paymentPlan/eligibility?planType=${planType}`;
	return this.httpClient.get<IPaymentPlanEligibility>(_url);
  }

  applyPaymentExtension(accountId, billingAccountNo, data): Observable<IPaymentPlanPostObject> {
	const _url =
	`${environment.apiURL}/api/v1/accountmgmt/accounts/${accountId}/billingAccounts/${billingAccountNo}/paymentPlan`;
	return this.httpClient.post<IPaymentPlanPostObject>(_url, data);
  }
/*
  getPaymentPlanSummary(
	customerId,
	paymentPlanId
  ): Observable<IPaymentPlanInProgressSummary[]> {
	this.appConfig = this.environmentConfig.getConfig();
	const url = `${this.appConfig.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan/${paymentPlanId}/instalments`;
	return this.httpClient.get<IPaymentPlanInProgressSummary[]>(url);
  }

  getInProgressPaymentPlan(customerId): Observable<IInstallmentPlan> {
	this.appConfig = this.environmentConfig.getConfig();
	const url = `${this.appConfig.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}`;
	return this.httpService
		.get<any>(url)
		.pipe(
		map((response) =>
			response.paymentPlan
			? response.paymentPlan.filter(
				(plans) => plans.type === "PaymentPlan"
				)[0] || <IInstallmentPlan>{}
			: <IInstallmentPlan>{}
		)
		);
  }

*/

  getDodoEligibility(customerId, planType): Observable<IPaymentPlanEligibility> {
	const _url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan/eligibility?planType=${planType}`;
	return this.httpClient.get<IPaymentPlanEligibility>(_url);
  }

  postDodoPaymentPlanDetails(customerId, data): Observable<IPaymentPlanPostObject> {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan`;
	return this.httpClient.post<IPaymentPlanPostObject>(url, data);
  }

  getDodoPaymentPlanSummary(
	customerId,
	paymentPlanId
  ): Observable<IPaymentPlanInProgressSummary[]> {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan/${paymentPlanId}/instalments`;
	return this.httpClient.get<IPaymentPlanInProgressSummary[]>(url);
  }

  getDodoInProgressPaymentPlan(customerId): Observable<IInstallmentPlan> {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}`;
	return this.httpService
		.get<any>(url)
		.pipe(
		map((response) =>
			response.paymentPlan
			? response.paymentPlan.filter(
				(plans) => plans.type === "PaymentPlan"
				)[0] || <IInstallmentPlan>{}
			: <IInstallmentPlan>{}
		)
		);
  }

  // **** UnAuth to get Payment Plan Extension Eligibility for selected Product
  getEligibilityUnAuth(
	customerId,
	planType
  ): Observable<IPaymentPlanEligibility> {
	const _url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan/eligibility?planType=${planType}`;
	return this.httpClient.get<IPaymentPlanEligibility>(_url);
  }

  postPaymentPlanDetailsUnAuth(
	customerId,
	data
  ): Observable<IPaymentPlanPostObject> {
	const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan`;
	return this.httpClient.post<IPaymentPlanPostObject>(url, data);
  }

  getPaymentPlanUnpaidSummary(
	customerId,
	paymentPlanId
  ): Observable<IPaymentPlanInProgressSummary[]> {
	const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/accountmgmt/customer/${customerId}/paymentplan/${paymentPlanId}/instalments`;
	return this.httpService
		.get<any>(url)
		.pipe(
		map((response) =>
			response
			? response.filter((plans) => plans.status === "Unpaid") ||
				<IPaymentPlanInProgressSummary>{}
			: <IPaymentPlanInProgressSummary>{}
		)
		);
  }
}
