import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { IBillingAccount, IProduct } from "src/app/self-serve/models/product.model";
import { ISelectedPayment } from "src/app/self-serve/models/store.model";
import { BillService } from "src/app/self-serve/services/bill.service";
import { AppStoreService, AppStore } from "src/app/services/appStore.service";
import { PaymentPlanService } from "src/app/self-serve/services/payment-plan.service";
import { IProductPaymentPlanExtPath } from "src/app/self-serve/models/product.model";
import { HelperService } from "src/app/services/helper.service";
import { PAYMENT_AMOUNT_FLASH_MESSAGES } from "./payment-amount.enum";
import { TealiumService } from "src/tealium/services/tealium.service";
@Component({
	selector: "dodo-payment-amount",
	templateUrl: "./payment-amount.component.html",
	styleUrls: ["./payment-amount.component.scss"]
})
export class PaymentAmountComponent implements OnInit {
	payment: ISelectedPayment = undefined;
	productId: any;
	public _appStore: AppStore;
	public showPaymentPlan = false;
	products: IProduct[];
	loadingplan: boolean;
	apiInProgressPaymentPlanExtError = false;
	isDataAvailable = false;
	errorMsg = "";
	flgmakePayforRegularBill = false;
	hasOutage = false;
	public msgHasOutage: string = undefined;

	@Input()
	billingAccount: IBillingAccount;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private billService: BillService,
		private appStoreService: AppStoreService,
		private paymentPlanService: PaymentPlanService,
		private helperService: HelperService,
		private tealiumService: TealiumService
	) { }

	ngOnInit() {
		this.loadingplan = true;
		const productId = this.route.snapshot.paramMap.get("productId");
		this.billingAccount = this.helperService.getBillingAccountById(productId);

		if (this.billingAccount) {
			this.getBalance();
		} else {
			this.loadDodoPayments(productId);
		}

		if (this.hasOutage === true) {
			this.msgHasOutage = PAYMENT_AMOUNT_FLASH_MESSAGES.OUTAGE_MESSAGE;
			this.tealiumService.setFlashWarningMsg(this.msgHasOutage, undefined);
		}
	}

	private getBalance(): void {
		this.billService
			.getAccountBalance(this.appStoreService.store.customer.accountId, this.billingAccount.id)
			.subscribe(balance => {
				this.billingAccount.accountBalance = balance;
				this.billingAccount.currentPayment = {
					amount: balance.amount.value.toString(),
					selectedPaymentMethod: null,
					questions: null
				};
				this.appStoreService.updateBillingAccount(this.billingAccount);
				this.loadingplan = false;
			});
	}

	public continueToPayment(paymentAmount: string) {
		this.billingAccount.currentPayment.amount = paymentAmount;
		this.appStoreService.updateBillingAccountPayment(this.billingAccount);
		this.router.navigate([
			"/make-a-payment/payment-type/",
			this.billingAccount.id
		]);
	}

	private loadDodoPayments(productId: string) {
		this.apiInProgressPaymentPlanExtError = false;
		this.products = this.appStoreService.store.products;
		this.products.map((product) => {
			this.paymentPlanService.getDodoInProgressPaymentPlan(productId).subscribe(
				(response) => {
					if (product.status === "Active") {
						if (response && Object.keys(response).length !== 0) {
							response.showInprogressSummary = true;
							this.isDataAvailable = true;
							this.loadingplan = false;
						} else {
							this.loadingplan = false;
						}
					} else {
						this.loadingplan = false;
					}

				},
				(error) => {
					this.errorMsg = PAYMENT_AMOUNT_FLASH_MESSAGES.INPROGRESS_PAYMENT_TRY_LATER_API_ERROR;
					this.tealiumService.setFlashErrorMsg(this.errorMsg, error?.error?.code);
					this.apiInProgressPaymentPlanExtError = true;
				}
			);
		});

		this._appStore = this.appStoreService.store;
		this.payment = <ISelectedPayment>{};
		// This is returning check, to find if previously checkbox was selected and CONTINUE TO PAY was clicked but then customer
		// returns back
		if (this.appStoreService.store.productPaymentPlanExtPath) {
			if (this.appStoreService.store.productPaymentPlanExtPath.paymentPlanExturl === "payment-amount-entry") {
				this.flgmakePayforRegularBill = this.appStoreService.store.productPaymentPlanExtPath.isCheckedMakepayforRegularbill;
				this.products = this.appStoreService.store.products.filter(product => product.status !== "Cancelled" && product.productCategoryType !== "Entertainment");
				this.appStoreService.store.products.map((data) => {
					if (data.payment) {
						if (data.payment.inProgressPlan) {
							if (data.id === productId) {
								data.payment.inProgressPlan.showInprogressSummary = false;
								// I want to update the flag
								this.appStoreService.updateInProgressPaymentPlanExtList(
									productId,
									data.payment.inProgressPlan
								);
							}
						}
					}
				});
			}
		}
		// Show the stored payment data
		if (
			this._appStore.customer.payment &&
			+this._appStore.customer.payment.amount > 0 &&
			this._appStore.customer.payment.selectedProduct.id === productId
		) {
			this.payment = this._appStore.customer.payment;
			this.showPaymentPlan = true;
			this.payment.instalment_paid = "0"; // no instalment for normal payment; telium
		} else {
			// Show direct from product
			// or refresh of page
			// or from make a payment page
			// this.payment = this._appStore.customer.payment;
			if (!this.payment.selectedProduct) {
				this.payment.selectedProduct = this.getProduct(productId);
				this.showPaymentPlan = true;
				if (!this.payment.amount) {
					if (!this.payment.selectedProduct.accountBalance) {
						this.getDodoBalance();
					} else {
						this.payment.amount =
							"" + this.payment.selectedProduct.accountBalance.amount.value;
					}
				}
				this.payment.instalment_paid = "0"; // no instalment for normal payment; telium
				this._appStore.customer.payment = { ...this.payment };
				this.appStoreService.updateDodoCustomerPayment(this.payment);
			}
		}

		const productPaymentPlanExtPath = <IProductPaymentPlanExtPath>{};
		productPaymentPlanExtPath.paymentPlanExturl = "in-progress-summary";
		productPaymentPlanExtPath.paymentPlanCurrenturl = "payment-amount";
		productPaymentPlanExtPath.id = this.productId;
		this.appStoreService.updateProductPaymentPlanExtPath(
			productPaymentPlanExtPath
		);
	}

	private getDodoBalance(): void {
		this.billService
			.getDodoAccountBalance(this.payment.selectedProduct.id)
			.subscribe((balance) => {
				this.payment.selectedProduct.accountBalance = balance;
				this.payment.amount =
					"" + this.payment.selectedProduct.accountBalance.amount.value;
				this._appStore.customer.payment = { ...this.payment };
				this.appStoreService.updateDodoCustomerPayment(this.payment);
			});
	}

	private getProduct(productId): IProduct {
		let selected: any = false;
		this._appStore.products.map((product) => {
			if (product.id === productId) {
				selected = product;
			}
		});
		return selected;
	}

	public gotoBack(): void {
		this.router.navigate(["/make-a-payment"]);
	}

	public hideActivePaymentPlans(): void {
		const productId = this.route.snapshot.paramMap.get("productId");
		this.appStoreService.store.products.map((data) => {
			if (data.id === productId) {
				data.payment.inProgressPlan.showInprogressSummary = false;
				this.isDataAvailable = true;
			}
		});
	}
}
