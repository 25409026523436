import { environment } from "src/environment";

export const NavMenu = [
  {
	mainMenu: "My services",
	mainLinks: [],

	footer: [
		{
		footerText: "",
		footerQuickLinks: [
			{
			footerLinkTitle: "Link accounts",
			href: "",
			external: "false"
			},
			{
			footerLinkTitle: "Upgrade",
			href: "",
			external: "false"
			}
		]
		}
	]
  },
  {
	mainMenu: "Billing & Payment",
	mainLinks: [
		{
		submenuTitle: "Bills, balance & information",
		submenuLinks: [
			{
			submenuLinksTitle: "Billing overview",
			href: "/billing-overview",
			external: "false",
			subtitle: ""
			}
		]
		},
		{
		submenuTitle: "Payment options",
		submenuLinks: [
			{
			submenuLinksTitle: "Make a payment",
			href: "/make-a-payment",
			external: "false",
			subtitle: ""
			}
		]
		},
		{
		submenuTitle: "Billing support",
		submenuLinks: [
			{
			submenuLinksTitle: "Payment extension & plan",
			href: "/payment-help",
			external: "false",
			subtitle: ""
			},
			{
			submenuLinksTitle: "Billing & account FAQs",
			href: environment.supportSiteHostServer,
			external: "true",
			subtitle: ""
			}
		]
		},
		{
		submenuTitle: "Billing information",
		submenuLinks: [
			{
			submenuLinksTitle: "Payment details",
			href: "/payment-details-overview",
			external: "false",
			subtitle: ""
			}
		]
		}
	],
	footer: [
		{
		footerText: "",
		footerQuickLinks: [
			{
			footerLinkTitle: "Make a payment",
			href: "",
			external: "false"
			},
			{
			footerLinkTitle: "Bills",
			href: "",
			external: "false"
			}
		]
		}
	]
  },
  {
	mainMenu: "Support",
	mainLinks: [
		{
		submenuTitle: "FAQs",
		submenuLinks: [
			{
			submenuLinksTitle: "Internet",
			href: environment.supportSiteHostServer + "/internet",
			external: "true",
			subtitle: ""
			},
			{
			submenuLinksTitle: "Energy",
			href: environment.supportSiteHostServer + "/energy",
			external: "true",
			subtitle: ""
			},
			{
				submenuLinksTitle: "Mobile",
				href: environment.supportSiteHostServer + "/mobile",
				external: "true",
				subtitle: ""
			},
			{
				submenuLinksTitle: "Mobile broadband",
				href: environment.supportSiteHostServer + "/mobile-broadband",
				external: "true",
				subtitle: ""
			},
			{
				submenuLinksTitle: "Home phone",
				href: environment.supportSiteHostServer + "/home-phone",
				external: "true",
				subtitle: ""
			},
			{
			submenuLinksTitle: "Fetch",
			href: environment.supportSiteHostServer + "/fetch-tv",
			external: "true",
			subtitle: ""
			},
			{
			submenuLinksTitle: "Billing & accounts",
			href: environment.supportSiteHostServer,
			external: "true",
			subtitle: ""
			}
		]
		},
		{
		submenuTitle: "Trouble shooting",
		submenuLinks: [
			{
			submenuLinksTitle: "Log a support request",
			href: "/support-request-overview",
			external: "false",
			subtitle: ""
			},
			{
			submenuLinksTitle: "Network status",
			href: "http://systemstatus.dodo.com.au/",
			external: "true",
			subtitle: ""
			}
		]
		},
		{
		submenuTitle: "Hardware delivery",
		submenuLinks: [
			{
			submenuLinksTitle: "Track my order",
			href: "https://www.mytoll.com/?externalSearchQuery=&op=Search",
			external: "true",
			subtitle: ""
			}/*,
			{
			submenuLinksTitle: "Order Status",
			href: "/order-status",
			external: "false",
			subtitle: ""
			}*/
		]
		},
		{
		submenuTitle: "Moving home?",
		submenuLinks: [
			{
			submenuLinksTitle: "Get in touch",
			href: environment.websiteHostServer + "/moving-house/",
			external: "true",
			subtitle: ""
			}
		]
		},
		{
		submenuTitle: "Payment assistance",
		submenuLinks: [
			{
			submenuLinksTitle: "Payment extension & plan",
			href: "/payment-help",
			external: "false",
			subtitle: ""
			}
		]
		}
	],
	footer: [
		{
		footerText: "",
		footerQuickLinks: [
			{
			footerLinkTitle: "Troubleshoot an issue",
			href: "",
			external: "true"
			},
			{
			footerLinkTitle: "Dodo Support",
			href: "",
			external: "true"
			},
			{
			footerLinkTitle: "FAQs",
			href: "",
			external: "true"
			}
		]
		}
	]
  },
  {
	mainMenu: "Account",
	mainLinks: [
		{
		submenuTitle: "Customer information",

		submenuLinks: [
			{
			submenuLinksTitle: "Personal details",
			href: "/account-introduction",
			external: "false",
			subtitle: ""
			},
			{
			submenuLinksTitle: "Webmail",
			href: "/mail-box",
			external: "false",
			subtitle: ""
			},
			{
			submenuLinksTitle: "Payment details",
			href: "/payment-details-overview",
			external: "false",
			subtitle: ""
			}
		]
		},

		{
		submenuTitle: "Leave My Dodo",
		submenuLinks: [
			{
			submenuLinksTitle: "Dodo.com",
			href: environment.websiteHostServer,
			external: "true",
			subtitle: "",
			target: "_blank"
			},
			{
			submenuLinksTitle: "Dodo Webmail",
			href: "https://webmail.dodo.com.au",
			external: "true",
			subtitle: "",
			target: "_blank"
			},
			{
			submenuLinksTitle: "Log out",
			href: "/logout",
			external: "false",
			subtitle: ""
			}
		]
		}
	],
	footer: [
		{
		footerText: "",
		footerQuickLinks: [
			{
			footerLinkTitle: "Make a payment",
			href: "",
			external: "false"
			},
			{
			footerLinkTitle: "Bills",
			href: "",
			external: "false"
			}
		]
		}
	]
  }
];
