import {
  Component,
  Input,
  OnInit
} from "@angular/core";

// custom store
import { IProduct } from "../../../models/product.model";
import { PaymentPlanService } from "src/app/self-serve/services/payment-plan.service";
import { AppStoreService } from "src/app/services/appStore.service";
import { ActivatedRoute } from "@angular/router";
import { PaymentAmountComponent } from "src/app/self-serve/containers/payment/payment-amount/payment-amount.component";

@Component({
  selector: "dodo-in-progress-payment-plans",
  templateUrl: "./in-progress-payment-plans.component.html"
})
export class InProgressPaymentPlansComponent implements OnInit {
  products: IProduct[];
  loadingplan = true;
  apiInProgressPaymentPlanExtError = false;
  isDataAvailable = false;
  screenWidth: number;
  productSelected: IProduct;
  totalBalanceRemaining = 0;
  constructor(
	private paymentPlanService: PaymentPlanService,
	private appStoreService: AppStoreService,
	private route: ActivatedRoute,
	private parent: PaymentAmountComponent

  ) { }
  @Input() productId: string;

  ngOnInit() {
	const productId = this.route.snapshot.paramMap.get("productId");
	this.screenWidth = window.innerWidth;
	if (this.appStoreService.store.backBtn) {
	this.products = this.appStoreService.store.products.filter(product => product.status !== "Cancelled" && product.productCategoryType !== "Entertainment");
		this.productSelected = this.products.find(product => product.id === productId);
		this.appStoreService.store.products.map((data) => {
		if (data.payment) {
			if (data.payment.inProgressPlan) {
			if (data.id === productId) {
				this.productSelected.payment.inProgressPlan = data.payment.inProgressPlan;
				// When I return from payment amount, I want to check the stored value and if false make it false
				if (!data.payment.inProgressPlan.showInprogressSummary) {
				this.productSelected.payment.inProgressPlan.showInprogressSummary = false;
				} else {
				this.productSelected.payment.inProgressPlan.showInprogressSummary = true;
				}
			}
			}
		}
		// if coming back from payment amount entry page, make this false
		this.isDataAvailable = true;
		this.loadingplan = false;
		});
		this.appStoreService.updateProductPaymentPath(false);
		this.appStoreService.updateProductPaymentSummaryPath(true);
	} else {
		this.totalBalanceRemaining = 0;
		this.loadingplan = true;
		this.apiInProgressPaymentPlanExtError = false;
	this.products = this.appStoreService.store.products.filter(product => product.status !== "Cancelled" && product.productCategoryType !== "Entertainment");
		this.productSelected = this.products.find(product => product.id === productId);
		this.paymentPlanService.getDodoInProgressPaymentPlan(productId).subscribe(
		(response) => {
			if (this.productSelected.status === "Active") {
			if (response && Object.keys(response).length !== 0) {
				this.productSelected.payment = {};
				this.productSelected.payment.inProgressPlan = response;
				this.paymentPlanService
				.getPaymentPlanUnpaidSummary(productId, this.productSelected.payment.inProgressPlan.id)
				.subscribe((data) => {
					this.productSelected.payment.inProgressPlanSummary = data;
					for (let i = 0; i < data.length; i++) {
					this.totalBalanceRemaining = this.totalBalanceRemaining + data[i].amount.value;
					}
					if (this.totalBalanceRemaining !== 0) {
					this.productSelected.payment.inProgressPlan.totalBalanceRemaining = this.totalBalanceRemaining;
					response.totalBalanceRemaining = this.totalBalanceRemaining;
					}

					// except first value record , disable first record to false
					data[0].disabled = false;
					for (let i = 1; i < data.length; i++) {
					data[i].disabled = true;
					}
					// update InProgressSummary
					this.appStoreService.updateInProgressPaymentPlanSummary(
					productId,
					data
					);
					this.appStoreService.updateInProgressPaymentPlanExtList(
					productId,
					response
					);
				});
				this.isDataAvailable = true;
			}
			}
			this.productSelected.payment.inProgressPlan.showInprogressSummary = true;
			this.loadingplan = false;
		},
		(error) => {
			this.apiInProgressPaymentPlanExtError = true;
		}
		);
	}
  }

  onResize(event) {
	this.screenWidth = event.target.innerWidth;
  }

  getIconPath(icon): string {
	if (icon === "Homephone") {
		icon = "home-phone";
	}
	return `assets/images/icons/icon-${icon.toLowerCase()}.svg`;
  }
}
