import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-payment-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
