import { Component, OnDestroy, OnInit } from "@angular/core";

import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";

import { INavigation } from "../../models/navigation.model";

import { IProduct, IProductSelected } from "../../self-serve/models/product.model";

import { NavMenu } from "../../../assets/configs/navigation.config";

import { NavService } from "../../self-serve/services/navigation.service";
import { AppStoreService, APP_EVENT } from "src/app/services/appStore.service";
import { filter } from "rxjs/internal/operators/filter";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  globalNav = true;
  paymentActionNav = false;
  otherActionNav = false;
  otherActionNavMsg = "";
  otherActionNavSubMsg = "";
  isActive = false;
  headingActive = -1;

  isMobSubmenuActive = false;
  mainMenuObjs: any;
  submenuObjs: INavigation;
  footerObjs: any;
  previousNavItem: any = "";

  navProducts: IProduct[];
  isAccountMobActive = false;

  constructor(
	private navService: NavService,
	private router: Router,
	private location: Location,
	private appStoreService: AppStoreService
  ) {
	/*Specific for make payment navigation*/
	this.router.events.subscribe(route => {
		if (route instanceof NavigationEnd) {
		if (
			this.location.path().includes("payment-amount") ||
			this.location.path().includes("payment-type") ||
			this.location.path().includes("payment-summary") ||
			this.location.path().includes("payment-successful")
		) {
			this.globalNav = false;
			this.paymentActionNav = true;
			this.otherActionNav = false;
		} else if (this.location.path().includes("instalment-plan")) {
			this.globalNav = false;
			this.paymentActionNav = false;
			this.otherActionNav = true;
			this.otherActionNavMsg = "Review instalment plan";
		} else if (this.location.path().includes("payment-help/")) {
			this.globalNav = false;
			this.paymentActionNav = false;
			this.otherActionNav = true;
			this.otherActionNavMsg = "Help paying my bill";
			if (this.location.path().includes("payment-help/get-started")) {
			this.otherActionNavSubMsg = "Get started";
			} else if (
			this.location.path().includes("payment-help/select-service")
			) {
			this.otherActionNavSubMsg = "Select a service";
			} else if (
			this.location.path().includes("payment-help/payment-plan-extension")
			) {
			this.otherActionNavSubMsg = "How can we help";
			} else if (
			this.location
				.path()
				.includes("payment-help/payment-plan-options") ||
			this.location
				.path()
				.includes("payment-help/payment-extension-options")
			) {
			this.otherActionNavSubMsg = "Select payment options";
			} else if (
			this.location
				.path()
				.includes("payment-help/payment-plan-confirmation") ||
			this.location
				.path()
				.includes("payment-help/payment-extension-confirmation")
			) {
			this.otherActionNavSubMsg = "Confirmation";
			}
		} else if (this.location.path().includes("credit-balance/refund")) {
			this.globalNav = false;
			this.paymentActionNav = false;
			this.otherActionNav = true;
			this.otherActionNavMsg = "Credit refund request";
			if (this.location.path().includes("credit-balance/refund-overview")) {
			this.otherActionNavSubMsg = "Get started";
			} else if (
			this.location.path().includes("credit-balance/refund-amount")
			) {
			this.otherActionNavSubMsg = "Enter refund amount";
			} else if (
			this.location.path().includes("credit-balance/refund-summary")
			) {
			this.otherActionNavSubMsg = "Credit refund submit";
			}
		} else {
			this.globalNav = true;
			this.otherActionNav = false;
			this.paymentActionNav = false;
		}
		}
	});
  }

  // For desktop hover state and open nav from hamburger icon

  openNav() {
	this.isActive = true;
	document.querySelector("body").style.overflow = "hidden";
	document.querySelector("body").style.position = "fixed";
	document.querySelector("body").style.left = "0";
	document.querySelector("body").style.right = "0";
  }

  // For closing active nav on desktop and close nav from 'close' button on mobile
  closeNav(submenuLink) {
	if (submenuLink !== "none") { this.setSourceUrl(submenuLink); }
	document.querySelector("body").style.overflow = "auto";
	document.querySelector("body").style.position = "relative";
	this.isActive = false;
	this.isMobSubmenuActive = false;
	this.headingActive = -1;
  }

  // For loading submenu
  loadNav(navItem, i) {
	const screenWidth: number = window.innerWidth;
	this.isAccountMobActive = false;
	// Desktop submenu items
	if (screenWidth > 1023) {
		if (this.previousNavItem !== "" && this.previousNavItem !== navItem) {
		this.isActive = false;
		}

		this.isActive = !this.isActive;
		this.headingActive = i;

		document.querySelector("body").style.overflow = "hidden";

		if (this.previousNavItem === navItem && this.isActive === false) {
		document.querySelector("body").style.overflow = "auto";
		this.headingActive = -1;
		}

		this.previousNavItem = navItem;
	}

	// Mobile submenu items

	if (screenWidth < 1024) {
		this.isMobSubmenuActive = true;
		this.isActive = false;
		if (navItem === "Account" && i === 0) {
		// Just to check if it is account nav or not
		this.isAccountMobActive = true;
		}
		document.querySelector("body").style.overflow = "hidden";
		document.querySelector("body").style.position = "fixed";
		document.querySelector("body").style.left = "0";
		document.querySelector("body").style.right = "0";
	}

	if (this.mainMenuObjs) {
		for (let j = 0; j < this.mainMenuObjs.length; j++) {
		if (this.mainMenuObjs[j].mainMenu === navItem) {
			this.submenuObjs = this.mainMenuObjs[j].mainLinks;
			this.footerObjs = this.mainMenuObjs[j].footer;
		}
		}
	}
  }

  // For Mobile

  closeMobSubNav() {
	this.isMobSubmenuActive = false;
	this.isActive = true;
  }

  setSourceUrl(submenuLink) {
	const productSelected = <IProductSelected>{};
	if (submenuLink.submenuLinksTitle === "Payment extension & plan") {
		productSelected.sourceUrl = "payment-help";
	} else if (submenuLink.submenuLinksTitle === "Order Status") {
		productSelected.sourceUrl = "order-status";
		console.log("entered in this nav");
	} else { productSelected.sourceUrl = ""; }
	productSelected.id = "";
	this.appStoreService.updateProductSelected(productSelected);
  }

  ngOnInit() {
	// This service is used to add products in navigation config file
	this.navService.buildProductsNav();
	this.mainMenuObjs = NavMenu;
	this.appStoreService.storeUpdated
		.pipe(filter(event => event === APP_EVENT.PRODUCTS_UPDATED),
			takeUntil(this.destroyed$)
		)
		.subscribe(() => {
			this.navService.buildProductsNav();
			this.mainMenuObjs = NavMenu;
		});
  }

  ngOnDestroy() {
	this.destroyed$.next(true);
	this.destroyed$.complete();
  }
  gotoBack() {
	// const productId = this.route.snapshot.paramMap.get("productId");
	this.location.back();
  }
}
