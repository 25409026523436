import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// services
import { AuthenticationService } from "src/app/services/authentication.service";
import { Auth0Service } from "src/app/auth0/auth0.service";

@Component({
  selector: "app-signout",
  templateUrl: "./signout.component.html",
  styleUrls: ["./signout.component.scss"]
})
export class SignoutComponent implements OnInit {
  constructor(
	private authenticationService: AuthenticationService,
  private router: Router,
  private __auth0Service: Auth0Service
  ) {}

  ngOnInit() {
  this.authenticationService.LoggedOut();
  this.__auth0Service.logout();
  }
}
