<section class="maintenance">
  <section class="_banner">
    <div class="container">
      <section class="__form">
        <div class="row">
          <div class="col-lg-6 col-md-12 relative">
            <img
              src="assets/images/authentication/circle-white.svg"
              class="pattern left"
              alt=""
            />
            <img
              src="assets/images/authentication/circle-green.svg"
              class="pattern right"
              alt=""
            />
            <section class="sign-in">
              <img
                src="assets/images/authentication/corner-circle-green.svg"
                class="pattern inner"
                alt=""
              />
              <img src="assets/images/brand-primary.svg" alt="" />
              <div class="banner-message mt2">
                <h3 class="my5 font-semibold">Scheduled Maintenance</h3>
              </div>
              <span class="___info">
                <div [innerHTML]="msg"></div>
              </span>
            </section>
            <img
              class="illustration lg-only"
              src="assets/images/authentication/banner.png"
              alt=""
            />
            <img
              class="lg-only pattern bottom"
              src="assets/images/authentication/pattern.svg"
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  </section>
</section>

