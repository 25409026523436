
<section>
    <app-navigation></app-navigation>
  </section>
  <section class="page-not-found container">
    <div class="row">
      <div class="column">
        <p class="_header">Oops, seems this page has gone missing</p>
        <p class="_contents">
          With the money we’ve saved on the internet, we’ve been off spending
          money on the internet…..and forgot to build this page!
        </p>
        <p class="_contents mt3">
          Rest assured, it hasn’t gone the way of the Dodo, and will be back soon.
        </p>
        <p class="_contents mt3">
          In the meantime, please visit the homepage or use the above navigation
          menu.
        </p>
        <p class="_btn-align">
          <button class="btn brand-btn-primary" routerLink="/login">
            BACK TO MY DODO
          </button>
        </p>
      </div>
      <div class="column">
        <img class="_img-align" src="assets/images/common/brand-bird-sad.png" />
      </div>
    </div>
  </section>
  <section>
    <app-footer></app-footer>
  </section>
  