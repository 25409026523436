import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentService } from "src/app/self-serve/services/payment.service";
import { IBillingAccount } from "src/app/self-serve/models/product.model";
import { IPaymentMethod } from "src/app/self-serve/models/payment.model";
import { environment } from "src/environment";
import { UUID } from "angular2-uuid";
import { TealiumService } from "src/tealium/services/tealium.service";
import { PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES } from "./payment-summary-verification.enum";

@Component({
	selector: "app-payment-summary-verification",
	templateUrl: "./payment-summary-verification.component.html",
	styleUrls: ["./payment-summary-verification.component.scss"]
})
export class PaymentSummaryVerificationComponent implements OnChanges {
	@Input() step;
	@Input() billingAccount: IBillingAccount;

	selectedPayment: IPaymentMethod;
	busy = false;
	errorMessage;

	constructor(
		private router: Router,
		private paymentService: PaymentService, private tealiumService: TealiumService
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.billingAccount = changes["billingAccount"].currentValue;
		this.selectedPayment = this.billingAccount.currentPayment.selectedPaymentMethod;
	}

	public continueToSecurePayment(): void {
		this.busy = true;

		const data = {
			description: "MyDodo Self Service",
			merchantReference: UUID.UUID(),
			account: { id: this.billingAccount.zouraId },
			paymentMethod: {
				"@type": "TokenizedCard",
				details: {
					token: this.selectedPayment.details.token
				},
				id: this.billingAccount.currentPayment.selectedPaymentMethod.id
			},
			totalAmount: { amount: + this.billingAccount.currentPayment.amount },
			relatedParty: [
				{
					name: environment.merchant,
					role: "Merchant"
				}
			]
		};

		this.paymentService
			.postPayment(data)
			.subscribe(
				(dataMessage) => {
					this.busy = false;
					if (dataMessage.success) {
						this.router.navigate([
							"/make-a-payment/payment-successful/",
							this.billingAccount.id
						]);
					} else {
						this.errorMessage = PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES.CANNOT_PROCESS_PAYMENT_API_ERROR;
						this.tealiumService.setFlashErrorMsg(this.errorMessage, undefined);
					}
				},
				(error) => {
					this.busy = false;
					if (error.status === 400) {
						if (error.error.code === 20) {
							this.errorMessage = error.error.message;
							this.tealiumService.setFlashErrorMsg(this.errorMessage, error?.error?.code);
						} else {
							this.errorMessage = PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES.CANNOT_PROCESS_PAYMENT_API_ERROR;
							this.tealiumService.setFlashErrorMsg(this.errorMessage, error?.error?.code);
						}
					} else {
						this.errorMessage = PAYMENT_SUCCESSFUL_VERIFICATION_FLASH_MESSAGES.CANNOT_PROCESS_PAYMENT_API_ERROR;
						this.tealiumService.setFlashErrorMsg(this.errorMessage, error?.error?.code);
					}
				}
			);
	}

	public continueToPaymentType(): void {
		this.router.navigate([
			"/make-a-payment/payment-type/",
			this.billingAccount.id
		]);
	}

	public getIconPath(icon): string {
		return `assets/images/icons/icon-bills.svg`;
	}
}
