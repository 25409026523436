import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { AuthenticationService } from "./services/authentication.service";
import { AppStoreService } from "src/app/services/appStore.service";
import { ICustomer } from "src/app/self-serve/models/store.model";
@Injectable({
  providedIn: "root"
})
export class AuthloginGuard  {
  constructor(
	private authenticationService: AuthenticationService,
	private router: Router,
	private __appStoreService: AppStoreService
  ) {}
  _customer: ICustomer;
  canActivate(): boolean {
	this._customer = this.__appStoreService.store.customer;
	if (this._customer?.email_verified === true) {
		if (this.authenticationService.isLoggedIn()) {
			return true;
		} else {
			this.authenticationService.LoggedOut();
			this.router.navigate([`/login`]);
			return false;
		}
	} else {
		return true;
	}
  }
}
