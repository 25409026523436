<section class="bg-color-primary height-full">
  <section class="mfa-code bg-color-primary">
    <div class="_mfa-code-container bg-white">
      <div class="mydodo-custom-header">
        <div class="auth0-lock-header-welcome">
          <img
            alt="MyDodo"
            class="auth0-lock-header-logo"
            src="assets/images/brand-primary.svg"
          />
        </div>
        <div>
          <h3>Hey there!</h3>
          <span class="mfa-sub-header">Enter the code we sent to </span>
          <span class="mfa-sub-header-txt">{{ selectedMobileEmail }}</span>
        </div>
      </div>
      <form #mfacode="ngForm" novalidate>
        <div class="auth0-lock-body-content">
          <div class="auth0-lock-content">
            <div class="auth0-lock-input-block auth0-lock-input-username">
              <div
                class="auth0-lock-input-wrap auth0-lock-input-wrap-with-icon"
              >
                <span
                  aria-hidden="true"
                  class="auth0-lock-icon auth0-lock-icon-box"
                  >123</span
                >
                <input
                  tabindex="1"
                  type="text"
                  name="codeValue"
                  #codeValue="ngModel"
                  [(ngModel)]="codeEntered"
                  class="auth0-lock-input"
                  placeholder=""
                  autocomplete="off"
                  autocorrect="off"
                  aria-invalid="false"
                  value=""
                  required
                  maxlength="6"
                  minlength="6"
                  pattern="[0-9]*"
                  [class.invalid]="codeValue.invalid && codeValue.touched"
                  [class.valid]="codeValue.valid && codeValue.touched"
                  (focus)="onFocusEvent($event)"
                />
              </div>
            </div>
            <div>
              <small
                class="indicators block txt-xs"
                [class.hide]="codeValue?.valid || codeValue?.untouched"
                *ngIf="codeValue?.errors?.required"
                >Code is required.
              </small>
              <small
                class="indicators block txt-xs"
                [class.hide]="codeValue?.valid || codeValue?.untouched"
                *ngIf="
                  codeValue?.errors?.pattern || codeValue?.errors?.maxlength
                "
                >Please enter a valid numeric 6 digit code.
              </small>
              <small
                class="indicators block txt-xs"
                [class.hide]="codeValue?.invalid || codeValue?.untouched"
                *ngIf="passCodeError === true"
                >{{ errCustomMsg }}
              </small>
            </div>
            <div class="mfa-code-resend resend-margin">
              <a (click)="resendMFAcode($event)" tabindex="3"
                ><span class="">Didn’t get a code? Resend code</span></a
              >
            </div>
            <div class="mfa-code-back">
              <a routerLink="/auth0-mfa" tabindex="4">Go back</a>
            </div>
          </div>
        </div>
        <button type="button"
          class="auth0-lock-submit"
          tabindex="2"
          (click)="validateCodeEntered($event)"
          [disabled]="
            codeValue?.errors?.required ||
            codeValue?.errors?.pattern ||
            codeValue?.errors?.maxlength ||
            codeValue?.errors?.minlength
          "
        >
          <span
            class="auth0-label-submit"
            *ngIf="!btnLoader"
            [disabled]="
              codeValue?.errors?.required ||
              codeValue?.errors?.pattern ||
              codeValue?.errors?.maxlength ||
              codeValue?.errors?.minlength
            "
            >Let's go
          </span>
          <span class="mfa-btn-preloader" *ngIf="btnLoader"></span>
        </button>
      </form>

      <div class="mydodo-custom-footer">
        <a
          href="{{ goMFAsupportForm() }}"
          (click)="resetAuth0Tokens()"
          tabindex="5"
          >Need help logging in?</a
        >
      </div>
    </div>
  </section>
</section>
