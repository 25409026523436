import { Component, OnInit } from "@angular/core";
import { environment } from "src/environment";
import { Auth0Service } from "../../../../auth0/auth0.service";
import { AppStoreService } from "src/app/services/appStore.service";
@Component({
	selector: "app-mfa-nocontacts-error",
	templateUrl: "./mfa-nocontacts-error.component.html"
})
export class MfaNocontactsErrorComponent implements OnInit {
	constructor(private appStoreService: AppStoreService,
		private __auth0Service: Auth0Service) { }

	ngOnInit() {
		this.resetAuth0Tokens();
	}

	backHomePage() {
		window.location.replace("https://www.dodo.com/");
	}
	goMFAsupportForm() {
		window.location.replace(`${environment.websiteHostServer}/online-support-request-my-dodo`);
	}
	resetAuth0Tokens() {
		this.__auth0Service.resetAuth0Tokens();
		this.appStoreService.flushAppStoreLocalStorage();
	}
}
