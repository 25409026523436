// angular
import { Injectable, Optional } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

// rxjs
import { Observable, throwError, timer, of } from "rxjs";
import { catchError, retryWhen, mergeMap, finalize } from "rxjs/operators";

// config import
import { AppError } from "src/app/models/error.model";
import { environment } from "src/environment";
import { TealiumService } from "src/tealium/services/tealium.service";

// config params - default
const RETRY_INTERVAL = 5000;
const RETRY_COUNT = 3;

@Injectable()
export class HttpService {

  constructor(
	private http: HttpClient,
	private tealiumService: TealiumService
  ) {  }

  public get<T>(endpoint, options?: {}): Observable<T> {
	return this.http.get<T>(endpoint, options).pipe(
		// retryWhen(this.genericRetryStrategy()),
		catchError((error$, caught: Observable<any>) => {
			return this.handleError(error$, "get");
		})
	);
  }

  public patch(endpoint, body, options?: {}): Observable<boolean> {
	return this.http.patch<boolean>(endpoint, body, options).pipe(
		catchError((error$, caught: Observable<any>) => {
			return this.handleError(error$, "patch");
		})
	);
  }

  public post<T>(endpoint, body, options?: {}): Observable<T> {
	return this.http.post<T>(endpoint, body, options).pipe(
		catchError((error$, caught: Observable<any>) => {
			return this.handleError(error$, "post");
		})
	);
  }

  private handleError(errorResponse, method: string) {
	const appError = new AppError();
	if (errorResponse instanceof HttpErrorResponse) {
		appError.apiError = errorResponse.error;
		appError.apiError.method = method;
	} else {
		appError.message = errorResponse;
	}

	this.tealiumService.error(appError);
	return throwError(appError);
  }

	/** deprecated */
	public getSlience<T>(endpoint, options?: {}): Observable<T> {
		return this.http.get<T>(endpoint, options).pipe(
			// retryWhen(this.genericRetryStrategy()),
			catchError((error$, caught: Observable<any>) => of({ error: true}))
		);
	  }

  genericRetryStrategy = ({
	maxRetryAttempts = environment.retryCount || RETRY_COUNT,
	scalingDuration = 2000,
	excludedStatusCodes = [400, 401, 403, 405, 409]
  }: {
	maxRetryAttempts?: number;
	scalingDuration?: number;
	excludedStatusCodes?: number[];
  } = {}) => (attempts: Observable<any>) => {
	return attempts.pipe(
		mergeMap((error, i) => {
		const retryAttempt = i + 1;
		// if maximum number of retries have been met
		// or response is a status code we don't wish to retry, throw error
		if (
			retryAttempt > maxRetryAttempts ||
			excludedStatusCodes.find(e => e === error.status)
		) {
			return throwError(error);
		}
		console.log(
			`ATTEMPT ${retryAttempt}: RETRYING IN ${retryAttempt *
			scalingDuration}ms`
		);
		// retry after 1s, 2s, etc...
		return timer(retryAttempt * scalingDuration);
		}),
		finalize(() => console.log("RETRY ATTEMPT COMPLETED!"))
	);
  }
}
