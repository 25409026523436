<div class="payment-summary">
  <div
    class="_heading font-brand txt-color-primary px3 py2"
    *ngIf="step !== 'THIRD'"
  >
    Payment Summary
  </div>
  <div class="_subheading txt-black txt-base" *ngIf="step === 'THIRD'">
      Check that your payment details are correct.
  </div>

  <div class="_service-selected p3">
    <div class="__heading txt-grey-darker pb3">Account selected</div>
    <div class="__service pb2">
      <img [src]="getIconPath('bills')"/>
      <span class="___service-name mr2 txt-black font-brand">
          <span>{{ billingAccount.accountNo }}</span>
      </span>
      <span class="___service-id txt-grey-darker txt-sm">
        {{ billingAccount.name }}
      </span>
      <span class="background-gradient"></span>
    </div>
  </div>
  <div class="_amount p3" *ngIf="step !== 'FIRST'">
    <div class="__heading txt-grey-darker pb3">Amount</div>
    <div class="__value pb2">
      <span class="txt-black font-brand"
        >${{ billingAccount?.currentPayment?.amount | number: "0.2-2" }}</span
      >
    </div>
  </div>
  <div
    class="_payment-type-selected p3"
    *ngIf="step !== 'FIRST' && step !== 'SECOND'"
  >
    <div class="__heading txt-grey-darker pb3">Payment Type</div>
    <div class="__payment-method pb2">
      <img class="_card-icon mr2" src="assets/images/icons/icon-bills.svg" />
      <div class="_card-details txt-black txt-sm" *ngIf="selectedPayment.type==='Credit Card' || selectedPayment.type==='tokenizedCard'">
        <span class="__card-name block mb1" >{{
          selectedPayment.details.nameOnCard
        }}</span>
        <span class="__card-number"
          >**** **** **** 
          {{ selectedPayment.details.lastFourDigits }}</span
        >
      </div>
    </div>
  </div>
  <section *ngIf="errorMessage">
    <div class="error txt-sm font-brand txt-color-error bg-color-error">
      <img class="mr1" src="assets/images/icons/icon-error.svg" />
      {{ errorMessage }}
    </div>
  </section>
  <section class="_buttons" *ngIf="step === 'THIRD'">
    <app-button btnClass="brand-btn-info" (click)="continueToPaymentType()"
      >BACK</app-button
    >

    <button
      class="btn brand-btn-secondary relative"
      (click)="continueToSecurePayment()"
      [disabled]="busy"
    >
      <span *ngIf="!busy">MAKE A SECURE PAYMENT</span>
      <span *ngIf="busy" class="btn-preloader"></span>
    </button>
  </section>
</div>
