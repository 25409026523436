import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PageNotFoundComponent } from "./common/page-not-found/page-not-found.component";
import { SignoutComponent } from "./common/signout/signout.component";

import { PaymentExtPlanComponent } from "./dodo-unauth/containers/payment-ext-plan/payment-ext-plan.component";
import { PayBillLoginComponent } from "./dodo-unauth/containers/pay-bill/pay-bill-login/pay-bill-login.component";

// My order status
import { MyOrderStatusComponent } from "./dodo-unauth/containers/my-order-status/my-order-status.component";

// services
import { ProductService } from "./self-serve/services/product.service";
import { ForgotPwdComponent } from "./dodo-unauth/containers/forgot-pwd/forgot-pwd.component";
import { MaintenanceComponent } from "./common/maintenance/maintenance.component";
import { MaintenanceGuard } from "./maintenance.guard";
import { AuthGuard } from "./auth.guard";
import { StateGuard } from "./state.guard";
import { Auth0MfaComponent } from "./self-serve/containers/auth0-mfa/auth0-mfa.component";
import { MfaCodeComponent } from "./self-serve/containers/auth0-mfa/mfa-code/mfa-code.component";
import { MfaErrorComponent } from "./self-serve/containers/auth0-mfa/mfa-error/mfa-error.component";
import { MfaCodeErrorComponent } from "./self-serve/containers/auth0-mfa/mfa-code-error/mfa-code-error.component";
import { MfaNocontactsErrorComponent } from "./self-serve/containers/auth0-mfa/mfa-nocontacts-error/mfa-nocontacts-error.component";
import { AuthloginGuard } from "./authlogin.guard";
import { MfaBillitComponent } from "./self-serve/containers/auth0-mfa/mfa-billit/mfa-billit.component";
import { PWABiometricComponent } from "./dodo-unauth/containers/pwa-biometric/pwa-biometric.component";
import { AccountSetupComponent } from "./dodo-unauth/containers/forgot-pwd/account-setup/account-setup.component";

const routes: Routes = [
	{
		path: "maintenance",
		component: MaintenanceComponent

	}, {

		path: "", canActivate: [MaintenanceGuard], children: [
			{
				path: "", // if user comes with just URL n no path
				loadChildren: () => import("./auth0/auth0.module").then(m => m.Auth0Module)
			},
			{
				path: "dashboard", // dashboard
				loadChildren: () => import("./self-serve/containers/dashboard/dashboard.module").then(m => m.DashboardModule)
			},
			{
				path: "login", // login
				loadChildren:  () => import("./auth0/auth0.module").then(m => m.Auth0Module)
			},
			{
				path: "forgot-pwd",
				component: ForgotPwdComponent,
				data: {
					breadcrumb: "forgot-pwd"
				}
			},
			{
				path: "account-setup",
				component: AccountSetupComponent,
				data: {
					breadcrumb: "account-setup"
				}
			},
			{
				path: "billing-overview", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/bill/bill-overview/bill-overview.module").then(m => m.BillOverviewModule)
			},
			{
				path: "billing-overview/:accountId",
				loadChildren:
					() => import("./self-serve/containers/bill/bill-detail/bill-detail.module").then(m => m.BillDetailModule)
			},
			{
				path: "payment-details-overview", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/wallet/payment-overview/payment-overview.module").then(m => m.PaymentOverviewModule)
			},
			{
				path: "payment-details/:accountId",
				loadChildren:
					() => import("./self-serve/containers/wallet/payment-detail/payment-detail.module").then(m => m.PaymentDetailModule)
			},
			{
				path: "payment-details-overview/:productId",
				loadChildren:
					() => import("./self-serve/containers/wallet/payment-detail/dodo-payment-detail.module").then(m => m.DodoPaymentDetailModule)
			},
			{
				path: "make-a-payment", // Deeplinking YES
				loadChildren: () => import("./self-serve/containers/payment/payment.module").then(m => m.PaymentModule)
			},
			{
				path: "payment-help", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/payment-plan/payment-plan.module").then(m => m.PaymentPlanModule)
			},
			{
				path: "instalment-plan", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/instalment-plan/instalment-plan.module").then(m => m.InstalmentPlanModule)
			},
			{
				path: "credit-balance", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/credit-refund/credit-refund.module").then(m => m.CreditRefundModule)
			},
			{
				path: "order-status", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/track-order/track-order.module").then(m => m.TrackOrderModule)
			},
			{
				path: "support-request-overview", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/support-request/support-request.module").then(m => m.SupportRequestModule)
			},
			{
				path: "subscription/:productId",
				loadChildren:
					() => import("./self-serve/containers/subscription/subscription.module").then(m => m.SubscriptionModule)
			},
			{
				path: "usage-alert/:productId",
				loadChildren:
					() => import("./self-serve/containers/usage-alert/usage-alert.module").then(m => m.UsageAlertModule)
			},
			{
				path: "account-introduction", // Deeplinking YES
				loadChildren:
					() => import("./self-serve/containers/account-details/account-details.module").then(m => m.AccountDetailsModule)
			},
			{
				path: "mail-box",  // Deeplinking YES
				loadChildren: () => import("./self-serve/containers/mail-box/mail-box.module").then(m => m.MailBoxModule)
			},
			{
				path: "logout",
				component: SignoutComponent
			},
			{
				path: "select-services",
				loadChildren:
					() => import("./dodo-unauth/containers/my-payment/my-payment.module").then(m => m.MyPaymentModule)
			},
			{
				path: "payment-extension",
				redirectTo: "/"
			},
			{
				path: "payment-helper",
				loadChildren:
					() => import("./dodo-unauth/containers/my-payment-plan/my-payment-plan.module").then(m => m.MyPaymentPlanModule)
			},
			{
				path: "biometric-login",
				component: PWABiometricComponent
			},
			{
				path: "dodo-account-status",
				component: MyOrderStatusComponent,
				data: {
					breadcrumb: "dodo-account-status"
				},
				children: [
					{
						path: "",
						component: PayBillLoginComponent,
						data: {
							breadcrumb: "pay-bill-login"
						}
					}
				]
			},
			{
				path: "order-status-show/:productId",
				loadChildren:
					() => import("./dodo-unauth/containers/my-track-order/my-track-order.module").then(m => m.MyTrackOrderModule)
			},
			{
				path: "view-bill",
				loadChildren:
					() => import("./dodo-unauth/containers/view-my-bill/view-my-bill.module").then(m => m.ViewMyBillModule)
			},
			{
				path: "login/forgot-pwd",
				pathMatch: "full",
				redirectTo: "/forgot-pwd"
			},
			{
				path: "login/change-pwd",
				pathMatch: "full",
				redirectTo: "/forgot-pwd"
			},
			{
				path: "auth0-mfa", // auth0-mfa
				component: Auth0MfaComponent,
				canActivate: [AuthloginGuard, StateGuard],
				data: {
					breadcrumb: "auth0-mfa"
				}
			},
			{
				path: "auth0-mfa/mfa-code",
				component: MfaCodeComponent,
				canActivate: [AuthloginGuard, StateGuard],
				data: {
					breadcrumb: "mfa-code"
				}
			},
			{
				path: "auth0-mfa/mfa-code-error",
				component: MfaCodeErrorComponent,
				canActivate: [AuthloginGuard, StateGuard],
				data: {
					breadcrumb: "mfa-code-error"
				}
			},
			{
				path: "auth0-mfa/mfa-error",
				component: MfaErrorComponent,
				canActivate: [AuthloginGuard, StateGuard],
				data: {
					breadcrumb: "mfa-error"
				}
			},
			{
				path: "auth0-mfa/mfa-nocontacts-error",
				component: MfaNocontactsErrorComponent,
				canActivate: [AuthloginGuard, StateGuard],
				data: {
					breadcrumb: "mfa-nocontacts-error"
				}
			},
			{
				path: "auth0-mfa/mfa-billit",
				component: MfaBillitComponent,
				canActivate: [AuthloginGuard, StateGuard],
				data: {
					breadcrumb: "mfa-billit"
				}
			},
			{
				path: "**",
				component: PageNotFoundComponent
			}
		]
	}
];
@NgModule({
		imports: [
			RouterModule.forRoot(routes, {
			scrollPositionRestoration: "top",
			anchorScrolling: "enabled"
})
	],
	providers: [ProductService],
	exports: [RouterModule]
})
export class AppRoutingModule { }
