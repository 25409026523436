import { Injectable, ErrorHandler } from "@angular/core";
import { TealiumService } from "../../tealium/services/tealium.service";

@Injectable({
	providedIn: "root"
})
export class ClientValidationErrorHandlerService implements ErrorHandler {
	constructor(private tealiumService: TealiumService) { }

	public handleError(errorDetails: any): void {
		this.tealiumService.trackFieldValidationErrors(errorDetails);
	}

}
