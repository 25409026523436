// angular
import { BrowserModule } from "@angular/platform-browser";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";

// module
import { CommomComponentModule } from "./common/commom-component.module";
import { AppInitializationModule } from "./app-initialization.module";
import { SelfServeModule } from "../app/self-serve/self-serve.module";

// libraries
import { ApiModule } from "../libraries/api/api.module";

// components
import { AppComponent } from "./app.component";

// environment
import { environment } from "../../src/environment";

// router
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard } from "./auth.guard";
import { TealiumModule } from "../tealium/tealium.module";

import { GoogleChartsModule } from "angular-google-charts";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import { MatListModule } from "@angular/material/list";
import { PayBillModule } from "./dodo-unauth/containers/pay-bill/pay-bill.module";
import { PaymentExtPlanModule } from "./dodo-unauth/containers/payment-ext-plan/payment-ext-plan.module";
import { MyOrderStatusModule } from "./dodo-unauth/containers/my-order-status/my-order-status.module";
import { ViewMyBillModule } from "./dodo-unauth/containers/view-my-bill/view-my-bill.module";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MaintenanceGuard } from "./maintenance.guard";
import { RecaptchaSettings, RECAPTCHA_SETTINGS } from "ng-recaptcha-2";
import { RemoveDecimalPipe } from "./common/pipe/remove-decimal.pipe";

import { ErrorHandler } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { ClientValidationErrorHandlerService } from "./services/client-validation-error-handler.service";
import { FormControlErrorDirective } from "../app/services/form-control-error.directive";
import { PWABiometricModule } from "./dodo-unauth/containers/pwa-biometric/pwa-biometric.module";

@NgModule({ declarations: [AppComponent, RemoveDecimalPipe],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CommomComponentModule,
        AppInitializationModule,
        PayBillModule,
        PaymentExtPlanModule,
        PWABiometricModule,
        MyOrderStatusModule,
        ViewMyBillModule,
        SelfServeModule,
        ApiModule.forRoot({
            timeout: 60000,
            retryInterval: 3000,
            retryCount: environment.retryCount,
            configBasePath: "./assets/configs"
        }),
        AppRoutingModule,
        GoogleChartsModule.forRoot(),
        TealiumModule,
        BrowserAnimationsModule,
        MatListModule,
        PdfViewerModule], providers: [AuthGuard, MaintenanceGuard, { provide: MAT_DATE_LOCALE, useValue: "en-AU" },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: environment.recaptcha.siteKey
            } as RecaptchaSettings
        },
        { provide: ErrorHandler, useClass: ClientValidationErrorHandlerService }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
