import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

// components
import { PWABiometricComponent } from "./pwa-biometric.component";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [PWABiometricComponent]
})
export class PWABiometricModule {}
