import { environment } from "src/environment";

export class PageViewData {
	page_id: string = null;
	previous_page_path: string = null;
	site_prod_domain: string = environment.tealium_domain;
	tealium_event: string = null;
	customer_email: string = null;
	customer_service_id: string = null;

	product_category: string = null;
	product_subcategory: string = null;

	customer_active_products: string[] = null;
	customer_inactive_products: string[] = null;

	instalment_paid: string = null;

	event_trigger: string = null;
	migration_cohort: string = null;
	banner_message: string = null;
	product_nbn_cohort_id: string = null;

	type: string = null;
	error_message: string = null;
	error_code: any = null;
}

export class EventTrackingData {
	site_prod_domain: string = environment.tealium_domain;
	tealium_event: string = null;
	page_zone: string = null;
	button_type: string = null;
	link_text: string = null;
	link_url: string = null;
}
