import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

// components
import { PaymentExtPlanComponent } from "./payment-ext-plan.component";

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule],
  declarations: [PaymentExtPlanComponent]
})
export class PaymentExtPlanModule {}
