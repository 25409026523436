// angular
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

// services
import { ProductService } from "./services/product.service";
import { UsageService } from "./services/usage.service";
import { BootService } from "./services/boot.service";
import { UsageDetailsService } from "./services/usage-details.service";
import { PaymentPlanService } from "./services/payment-plan.service";
import { AccountDetailsService } from "./services/account-details.service";
import { MailBoxService } from "./services/mail-box.service";

// module
import { SelfServeComponentsModule } from "./components/components.module";
import { SelfServeContainersModule } from "./containers/containers.module";
import { CommomComponentModule } from "../common/commom-component.module";

import { TrackOrderService } from "./services/track-order.service";

// addons
import { AddonsService } from "./services/addons.service";

import { LoadingService } from "./services/loading.service";

@NgModule({
  imports: [
	CommonModule,
	CommomComponentModule,
	RouterModule,
	SelfServeComponentsModule,
	SelfServeContainersModule
  ],
  providers: [
	ProductService,
	UsageService,
	UsageDetailsService,
	BootService,
	PaymentPlanService,
	AccountDetailsService,
	TrackOrderService,
	MailBoxService,
	AddonsService,
	LoadingService
  ],
  declarations: [],
  exports: []
})
export class SelfServeModule {}
