import { Component, OnInit } from "@angular/core";
import { TealiumService } from "src/tealium/services/tealium.service";
import { PAGE_NOT_FOUND } from "src/tealium/enums/telium.enum";

@Component({
	selector: "app-page-not-found",
	templateUrl: "./page-not-found.component.html",
	styleUrls: ["./page-not-found.component.scss"]
})
export class PageNotFoundComponent implements OnInit {
	constructor(private tealiumService: TealiumService) { }
	ngOnInit() {
		const errorHttpDetails = {
			errorCode: PAGE_NOT_FOUND.ERROR_CODE,
			errorMessage: PAGE_NOT_FOUND.ERROR_MESSAGE,
			pageUrl: window.location.href,
			timestamp: new Date().toISOString()
		};
		this.tealiumService.trackHttpErrorPages(errorHttpDetails);
	}
}
