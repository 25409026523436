import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule, Route } from "@angular/router";

// components
import { ViewMyBillComponent } from "./view-my-bill.component";
import { PdfViewerModule } from "ng2-pdf-viewer";

const routes: Route[] = [
	{
		// /:encrypted_invoicenumber
		path: "view-bill", // view-bill
		component: ViewMyBillComponent,
		data: {
			breadcrumb: "View my Bill"
		}
	}
];
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		RouterModule.forChild(routes),
		PdfViewerModule
	],
	declarations: [ViewMyBillComponent]
})
export class ViewMyBillModule {}
