import { Component, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ISelectedPayment } from "src/app/self-serve/models/store.model";
import { AppStoreService } from "src/app/services/appStore.service";
import { IProductPaymentPlanExtPath } from "src/app/self-serve/models/product.model";
import { TealiumService } from "src/tealium/services/tealium.service";
import { PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES } from "./payment-amount-entry.enum";

@Component({
	selector: "dodo-payment-amount-entry",
	templateUrl: "./dodo-payment-amount-entry.component.html",
	styleUrls: ["./payment-amount-entry.component.scss"]
})
export class DodoPaymentAmountEntryComponent implements OnChanges {
	payment: ISelectedPayment;
	amountError = false;
	errorMsg = "";
	paymentAmount: string;
	public errInvalidAmount = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.AMT_MORE_THAN_ONE_DOLLAR_WITH_TWO_DECIMAL_PLACES;

	constructor(
		private router: Router,
		private appStoreService: AppStoreService, private tealiumService: TealiumService
	) { }
	@Input() isDataAvailable: boolean;
	@Input() flgmakePayforRegularBill: boolean;

	ngOnChanges(): void {
		this.payment = this.appStoreService.store.customer.payment;
		this.paymentAmount = +this.payment.amount < 0
			? "0"
			: this.payment.amount;
	}

	public continueToPaymentType(): void {
		this.payment.amount = this.paymentAmount;
		if (
			this.payment.amount.trim() !== "" &&
			(+this.payment.amount.trim() >= 1 && +this.payment.amount.trim() <= 9999)
		) {
			const productPaymentPlanExtPath = <IProductPaymentPlanExtPath>{};
			productPaymentPlanExtPath.paymentPlanExturl = "payment-amount-entry";
			productPaymentPlanExtPath.id = this.payment.selectedProduct.id;
			productPaymentPlanExtPath.isCheckedMakepayforRegularbill = this.flgmakePayforRegularBill;
			this.appStoreService.updateProductPaymentPlanExtPath(
				productPaymentPlanExtPath
			);

			this.appStoreService.updateDodoCustomerPayment(this.payment);
			this.router.navigate([
				"/make-a-payment/payment-type/",
				this.payment.selectedProduct.id
			]);
		}
	}

	public checkAmtRange(): void {
		const amount = this.paymentAmount;
		const accountBalance = this.payment.selectedProduct.accountBalance?.amount?.value;
		if (this.isDataAvailable === false) {
			if (!amount || +amount?.trim() < 1 && accountBalance > 0) {
				this.amountError = true;
				this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.AMT_MORE_THAN_ONE_DOLLAR_ERROR;
				this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
			} else if (+amount?.trim() > +accountBalance && accountBalance > 0) {
				this.amountError = true;
				this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.AMT_ABOVE_OUTSTANDING_BALANCE_ERROR;
				this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
			} else if (+amount?.trim() >= 0 && accountBalance <= 0) {
				this.amountError = true;
				this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.NO_OUTSTANDING_BALANCE_ERROR;
				this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
			} else {
				this.amountError = false;
			}
		} else { // no max amount checks if payment plan in progress for billit only
			if (!amount || +amount?.trim() < 1) {
				this.amountError = true;
				this.errorMsg = PAYMENT_AMOUNT_ENTRY_FLASH_MESSAGES.AMT_MORE_THAN_ONE_DOLLAR_ERROR;
				this.tealiumService.setFlashErrorMsg(this.errorMsg, undefined);
			} else {
				this.amountError = false;
			}
		}
	}

	public backToMakeAPayment(): void {
		this.router.navigate(["/make-a-payment/"]);
	}
}
