import { Component, OnInit } from "@angular/core";
import { environment } from "src/environment";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html"
})
export class FooterComponent implements OnInit {
  config = environment;
  constructor() { }
 ngOnInit() {
  }

}
