import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError as _throw } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { environment } from "src/environment";

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
	constructor() {

	}
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			// Skip `sent` event
			filter((e) => e.type !== 0),
			tap(
				(response: HttpEvent<any>) => {
					this.logToWindow(response);
				},
				(error: HttpErrorResponse) => {
					this.logToWindow(error, true);
				}
			)
		);
	}

	private logToWindow(
		response: HttpEvent<any> | HttpErrorResponse,
		isError = false
	): void {
		const id = window["utag_data"] ? window["utag_data"].tealium_visitor_id : "";
		if (!window["syslog"]) {
			window["syslog"] = [];
		}
		const log = {
			Brand: environment.brand,
			App: environment.appName,
			Env: environment.envName,
			ApiEndpoint: response["url"],
			Id: id ? id : "",
			APIResponseStatus: response["status"],
			TimeStamp: new Date().toISOString(),
			Error: "",
			PageURL: window.location.href
		};
		if (isError) {
			log.APIResponseStatus = response["status"] > 0 ? response["status"] : 500;
			log.Error = response["message"];
		}
		window["syslog"].push(log);
	}
}
