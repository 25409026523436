<section class="in-progress-payment-plans">
  <div
    class="_payment-plans-or-ext-progress-header py1"
    (window:resize)="onResize($event)"
  >
    Active Payment Plans / Extensions
  </div>
  <div *ngIf="loadingplan===false && isDataAvailable" class="__information">
    <div class="___disclaimer p1 mt2 font-brand">
      <i class="fal fa-exclamation-circle"></i>
      Your account currently has a Payment Plan active. If you would like to
      make a payment towards that plan, click ‘View Details’ button.
    </div>
  </div>
  <!-- Desktop and IPad View-->
  <section *ngIf="screenWidth > 767">
    <section
      class="_overview sm-hide"
      *ngIf="loadingplan===false && isDataAvailable; else desktopPreloader"
    >
      <header class="__content title">
        <div class="___item">
          <span class="table-heading-color-1 font-medium">Payment Option</span>
        </div>
        <div class="___item">
          <span class="table-heading-color-1 font-medium"
            >Remaining Balance</span
          >
        </div>
        <div class="___item">
          <span class="table-heading-color-1 font-medium"
            >Next Instalment Date</span
          >
        </div>
        <div class="___item">
          <span class="table-heading-color-1 font-medium"
            >Instalment Amount</span
          >
        </div>
        <div class="___item">
          <span class="table-heading-color-1 font-medium"></span>
        </div>
      </header>
      <section>
        <div
          class="__content"
          *ngIf="
            productSelected.payment &&
            (productSelected.payment.inProgressPlan | json) !== '{}'
          "
        >
          <div class="___item">
            <span class="paymentoption">
              {{ productSelected.payment.inProgressPlan.name }}
            </span>
          </div>
          <div class="___item price">
            <span class="balanceamount mb2" *ngIf="productSelected.payment.inProgressPlan.totalBalanceRemaining; else preload">
              ${{ productSelected.payment.inProgressPlan.totalBalanceRemaining | number: "0.2-2" }}
            </span>
            <ng-template #preload>
              <span class="amount">
                <img
                  src="assets/images/common/preloader.gif"
                  alt="preloader"
                  width="30px"
                  height="30px"
                />
              </span>
            </ng-template>
          </div>
          <div class="___item">
            <span class="paymentduedate">
              {{
                productSelected.payment.inProgressPlan.nextInstalmentDate
                  | date: "dd/MM/yyyy"
              }}
            </span>
          </div>
          <div class="___item">
            <span class="balanceamount">
              ${{
                productSelected.payment.inProgressPlan.instalmentAmount[0]
                  .value | number: "0.2-2"
              }}
            </span>
          </div>
          <div class="___item"> 
            <button
              class="btn brand-btn-info relative"
              (click)="productSelected.payment.inProgressPlan.showInprogressSummary = !productSelected
              .payment.inProgressPlan.showInprogressSummary;parent.flgmakePayforRegularBill = false"
            >
              <div *ngIf="!productSelected.payment.inProgressPlan.showInprogressSummary; else hideDetails">
                <span>View Details</span>
              </div>
              <ng-template #hideDetails>
                <span>Hide Details</span>
              </ng-template>
            </button>
          </div>
        </div>

        <div *ngIf="productSelected.payment.inProgressPlan.showInprogressSummary">
          <dodo-in-progress-summary
            [productSelected]="productSelected" [totalAmtRemaining] ="productSelected.payment.inProgressPlan.totalBalanceRemaining"
          ></dodo-in-progress-summary>        
        </div>
      </section>
    </section>
    <ng-template #desktopPreloader>
      <section
        class="_plan _card my5"
        *ngIf="!apiInProgressPaymentPlanExtError && isDataAvailable"
      >
        <div class="flex flex-align-items-center flex-justify-content-center">
          <img src="assets/images/common/preloader.gif" alt="preloader" />
        </div>
      </section>    
      <section
        class="_plan _card bg-white py3"
        *ngIf="apiInProgressPaymentPlanExtError && !isDataAvailable"
      >
        <div class="error txt-sm font-brand txt-color-error bg-color-error">
          <img
            src="assets/images/icons/icon-error-orange.svg"
            class="mr1"
          />Sorry! We you can't access Payment plan/extension at this time.
        </div>
      </section>
    </ng-template>
  </section>

  <!-- Mobile View -->
  <section *ngIf="screenWidth < 768">
    <section *ngIf="loadingplan===false && isDataAvailable; else mobilePreloader">
      <div
        class="mobile-box"
        *ngIf="
          productSelected.payment &&
          (productSelected.payment.inProgressPlan | json) !== '{}'
        "
      >
        <div
          class="__content sm-only"
          *ngIf="
            productSelected.payment &&
            (productSelected.payment.inProgressPlan | json) !== '{}'
          "
        >
          <div class="___item-Paymentoption">
            <span class="paymentoption font-semibold">Payment option</span>
          </div>
          <div class="___item-Paymentoption paymentplanandext">
            <span>{{ productSelected.payment.inProgressPlan.name }}</span>
          </div>
        </div>
        <div
          class="__content sm-only"
          *ngIf="
            productSelected.payment &&
            (productSelected.payment.inProgressPlan | json) !== '{}'
          "
        >
          <div class="___item-Paymentoption">
            <span class="paymentoption font-semibold">Remaining Balance</span>
          </div>
          <div class="___item-Paymentoption paymentplanandext">
            <span>${{ totalBalanceRemaining | number: "0.2-2" }} </span>
          </div>
        </div>
        <div
          class="__content sm-only"
          *ngIf="
            productSelected.payment &&
            (productSelected.payment.inProgressPlan | json) !== '{}'
          "
        >
          <div class="___item-DueDate">
            <span class="paymentduedatelabel font-semibold">
              Next Instalment Date
            </span>
          </div>
          <div class="___item-DueDate paymentduedate">
            <span>{{
              productSelected.payment.inProgressPlan.nextInstalmentDate
                | date: "dd/MM/yyyy"
            }}</span>
          </div>
        </div>

        <div
          class="__content sm-only"
          *ngIf="
            productSelected.payment &&
            (productSelected.payment.inProgressPlan | json) !== '{}'
          "
        >
          <div class="___item-DueDate">
            <span class="paymentduedatelabel font-semibold">
              Instalment Amount
            </span>
          </div>
          <div class="___item-DueDate paymentduedate">
            <span
              >${{
                productSelected.payment.inProgressPlan.instalmentAmount[0]
                  .value | number: "0.2-2"
              }}
            </span>
          </div>
        </div>
        <div
          class="__content sm-only"
          *ngIf="
            productSelected.payment &&
            (productSelected.payment.inProgressPlan | json) !== '{}'
          "
        >
          <div class="___item-button _buttons">
            <span> 
              <button
                class="btn brand-btn-info"
                (click)="productSelected.payment.inProgressPlan.showInprogressSummary = !productSelected
                .payment.inProgressPlan.showInprogressSummary;parent.flgmakePayforRegularBill = false"
              >
                <div *ngIf="!productSelected.payment.inProgressPlan.showInprogressSummary; else hideDetailsMobile">
                  <span>VIEW DETAILS</span>
                </div>
                <ng-template #hideDetailsMobile>
                  <span>HIDE DETAILS</span>
                </ng-template>
              </button>
            </span>
          </div>
        </div>
        <div *ngIf="productSelected.payment.inProgressPlan.showInprogressSummary">
          <dodo-in-progress-summary
            [productSelected]="productSelected" [totalAmtRemaining] ="productSelected.payment.inProgressPlan.totalBalanceRemaining"
          ></dodo-in-progress-summary>
        </div>
      </div>
    </section>
    <ng-template #mobilePreloader>
      <section
        class="_plan _card my5"
        *ngIf="!apiInProgressPaymentPlanExtError && isDataAvailable"
      >
        <div class="flex flex-align-items-center flex-justify-content-center">
          <img src="assets/images/common/preloader.gif" alt="preloader" />
        </div>
      </section>  
      <section
        class="_plan _card bg-white py3"
        *ngIf="apiInProgressPaymentPlanExtError && !isDataAvailable"
      >
        <div class="error txt-sm font-brand txt-color-error bg-color-error">
          <img
            src="assets/images/icons/icon-error-orange.svg"
            class="mr1"
          />Sorry! We you can't access Payment plan/extension at this time.
        </div>
      </section>
    </ng-template>
  </section>
</section>
