import { Injectable } from "@angular/core";
import { NavMenu } from "../../../assets/configs/navigation.config";
import { AppStoreService } from "src/app/services/appStore.service";
import { IProduct } from "../../self-serve/models/product.model";
import { Observable } from "rxjs";
import { environment } from "src/environment";

export interface ProductInfo {
	submenuTitle: string;
	submenuLinks?: Array<any>;
	submenuLinksTitle?: string;
	subtitle?: string;
	href?: any;
}

@Injectable({
	providedIn: "root"
})
export class NavService {
	products$: Observable<IProduct[]>;
	allProducts: IProduct[];
	mobile: any = {};
	energy: any = {};
	internet: any = {};
	fetch: any = {};
	homePhone: any = {};
	apiError = false;

	constructor(
		private appStoreService: AppStoreService
	) { }

	public buildProductsNav(): void {
		this.apiError = false;
		this.allProducts = [];

		if (this.appStoreService.store.products) {
			this.allProducts = this.appStoreService.store.products.filter(
				product => product.status !== "Cancelled" && product.status !== "Withdrawn"
			);
			this.createArray(this.allProducts);
		}
	}

	private createArray(allProducts): void {
		let hasenergy = false;
		const config = environment;
		allProducts.map(product => {
			if (product.productGroupType === "Energy") {
				hasenergy = true;
			}
		});
		if (hasenergy) {
			if (NavMenu[1].mainLinks[0].submenuLinks.length === 1) {
				NavMenu[1].mainLinks[0].submenuLinks.push({
					submenuLinksTitle: "Credit overview",
					href: "/credit-balance",
					external: "false",
					subtitle: ""
				});
			}

			if (NavMenu[2].mainLinks[4].submenuLinks.length === 1) {
				NavMenu[2].mainLinks[4].submenuLinks.push({
					submenuLinksTitle: "Concessions",
					href: config.websiteHostServer + "/energy/concessions",
					external: "true",
					subtitle: ""
				});
			}
		} else {
			if (NavMenu[1].mainLinks[0].submenuLinks.length > 1) {
				NavMenu[1].mainLinks[0].submenuLinks.pop();
			}
			if (NavMenu[2].mainLinks[4].submenuLinks.length > 1) {
				NavMenu[2].mainLinks[4].submenuLinks.pop();
			}
		}

		NavMenu[0].mainLinks = [];
		NavMenu[0].mainLinks.length = 0;
		const configMainLinks = [];
		this.mobile.submenuTitle = "Mobile";
		this.internet.submenuTitle = "Internet";
		this.energy.submenuTitle = "Energy";
		this.fetch.submenuTitle = "Fetch";
		this.homePhone.submenuTitle = "Home Phone";

		this.mobile.submenuLinks = [];
		this.internet.submenuLinks = [];
		this.energy.submenuLinks = [];
		this.fetch.submenuLinks = [];
		this.homePhone.submenuLinks = [];
		const productlink = "/dashboard/product/";

		allProducts.map(ele => {
			if (ele.productGroupType === "Mobile") {
				this.mobile.submenuLinks.push({
					submenuLinksTitle: ele.name,
					subtitle: ele.services[0].uniqueId,
					href: productlink + ele.id
				});
			} else if (ele.productGroupType === "Internet") {
				this.internet.submenuLinks.push({
					submenuLinksTitle: ele.name,
					subtitle: ele.services[0].uniqueId,
					href: productlink + ele.id
				});
			} else if (ele.productGroupType === "Homephone") {
				this.homePhone.submenuLinks.push({
					submenuLinksTitle: ele.name,
					subtitle: ele.services[0].uniqueId,
					href: productlink + ele.id
				});
			} else if (ele.productGroupType === "Energy") {
				this.energy.submenuLinks.push({
					submenuLinksTitle: ele.name,
					subtitle: ele.services[0].uniqueId,
					href: productlink + ele.id
				});
			} else if (ele.productGroupType === "Fetch") {
				this.fetch.submenuLinks.push({
					submenuLinksTitle: ele.name,
					subtitle: ele.services[0].uniqueId,
					href: productlink + ele.id
				});
			}
		});

		if (this.mobile.submenuLinks.length > 0) { configMainLinks.push(this.mobile); }

		if (this.internet.submenuLinks.length > 0) {
			configMainLinks.push(this.internet);
		}

		if (this.homePhone.submenuLinks.length > 0) {
			configMainLinks.push(this.homePhone);
		}

		if (this.energy.submenuLinks.length > 0) { configMainLinks.push(this.energy); }

		if (this.fetch.submenuLinks.length > 0) { configMainLinks.push(this.fetch); }

		NavMenu[0].mainLinks = configMainLinks;
	}
}
