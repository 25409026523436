import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// rxjs
import { Observable, of } from "rxjs";

// libraries
import { HttpService } from "../../../libraries/api/services/http.service";

// services
import { ITrackOrder } from "../models/track-order.model";

import { HttpHeaders } from "@angular/common/http"; // tracking order
import * as moment from "moment";  // tracking order
import { environment } from "src/environment";  // tracking order

@Injectable()
export class TrackOrderService {

	constructor(
		private httpClient: HttpClient,
		private httpService: HttpService
	) { }

	/**
	 * returns an object with all the data coming from the backend api
	 *
	 * @param orderId - order id
	 * @returns - object with all the data coming from the backend api
	 */
	getServiceOrderStatus(orderId): Observable<ITrackOrder[]> {
		const url = `${environment.apiURL}/api/v1/serviceOrderMgmt/serviceOrder/${orderId}`;
		// const url = `${this.appConfig.apiURL}/api/v1/serviceOrderMgmt/serviceOrder?sfAccount=${sfAccountId}`;
		return this.httpClient.get<ITrackOrder[]>(url);

		// let mockOrderStatus = [];

		/* order status Completed */
		/*mockOrderStatus = [
			{
			  "id": "8011s000000RZdtAAG",
			  "category": "NBN Connect",
			  "state": "Completed",
			//   "state": "In Progress",
			  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			  "requestedStartDate": new Date("2020-11-12"),
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "Reschedule required"
				},
				{
				  "name": "Nbn Order Status",
				  "value": ""
				},
				{
				  "name": "Notification Type",
				  "value": ""
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://toll.com?trackingNumber=123456"
				},
				{
				  "name": "notfication context",
				  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
	}
			  ],
			  "orderItem": [ {
				"id": "8021s000000QxF2AAK",
				"state": "In Progress",
				"service": {
				  "characteristic": [
					  {
						  "name": "Plan Name",
						  "value": "NBN 50"
					  },
					  {
						  "name": "Self Install",
						  "value": "false"
					  }
				  ]
				},
				"place": [
				  {
					  "id": "LOC260100000857",
					  "role": "Service Location"
				  }
				],
				"appointment": [
				  {
					  "id": "",
					  "timeSlot": {
						  "validFor": {
							  "startDateTime": new Date(),
							  "endDateTime": new Date()
						  },
						  "characteristic": [
							  {
								  "name": "appointmentSlotType",
								  "value": "AM"
							  }
						  ]
					  },
					  "status": "RESERVED",
					  "characteristic": [
						  {
							"name": "count",
							"value": "1"
						  }
					  ]
				  }
				]
			  } ]
			}
		  ];*/

				/* order status Created */
		/*mockOrderStatus = [
			{
			  "id": "8011s000000RZdtAAG",
			  "category": "NBN Connect",
			  "state": "Order Created",
			//   "state": "In Progress",
			  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			  "requestedStartDate": new Date("2020-11-12"),
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "Reschedule required"
				},
				{
				  "name": "Nbn Order Status",
				  "value": ""
				},
				{
				  "name": "Notification Type",
				  "value": ""
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://toll.com?trackingNumber=123456"
				},
				{
				  "name": "notfication context",
				  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
				}
			  ],
			  "orderItem": [ {
				"id": "8021s000000QxF2AAK",
				"state": "In Progress",
				"service": {
				  "characteristic": [
					  {
						  "name": "Plan Name",
						  "value": "NBN 50"
					  },
					  {
						  "name": "Self Install",
						  "value": "false"
					  }
				  ]
				},
				"place": [
				  {
					  "id": "LOC260100000857",
					  "role": "Service Location"
				  }
				],
				"appointment": [
				  {
					  "id": "",
					  "timeSlot": {
						  "validFor": {
							  "startDateTime": new Date(),
							  "endDateTime": new Date()
						  },
						  "characteristic": [
							  {
								  "name": "appointmentSlotType",
								  "value": "AM"
							  }
						  ]
					  },
					  "status": "RESERVED",
					  "characteristic": [
						  {
							"name": "count",
							"value": "1"
						  }
					  ]
				  }
				]
			  } ]
			}
		  ];*/

		/* order status In Progress case scheduled*/
		/*mockOrderStatus = [
			{
			  "id": "8011s000000RZdtAAG",
			  "category": "NBN Connect",
			  "state": "In Progress",
			  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			  "requestedStartDate": new Date("2020-11-12"),
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "Reschedule required"
				},
				{
				  "name": "Nbn Order Status",
				  "value": ""
				},
				{
				  "name": "Notification Type",
				  "value": ""
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://toll.com?trackingNumber=123456"
				},
				{
				  "name": "notfication context",
				  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
				}
			  ],
			  "orderItem": [{
				"id": "8021s000000QxF2AAK",
				"state": "In Progress",
				"service": {
				  "characteristic": [
					  {
						  "name": "Plan Name",
						  "value": "NBN 50"
					  },
					  {
						  "name": "Service Trasfer Flag",
						  "value": "false"
					  },
					  {
						  "name": "Self Install",
						  "value": "false"
					  }
				  ]
				},
				"place": [
				  {
					  "id": "LOC260100000857",
					  "role": "Service Location"
				  }
				],
				"appointment": [
				  {
					  "id": "APT772450601264",
					  "timeSlot": {
						  "validFor": {
							  "startDateTime": new Date(),
							  "endDateTime": new Date()
						  },
						  "characteristic": [
							  {
								  "name": "appointmentSlotType",
								  "value": "AM"
							  }
						  ]
					  },
					  "status": "RESERVED",
					  "characteristic": [
						  {
							"name": "count",
							"value": "1"
						  }
					  ]
				  }
				]
			  }]
			}
		  ];*/

		/* order status In Progress case: transfer */
		/*mockOrderStatus = [
			{
			  "id": "8011s000000RZdtAAG",
			  "category": "NBN Connect",
			  "state": "In Progress",
			  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			  "requestedStartDate": new Date("2020-11-12"),
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "Reschedule required"
				},
				{
				  "name": "Nbn Order Status",
				  "value": ""
				},
				{
				  "name": "Notification Type",
				  "value": ""
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://toll.com?trackingNumber=123456"
				},
				{
				  "name": "notfication context",
				  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
				}
			  ],
			  "orderItem": [{
				"id": "8021s000000QxF2AAK",
				"state": "In Progress",
				"service": {
				  "characteristic": [
					  {
						  "name": "Plan Name",
						  "value": "NBN 50"
					  },
					  {
						  "name": "Service Trasfer",
						  "value": "true"
					  },
					  {
						  "name": "Self Install",
						  "value": "false"
					  }
				  ]
				},
				"place": [
				  {
					  "id": "LOC260100000857",
					  "role": "Service Location"
				  }
				],
				"appointment": [
				  {
					  "id": "",
					  "timeSlot": {
						  "validFor": {
							  "startDateTime": new Date(),
							  "endDateTime": new Date()
						  },
						  "characteristic": [
							  {
								  "name": "appointmentSlotType",
								  "value": "AM"
							  }
						  ]
					  },
					  "status": "RESERVED",
					  "characteristic": [
						  {
							"name": "count",
							"value": "1"
						  }
					  ]
				  }
				]
			  }]
			}
		  ];*/

		/* order status In Progress case : install flag */
		/*mockOrderStatus = [
		   {
			 "id": "8011s000000RZdtAAG",
			 "category": "NBN Connect",
			 "state": "In Progress",
			 "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			 "requestedStartDate": new Date("2020-11-12"),
			 "characteristics": [
			   {
				 "name": "Customer Input Required",
				 "value": "Reschedule required"
			   },
			   {
				 "name": "Nbn Order Status",
				 "value": ""
			   },
			   {
				 "name": "Notification Type",
				 "value": ""
			   },
			   {
				 "name": "Shipment Tracking",
				 "value": "AWVE725891"
			   },
			   {
				 "name": "Shipment Carrier",
				 "value": "Toll"
			   },
			   {
				 "name": "Shipment Tracking Link",
				 "value": "http://toll.com?trackingNumber=123456"
			   },
			   {
				 "name": "notfication context",
				 "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
			   }
			 ],
			 "orderItem": [{
			   "id": "8021s000000QxF2AAK",
			   "state": "In Progress",
			   "service": {
				 "characteristic": [
					 {
						 "name": "Plan Name",
						 "value": "NBN 50"
					 },
					 {
						 "name": "Service Trasfer Flag",
						 "value": "false"
					 },
					 {
						 "name": "Self Install",
						 "value": "true"
					 }
				 ]
			   },
			   "place": [
				 {
					 "id": "LOC260100000857",
					 "role": "Service Location"
				 }
			   ],
			   "appointment": [
				 {
					 "id": "asasasa",
					 "timeSlot": {
						 "validFor": {
							 "startDateTime": new Date(),
							 "endDateTime": new Date()
						 },
						 "characteristic": [
							 {
								 "name": "appointmentSlotType",
								 "value": "AM"
							 }
						 ]
					 },
					 "status": "RESERVED",
					 "characteristic": [
						 {
						   "name": "count",
						   "value": "1"
						 }
					 ]
				 }
			   ]
			 }]
		   }
		 ];*/

		/* order status In Progress case 4*/
		/*mockOrderStatus = [
			{
			  "id": "8011s000000RZdtAAG",
			  "category": "NBN Connect",
			  "state": "In Progress",
			  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			  "requestedStartDate": new Date("2020-11-12"),
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "CustomerToConnectDevices"
				},
				{
				  "name": "Nbn Order Status",
				  "value": ""
				},
				{
				  "name": "Notification Type",
				  "value": ""
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://toll.com?trackingNumber=123456"
				},
				{
				  "name": "notfication context",
				  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
				}
			  ],
			  "orderItem": [{
				"id": "8021s000000QxF2AAK",
				"state": "In Progress",
				"service": {
				  "characteristic": [
					  {
						  "name": "Plan Name",
						  "value": "NBN 50"
					  },
					  {
						  "name": "Service Trasfer Flag",
						  "value": "true"
					  },
					  {
						  "name": "Self Install",
						  "value": "false"
					  }
				  ]
				},
				"place": [
				  {
					  "id": "LOC260100000857",
					  "role": "Service Location"
				  }
				],
				"appointment": [
				  {
					  "id": "",
					  "timeSlot": {
						  "validFor": {
							  "startDateTime": new Date(),
							  "endDateTime": new Date()
						  },
						  "characteristic": [
							  {
								  "name": "appointmentSlotType",
								  "value": "AM"
							  }
						  ]
					  },
					  "status": "RESERVED",
					  "characteristic": [
						  {
							"name": "count",
							"value": "1"
						  }
					  ]
				  }
				]
			  }]
			}
		  ];*/

		/* order status In Progress case Reschedule NBN Appointment*/
		/*mockOrderStatus = [
		  {
			"id": "8011s000000RZdtAAG",
			"category": "NBN Connect",
			"state": "In Progress",
			"orderDate": new Date("2020-11-13T00:22:17.000Z"),
			"requestedStartDate": new Date("2020-11-12"),
			"characteristics": [
			  {
				"name": "Customer Input Required",
				"value": "InformRescheduleAppointment"
			  },
			  {
				"name": "Nbn Order Status",
				"value": ""
			  },
			  {
				"name": "Notification Type",
				"value": ""
			  },
			  {
				"name": "Shipment Tracking",
				"value": "AWVE725891"
			  },
			  {
				"name": "Shipment Carrier",
				"value": "Toll"
			  },
			  {
				"name": "Shipment Tracking Link",
				"value": "http://toll.com?trackingNumber=123456"
			  },
			  {
				"name": "notfication context",
				"value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
			  }
			],
			"note": [
			  {
				"system": "mydodo",
				"text": "Oops, it appears you were unavailable for your scheduled appointment, please make another appointment at a more suitable time.",
				"type": "content"
			  }
			],
			"orderItem": [{
			  "id": "8021s000000QxF2AAK",
			  "state": "In Progress",
			  "service": {
				"characteristic": [
					{
						"name": "Plan Name",
						"value": "NBN 50"
					},
					{
						"name": "Service Trasfer Flag",
						"value": "false"
					},
					{
						"name": "Self Install",
						"value": "false"
					}
				]
			  },
			  "place": [
				{
					"id": "LOC260100000857",
					"role": "Service Location"
				}
			  ],
			  "appointment": [
				{
					"id": "",
					"timeSlot": {
						"validFor": {
							"startDateTime": new Date(),
							"endDateTime": new Date()
						},
						"characteristic": [
							{
								"name": "appointmentSlotType",
								"value": "AM"
							}
						]
					},
					"status": "RESERVED",
					"characteristic": [
						{
						  "name": "count",
						  "value": "1"
						}
					]
				}
			  ]
			}]
		  }
		];*/

		/* order status In Progress case : Await For Further Updates From NBN*/
		//  mockOrderStatus = [
		// 	{
		// 	  "id": "8011s000000RZdtAAG",
		// 	  "category": "NBN Connect",
		// 	  "state": "In Progress",
		// 	  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
		// 	  "requestedStartDate": new Date("2020-11-12"),
		// 	  "expectedCompletionDate": "2000-01-01",
		// 	  "characteristics": [
		// 		{
		// 		  "name": "Customer Input Required",
		// 		  "value": "AwaitForFurtherUpdatesFromNBN"
		// 		},
		// 		{
		// 		  "name": "Nbn Order Status",
		// 		  "value": ""
		// 		},
		// 		{
		// 		  "name": "Notification Type",
		// 		  "value": ""
		// 		},
		// 		{
		// 		  "name": "Shipment Tracking",
		// 		  "value": "AWVE725891"
		// 		},
		// 		{
		// 		  "name": "Shipment Carrier",
		// 		  "value": "Toll"
		// 		},
		// 		{
		// 		  "name": "Shipment Tracking Link",
		// 		  "value": "http://toll.com?trackingNumber=123456"
		// 		},
		// 		{
		// 		  "name": "notfication context",
		// 		  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
		// 		}
		// 	  ],
		// 	  "orderItem": [{
		// 		"id": "8021s000000QxF2AAK",
		// 		"state": "In Progress",
		// 		"service": {
		// 		  "characteristic": [
		// 			  {
		// 				  "name": "Plan Name",
		// 				  "value": "NBN 50"
		// 			  },
		// 			  {
		// 				  "name": "Service Trasfer Flag",
		// 				  "value": "false"
		// 			  },
		// 			  {
		// 				  "name": "Self Install",
		// 				  "value": "false"
		// 			  }
		// 		  ]
		// 		},
		// 		"place": [
		// 		  {
		// 			  "id": "LOC260100000857",
		// 			  "role": "Service Location"
		// 		  }
		// 		],
		// 		"appointment": [
		// 		  {
		// 			  "id": "",
		// 			  "timeSlot": {
		// 				  "validFor": {
		// 					  "startDateTime": new Date(),
		// 					  "endDateTime": new Date()
		// 				  },
		// 				  "characteristic": [
		// 					  {
		// 						  "name": "appointmentSlotType",
		// 						  "value": "AM"
		// 					  }
		// 				  ]
		// 			  },
		// 			  "status": "RESERVED",
		// 			  "characteristic": [
		// 				  {
		// 					"name": "count",
		// 					"value": "1"
		// 				  }
		// 			  ]
		// 		  }
		// 		]
		// 	  }]
		// 	}
		//   ];

		/* order status In Progress case ProofOfOccupancyRequired*/
		/*mockOrderStatus = [
			{
			  "id": "8011s000000RZdtAAG",
			  "category": "NBN Connect",
			  "state": "In Progress",
			  "orderDate": new Date("2020-11-13T00:22:17.000Z"),
			  "requestedStartDate": new Date("2020-11-12"),
			  "expectedCompletionDate": null,
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "ProofOfOccupancyRequired"
				},
				{
				  "name": "Nbn Order Status",
				  "value": ""
				},
				{
				  "name": "Notification Type",
				  "value": ""
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://toll.com?trackingNumber=123456"
				},
				{
				  "name": "notfication context",
				  "value": "We'll be in touch soon to let you know what's next to get you connected.\nHere's some more order information"
				}
			  ],
			  "orderItem": [{
				"id": "8021s000000QxF2AAK",
				"state": "In Progress",
				"service": {
				  "characteristic": [
					  {
						  "name": "Plan Name",
						  "value": "NBN 50"
					  },
					  {
						  "name": "Service Trasfer Flag",
						  "value": "false"
					  },
					  {
						  "name": "Self Install",
						  "value": "false"
					  }
				  ]
				},
				"place": [
				  {
					  "id": "LOC260100000857",
					  "role": "Service Location"
				  }
				],
				"appointment": [
				  {
					  "id": "",
					  "timeSlot": {
						  "validFor": {
							  "startDateTime": new Date(),
							  "endDateTime": new Date()
						  },
						  "characteristic": [
							  {
								  "name": "appointmentSlotType",
								  "value": "AM"
							  }
						  ]
					  },
					  "status": "RESERVED",
					  "characteristic": [
						  {
							"name": "count",
							"value": "1"
						  }
					  ]
				  }
				]
			  }]
			}
		  ];*/

		/*mockOrderStatus = [
			{
			  "id": "8011s000000RfIuAAK",
			  "category": "NBN Connect",
			  "state": "In Progress",
			  "orderDate": "2020-11-30T05:32:39.000Z",
			  "requestedStartDate": null,
			  "characteristics": [
				{
				  "name": "Customer Input Required",
				  "value": "NoTreatmentRequired"
				},
				{
				  "name": "Nbn Order Status",
				  "value": "inProgress"
				},
				{
				  "name": "Notification Type",
				  "value": "NoTreatmentRequired"
				},
				{
				  "name": "Shipment Tracking",
				  "value": "AWVE725891"
				},
				{
				  "name": "Shipment Carrier",
				  "value": "Toll"
				},
				{
				  "name": "Shipment Tracking Link",
				  "value": "http://Toll.com?trackingNumber=AWVE725891"
				}
			  ],
			  "note": [
				{
				  "system": "mydodo",
				  "text": null,
				  "type": "content"
				}
			  ],
			  "orderItem": [
				{
				  "id": "8021s000000RaV4AAK",
				  "state": "In Progress",
				  "service": {
					"characteristic": [
					  {
						"name": "Plan Name",
						"value": null
					  },
					  {
						"name": "Self Install",
						"value": "false"
					  },
					  {
						"name": "Total Price",
						"value": "75.0"
					  },
					  {
						"name": "Service Transfer",
						"value": "false"
					  }
					]
				  },
				  "place": [
					{
					  "id": null,
					  "role": "Service Location",
					  "name": "917 LION WAY CANTERBURY VIC 3126"
					}
				  ],
				  "appointment": [
					{
					  "id": null,
					  "timeSlot": {
						"validFor": {
						  "startDateTime": "",
						  "endDateTime": ""
						},
						"characteristic": [
						  {
							"name": "appointmentSlotType",
							"value": null
						  }
						]
					  },
					  "status": null,
					  "characteristic": [
						{
						  "name": "count",
						  "value": null
						}
					  ]
					}
				  ]
				}
			  ]
			}
		  ];*/

		/*mockOrderStatus = [
			{
				"id": "8011s000000ROG7AAO",
				"category": "NBN Connect",
				"state": "In Progress",
				"orderDate": "2020-10-31T07:26:24.000Z",
				"requestedStartDate": "2020-11-15",
				"expectedCompletionDate": null,
				"characteristics": [
					{
						"name": "CanModifyConnectionRequestDate",	// new characteristic - rule: if state="Held" requestStartDate > today
						"value": "true"
					},
					{
						"name": "Customer Input Required",
						"value": "ProofOfOccupancyRequired"
					},
					{
						"name": "Nbn Order Status",
						"value": null
					},
					{
						"name": "Notification Type",
						"value": null
					},
					{
						"name": "Shipment Tracking",
						"value": "AWVE725891"
					},
					{
						"name": "Shipment Carrier",
						"value": "Toll"
					},
					{
						"name": "Shipment Tracking Link",
						"value": "http://Toll.com?trackingNumber=AWVE725891"
					}
				],
				"relatedParty": [
					{
						"id": "0011s00000PP2VkAAL",
						"role": "Customer Account"
					}
				],
				"note": [
					{
						"system": "mydodo",
						"text": null,
						"type": "content"
					}
				],
				"orderItem": [
					{
						"id": "8021s000000KxpZAAS",
						"state": "In Progress",
						"service": {
							"characteristic": [
								{
									"name": "Plan Name",
									"value": null
								},
								{
									"name": "Self Install",
									"value": "false"
								},
								{
									"name": "Total Price",
									"value": "75.0"
								},
								{
									"name": "Service Transfer",
									"value": "false"
								}
							]
						},
						"place": [
							{
								"id": "LOC260100000507",
								"role": "Service Location",
								"name": "37 LAWN WAY CANTERBURY VIC 3126"
							}
						],
						"appointment": [
							{
								"id": null,
								"timeSlot": {
									"validFor": {
										"startDateTime": "",
										"endDateTime": ""
									},
									"characteristic": [
										{
											"name": "appointmentSlotType",
											"value": null
										}
									]
								},
								"status": null,
								"characteristic": [
									{
										"name": "count",
										"value": null
									}
								]
							}
						]
					}
				]
			}
		];*/

	}
		// return of(mockOrderStatus);

	patchServiceOrder(orderId, orderItemId, data): Observable<any> {

		const url = `${environment.apiURL}/api/v1/serviceOrderMgmt/serviceOrder/${orderId}/orderItem/${orderItemId}`;

		return this.httpClient.patch<any>(url, data);

	}

	/**
	 * Calls Order history API
	 *
	 * @remarks
	 * Calls Order history API
	 *
	 * @param productId - receives product id
	 * @returns - object from API
	 */
	getOrderHistory(productId) {
		const url = `${environment.apiURL}/api/v1/serviceOrderMgmt/serviceOrder?subscriptionNumber=${productId}`;
		return this.httpClient.get<ITrackOrder[]>(url);
	}

	/**
	 * returns object from API
	 *
	 * @remarks
	 * Calls Service Order Status API
	 *
	 * @param productId - receives product id
	 * @returns - object from API
	 */
	getDodoServiceOrderStatus(productId): Observable<ITrackOrder[]> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/serviceordermgmt/serviceorder?productId=${productId}`;
		return this.httpClient.get<ITrackOrder[]>(url);
	}

	patchActivateSim(customerId, serviceId, data): Observable<any> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/activationmgmt/product/${customerId}/service/${serviceId}`;
		return this.httpClient.patch<any>(url, data);
	}

	// **** UnAuth to get Order Status for selected Product
	getServiceOrderStatusUnAuth(productId): Observable<ITrackOrder[]> {
		const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/serviceordermgmt/serviceorder?productId=${productId}`;
		return this.httpClient.get<ITrackOrder[]>(url);
	}

	/**
	 * returns slots for the appointment based on location
	 *
	 * @remarks
	 * Calls slots appointment API
	 *
	 * @param currentAppointment - current appointment
	 * @param locationId - location id
	 * @returns - slots for the appointment based on location
	 */
	getSlotsAppointment(
		priorityAssist: any,
		demandType: any,
		appointmentSLA: any,
		locationId: any,
		timeZone: any,
		startDate,
		endDate
	): Observable<any> {
		const _url = `${environment.apiURL}/api/v1/appointmentmgmt/searchTimeSlot?place.id=${locationId}&priorityAssist=${priorityAssist}&startDateTime=${startDate + "T00:00:00Z"}&endDateTime=${endDate + "T13:00:00Z"}&demandType=${demandType}&appointmentSLA=${appointmentSLA}&timeZone=${timeZone}`;
		return this.httpService.get<any[]>(_url);
	}

	/**
	 * Calls get service API
	 *
	 * @remarks
	 * Calls get service API
	 *
	 * @param appId - application id
	 * @returns
	 */
	getAppointment(
		appId: any
	): Observable<any[]> {

		console.log("passed MAIN appId:", appId);
		const _url = `${environment.apiURL}/api/v1/appointmentmgmt/appointment/${appId}`;

		return this.httpService.get<any[]>(_url);
	}

	/**
	 * returns whether the patch was successful or not
	 *
	 * @remarks
	 * Calls reschedule appointment
	 *
	 * @param currentCid - current cid
	 * @param data - payload
	 * @returns - the response whether the patch was successful or not
	 */
	rescheduleAppointment(currentCid, data) {

		const url = `${environment.apiURL}/api/v1/appointmentmgmt/appointment/${currentCid}`;
		return this.httpService.patch(url, data);
	}

	/**
	 * response whether the patch was successful or not
	 *
	 * @param orderId - orer id
	 * @param orderItemId - orer item id
	 * @param data - payload
	 * @returns - response whether the patch was successful or not
	 */
	changeCRD(orderId, orderItemId, data) {

		const url = `${environment.apiURL}/api/v1/serviceOrderMgmt/serviceOrder/${orderId}/orderItem/${orderItemId}`;
		return this.httpService.patch(url, data);
	}

}
