import { Component, OnInit } from "@angular/core";
import { Auth0Service } from "../../../../auth0/auth0.service";
import { AppStoreService, AppStore } from "src/app/services/appStore.service";
import { ICustomer } from "src/app/self-serve/models/store.model";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";
import { IMfaNotDPGonlyMigrate } from "src/app/self-serve/models/mfa-contactmedium.model";
@Component({
	selector: "app-mfa-billit",
	templateUrl: "./mfa-billit.component.html"
})
export class MfaBillitComponent implements OnInit {
	_customer: ICustomer;
	public _notDPGonlyNewEmail: string;
	public _errorMessage: string;
	isEmailValid: boolean;
	isPostSuccessful: boolean;
	public _appStore: AppStore;

	constructor(
		private __auth0Service: Auth0Service,
		public __appStoreService: AppStoreService,
		private authenticationService: AuthenticationService,
		private router: Router
	) { }

	ngOnInit() {
		this._appStore = this.__appStoreService.store;
		this._customer = this._appStore.customer;
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		this.isEmailValid = emailRegex.test(this._customer.email);
		this._notDPGonlyNewEmail = this._appStore?.mfaNotDPGonlyMigrate?.notDPGonlyNewEmail;
		if (!this._customer.email_verified && this.isEmailValid) {
			this.router.navigate(["/login-email"]);
		} else if (!this._customer.email_verified && !this.isEmailValid) {
			if (this._notDPGonlyNewEmail !== undefined && this._customer?.email !== undefined && this._customer.username !== undefined && this._customer.auth0Sub !== undefined) {
				this.getNotDPGonlyAuth0Email();
			} else {
				this.router.navigate(["/auth0-mfa/mfa-error"]);
			}
		}

	}

	getNotDPGonlyAuth0Email() {
		this.__auth0Service.submitAuth0EmailMigrate(this._notDPGonlyNewEmail, this._customer.username, this._customer.auth0Sub).subscribe(
			() => {
				this.isPostSuccessful = true;
				this.__auth0Service.resetAuth0Tokens();
				this.authenticationService.LoggedOut();
			},
			error => {
				this.isPostSuccessful = false;
				const mfaNotDPGonlyMigrate = <IMfaNotDPGonlyMigrate>{};
				mfaNotDPGonlyMigrate.notDPGonlyNewEmail = this._notDPGonlyNewEmail;
				if (error.error.message) {
					mfaNotDPGonlyMigrate.errorMessage = error.apiError.message;
					this.__appStoreService.storeMfaNotDPGonlyErrMsg(mfaNotDPGonlyMigrate);
					this.router.navigate(["/login-email"]);
				} else {
					mfaNotDPGonlyMigrate.errorMessage = error.apiError.message;
					this.__appStoreService.storeMfaNotDPGonlyErrMsg(mfaNotDPGonlyMigrate);
					this.router.navigate(["/login-email"]);
				}
			}
		);
	}
}
