import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { IProduct } from "src/app/self-serve/models/product.model";
import { AppStoreService } from "src/app/services/appStore.service";

import { IProductPaymentPlanExtPath } from "src/app/self-serve/models/product.model";
import { Router } from "@angular/router";
import { ISelectedPayment } from "src/app/self-serve/models/store.model";
import { AppStore, APP_EVENT } from "src/app/services/appStore.service";
import { filter } from "rxjs/operators";
import { IPaymentPlanInProgressSummary } from "src/app/self-serve/models/payment-plan.model";
import { IPaymentMethod } from "src/app/self-serve/models/payment.model";
import { PaymentService } from "src/app/self-serve/services/payment.service";
@Component({
  selector: "dodo-in-progress-summary",
  templateUrl: "./in-progress-summary.component.html"
})
export class InProgressSummaryComponent implements OnChanges, OnInit {
  constructor(
	private appStoreService: AppStoreService,
	private router: Router,
	private paymentService: PaymentService
  ) { }
  flgmakePayforInstalmentPlan = false;
  InstalmentAmttoPay = 0;
  productselectid: string;

  payment: ISelectedPayment = undefined;
  productId: any;
  public _appStore: AppStore;
  products: IProduct[];
  index: any;
  checkedPayments = [];
  public disabledValue: boolean;
  @Input() productSelected: IProduct;
  @Input() totalAmtRemaining;
  btnPayNow = false;
  btnProloader = false;
  public instalment_paid = 0;
  public paymentPlanExturl: string;
  preferredPayment: IPaymentMethod;
  preferredPaymenttypeFlg = false;
  isOnePlatform = false;

  ngOnChanges(changes: SimpleChanges) {
	this.disabledValue = false;
	if (changes["productSelected"]) {
		if (changes.productSelected.currentValue) {
		this.productSelected = changes.productSelected.currentValue;
		this.productselectid = this.productSelected.id;
		this.isOnePlatform = this.productSelected.bss === "shelley";

		if (!this.isOnePlatform) {
			this.paymentService.getDodoPayment(this.productselectid).subscribe(methods => {
				this.preferredPayment = this.paymentService.getPreferredtMethod(
				methods.paymentMethods
				);
				if (this.preferredPayment.preferred && (this.preferredPayment.type === "Credit Card" || this.preferredPayment.type === "Direct Debit")) {
				this.preferredPaymenttypeFlg = true;
				}
			});
		}
		// keeping all false as selected and only first enabled.
		if (this.productSelected.payment.inProgressPlanSummary !== undefined) {
			for (
			let i = 0;
			i < this.productSelected.payment.inProgressPlanSummary.length;
			i++
			) {
			this.productSelected.payment.inProgressPlanSummary[
				i
			].selected = false;
			if (this.appStoreService.store.summaryFlg) {
				if (this.productSelected.payment.inProgressPlanSummary[
				i
				].isChecked) {
				this.productSelected.payment.inProgressPlanSummary[i].disabled = false;
				this.btnPayNow = true;
				}
			} else {
				this.productSelected.payment.inProgressPlanSummary[0].disabled = false;
				if (this.productSelected.payment.inProgressPlanSummary[
				i
				].isChecked) {
				this.productSelected.payment.inProgressPlanSummary[i].disabled = false;
				this.btnPayNow = true;
				}
			}
			}

		}
		}
	}
  }
  ngOnInit() {
	this.instalment_paid = 0;
  }

  getIconPath(icon): string {
	if (icon === "Homephone") {
		icon = "home-phone";
	}
	return `assets/images/icons/icon-${icon.toLowerCase()}.svg`;
  }

  continueToPayInstalments() {
	const productId = this.productselectid; // captured onchange from summary listing
	this._appStore = this.appStoreService.store;
	this.payment = <ISelectedPayment>{};

	this.InstalmentAmttoPay = 0;
	this.instalment_paid = 0;
	for (
		let i = 0;
		i < this.productSelected.payment.inProgressPlanSummary.length;
		i++
	) {
		if (this.productSelected.payment.inProgressPlanSummary[
		i
		].isChecked) {
		this.InstalmentAmttoPay = this.InstalmentAmttoPay + this.productSelected.payment.inProgressPlanSummary[
			i
		].amount.value;
		this.instalment_paid = this.instalment_paid + 1;
		}
	}

	// Show the stored payment data
	if (
		this._appStore.customer.payment &&
		+this._appStore.customer.payment.amount > 0 &&
		this._appStore.customer.payment.selectedProduct.id === productId
	) {
		this.payment = this._appStore.customer.payment;
		this.payment.amount = this.InstalmentAmttoPay.toString();
		this.payment.instalment_paid = this.instalment_paid.toString();
	} else {
		// Show direct from product
		// or refresh of page
		// or from make a payment page
		// this.payment = this._appStore.customer.payment;

		if (!this.payment.selectedProduct) {
		this.payment.selectedProduct = this.getProduct(productId);
		if (!this.payment.amount) {
			// This value has to be dynamic based on user selection so
			this.payment.amount = this.InstalmentAmttoPay.toString();
			this.payment.instalment_paid = this.instalment_paid.toString();
		}
		this._appStore.customer.payment = { ...this.payment };
		this.appStoreService.updateDodoCustomerPayment(this.payment);
		}
	}

	// if update payment is done, then go for
	this.payment = this.appStoreService.store.customer.payment;
	this.appStoreService.storeUpdated
		.pipe(filter((event) => event === APP_EVENT.CUSTOMER_PAYMENT_UPDATED))
		.subscribe(() => {
		this.payment = this.appStoreService.store.customer.payment;
		});

	this.appStoreService.updateInProgressPaymentPlanSummary(
		this.productSelected.id,
		this.productSelected.payment.inProgressPlanSummary);

	const productPaymentPlanExtPath = <IProductPaymentPlanExtPath>{};
	if (this.router.url === "/payment-help") {
		productPaymentPlanExtPath.paymentPlanExturl = "in-progress-summary";
		productPaymentPlanExtPath.paymentPlanCurrenturl = undefined;
		productPaymentPlanExtPath.id = this.productselectid;
		this.appStoreService.updateProductPaymentPlanExtPath(
		productPaymentPlanExtPath
		);
	} else {
		if (this.appStoreService.store.productPaymentPlanExtPath?.paymentPlanCurrenturl) {
		if (this.appStoreService.store.productPaymentPlanExtPath?.paymentPlanCurrenturl === "payment-amount") {
			productPaymentPlanExtPath.paymentPlanExturl = "in-progress-summary";
			productPaymentPlanExtPath.paymentPlanCurrenturl = "payment-amount";
			productPaymentPlanExtPath.id = this.productselectid;
			this.appStoreService.updateProductPaymentPlanExtPath(
			productPaymentPlanExtPath
			);
		}
		}
	}
	this.router.navigate([
		"/make-a-payment/payment-type/",
		this.productselectid
	]);
  }

  getProduct(productId): IProduct {
	let selected: any = false;
	this._appStore.products.map((product) => {
		if (product.id === productId) {
		selected = product;
		}
	});
	return selected;
  }

  checkPaymentItem(summary: IPaymentPlanInProgressSummary, index, event) {
	// Event is selected true
	if (event) {
		this.productSelected.payment.inProgressPlanSummary[index].selected = true;
		this.productSelected.payment.inProgressPlanSummary[
		index
		].isChecked = true;
		this.btnPayNow = true;
		if (index < this.productSelected.payment.inProgressPlanSummary.length) {
		// fails for last record, so check if undefined is false
		if (this.productSelected.payment.inProgressPlanSummary[index + 1]) {
			this.productSelected.payment.inProgressPlanSummary[
			index + 1
			].disabled = false;
			this.disableCheckBoxes(index + 1); // make remaining checkbox all disabled except the current chekbox u made YES
		}
		}
	} else {
		// Event is selected false - Reset all below checkboxes
		this.productSelected.payment.inProgressPlanSummary[
		index
		].selected = false;
		this.productSelected.payment.inProgressPlanSummary[
		index
		].isChecked = false;
		this.disableCheckBoxes(index);
		if (index === 0) {
		this.btnPayNow = false;
		}
	}
	this.btnProloader = false;
  }

  disableCheckBoxes(index) {
	for (
		let i = 0;
		i < this.productSelected.payment.inProgressPlanSummary.length;
		i++
	) {
		if (i > index) {
		this.productSelected.payment.inProgressPlanSummary[i].isChecked = false;
		this.productSelected.payment.inProgressPlanSummary[i].disabled = true;
		}
	}
  }
}
