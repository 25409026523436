import { Component, OnInit } from "@angular/core";
import { environment } from "src/environment";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"]
})
export class MaintenanceComponent implements OnInit {
  msg: string;

  ngOnInit() {
  this.msg = environment.inMaintenanceMsg;
  }
}
