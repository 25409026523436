import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	DoCheck
} from "@angular/core";
import { AppStoreService, AppStore } from "src/app/services/appStore.service";
import {
	IInstalmentForm,
	IPaymentPlanForm,
	IPaymentPlanInProgressSummary,
	IPaymentPlanEligibility
} from "src/app/self-serve/models/payment-plan.model";
import { Location } from "@angular/common";
import { IProduct } from "src/app/self-serve/models/product.model";
import { ActivatedRoute } from "@angular/router";
import { IProductUnAuth } from "src/app/self-serve/models/product.model";
import { Router } from "@angular/router";
import { MODAL_MESSAGES } from "./modal.enum";
import { TealiumService } from "src/tealium/services/tealium.service";

@Component({
	selector: "dodo-modal",
	templateUrl: "./modal.component.html",
	styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnInit, DoCheck { // DoCheck AfterViewInit
	@Output() modalResponse = new EventEmitter<string>();
	@Output() deletePayment = new EventEmitter<boolean>();
	_appStore: AppStore;
	productId: any;
	product: IProduct;
	selectedInstalmentValues: IInstalmentForm;
	selectedPaymentPlanValues: IPaymentPlanForm;
	inProgressPaymentPlanSummary: IPaymentPlanInProgressSummary;
	selectedPaymentPlanEligibility: IPaymentPlanEligibility;
	public custErrorMsgPaymentPlanEligibility = "";
	custErrorMsgFlg: boolean;
	custAndErrorMsgFlg: boolean;

	productunauth: IProductUnAuth;
	productIdunauth: any;
	selectedPaymentPlanValuesunauth: IPaymentPlanForm;
	selectedPaymentPlanEligibilityauth: IPaymentPlanEligibility;
	public custErrorMsgPaymentPlanEligibilityunauth = "";
	custAndErrorMsgFlgunauth: boolean;

	apiError = false;
	btnLoader = false;
	message = "";
	btnPreloader: boolean;
	public customerId: string;
	public loginusername: string;
	public billingAccountId: string;
	private errorCode = undefined;

	constructor(
		private appStoreService: AppStoreService,
		private location: Location,
		public route: ActivatedRoute,
		public router: Router,
		private tealiumService: TealiumService
	) { }

	ngDoCheck(): void {
		if (this.custErrorMsgPaymentPlanEligibility === "") {
			if (this.router.url.indexOf("/payment-help/select-service") === 0) {
				this.paymentPlanCustomErrorMsg();
				if (this.appStoreService.store.billingAccountSelected) {
					this.billingAccountId = this.appStoreService.store.billingAccountSelected.id;
				}
			}
		}

		if (this.custErrorMsgPaymentPlanEligibilityunauth === "") {
			if (this.router.url.indexOf("/payment-helper/select-services") === 0) {
				this.paymentPlanCustomErrorMsgUnAuth();
			}
		}
	}

	ngOnInit() {
		if (this.location.path().includes("instalment-plan/summary")) {
			this.productId = this.route.snapshot.paramMap.get("productId");
			this._appStore = this.appStoreService.store;
			this._appStore.products.map(data => {
				if (data.id === this.productId) {
					this.product = data;
					if (data.instalmentPlan) {
						this.selectedInstalmentValues =
							data.instalmentPlan.selectedInstalmentDetails;
					}
				}
			});
		}

		if (
			this.router.url.indexOf(
				"/payment-help/payment-extension-confirmation"
			) === 0 ||
			this.router.url.indexOf("/payment-help/payment-plan-confirmation") === 0 ||
			this.router.url.indexOf("/payment-help/payment-help-confirmation") === 0
		) {
			this._appStore = this.appStoreService.store;
			this.productId = this._appStore.productSelected.id;
			this._appStore.products.map(data => {
				if (data.id === this.productId) {
					this.product = data;
					if (data.payment) {
						this.selectedPaymentPlanValues = this.product.payment.selectedPlanDetails;
					}
				}
			});
		}

		// For credit balance success modal
		if (this.location.path().includes("credit-balance/")) {
			this.productId = this.route.snapshot.paramMap.get("productId");
			this._appStore = this.appStoreService.store;
			this._appStore.products.map(data => {
				if (data.id === this.productId) {
					this.product = data;
				}
			});
		}

		if (
			this.router.url.indexOf(
				"/payment-helper/payment-extension-confirmation"
			) === 0
		) {
			this._appStore = this.appStoreService.store;
			this.productIdunauth = this._appStore.productSelectedunauth.customerid;
			this._appStore.productsunauth.map(data => {
				if (data.customerid === this.productIdunauth) {
					this.productunauth = data;
					if (data.payment) {
						this.selectedPaymentPlanValuesunauth = this.productunauth.payment.selectedPlanDetails;
					}
				}
			});
		}
	}

	private paymentPlanCustomErrorMsg(): void {
		this.custErrorMsgFlg = true;
		this.custAndErrorMsgFlg = false; // for 700013 only
		this._appStore = this.appStoreService.store;
		this.productId = this._appStore.productSelected.id;
		this._appStore.products.map(data => {
			if (data.id === this.productId) {
				this.product = data;
				if (data.payment) {
					this.selectedPaymentPlanEligibility = this.product.payment.plan;
				}
				switch (this.selectedPaymentPlanEligibility.code.toString().trim()) {
					case "700002": {
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700002;
						this.errorCode = this.selectedPaymentPlanEligibility.code.toString().trim();
						break;
					}
					case "700006": {
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700006;
						this.errorCode = this.selectedPaymentPlanEligibility.code.toString().trim();
						break;
					}
					case "700008":
					case "700009": {
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700008_700009;
						this.errorCode = this.selectedPaymentPlanEligibility.code.toString().trim();
						break;
					}
					case "700011": {
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700011;
						this.custErrorMsgPaymentPlanEligibility += this.product.productCategoryType === "Energy" ? "90" : "60";
						this.custErrorMsgPaymentPlanEligibility += " days (max limit of 2)";
						this.errorCode = this.selectedPaymentPlanEligibility.code.toString().trim();
						break;
					}
					case "700012": {
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700012;
						this.errorCode = this.selectedPaymentPlanEligibility.code.toString().trim();
						break;
					}
					case "700013": {
						// custom plus general message
						this.custAndErrorMsgFlg = true;
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700013;
						this.errorCode = this.selectedPaymentPlanEligibility.code.toString().trim();
						break;
					}
					default: {
						this.custAndErrorMsgFlg = true;
						this.custErrorMsgPaymentPlanEligibility = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_DEFAULT;
						this.errorCode = undefined;
						break;
					}
				}

				if (this.errorCode === undefined) {
					this.tealiumService.setModalNotifyMsg(this.custErrorMsgPaymentPlanEligibility, undefined);
				} else {
					this.tealiumService.setModalNotifyMsg(this.custErrorMsgPaymentPlanEligibility, this.errorCode);
				}
			}
		});

	}

	public modalAction(action): void {
		this.modalResponse.emit(action);
	}

	public emptyInstalmentPlan(): void {
		this.appStoreService.store.products.map(data => {
			if (data.id === this.productId) {
				if (data.instalmentPlan) { delete data.instalmentPlan; }
			}
		});
		this.appStoreService.updateProducts(this.appStoreService.store.products);
	}

	public emptyPaymentPlan(): void {
		this.appStoreService.store.products.map(data => {
			if (data.id === this.productId) {
				if (data.payment.selectedPlanDetails) {
					delete data.payment.plan;
				}
			}
		});
		this.appStoreService.updateProducts(this.appStoreService.store.products);
	}

	public getIconPath(icon): string {
		if (icon === "Homephone") {
			icon = "home-phone";
		}
		return `assets/images/icons/icon-${icon.toLowerCase()}.svg`;
	}

	public UnAuthgetIconPath(icon): string {
		if (icon === "Homephone") {
			icon = "home-phone";
		} else if (icon === "Postpaid Mobile") {
			icon = "mobile";
		} else {
			icon = "internet";
		}
		return `assets/images/icons/icon-${icon.toLowerCase()}.svg`;
	}

	public emptyPaymentPlanUnAuth(): void {
		this.appStoreService.store.productsunauth.map(data => {
			if (data.customerid === this.productIdunauth) {
				if (data.payment.selectedPlanDetails) {
					delete data.payment.plan;
				}
			}
		});
		this.appStoreService.updateProductsUnAuth(
			this.appStoreService.store.productsunauth
		);
	}

	private paymentPlanCustomErrorMsgUnAuth(): void {
		this.custAndErrorMsgFlgunauth = false;
		this._appStore = this.appStoreService.store;
		this.productIdunauth = this._appStore.productSelectedunauth.customerid;
		this._appStore.productsunauth.map(data => {
			if (data.customerid === this.productIdunauth) {
				this.productunauth = data;
				if (data.payment) {
					this.selectedPaymentPlanEligibilityauth = this.productunauth.payment.plan;
				}
				if (this.selectedPaymentPlanEligibilityauth) {
					switch (
					this.selectedPaymentPlanEligibilityauth.code.toString().trim()
					) {
						case "700002": {
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700002;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
						case "700006": {
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700006;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
						case "700008":
						case "700009": {
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700008_700009;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
						case "700011": {
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_UNAUTH_700011;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
						case "700012": {
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700012;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
						case "700013": {
							// custom plus general message
							this.custAndErrorMsgFlgunauth = true;
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_ELIGIBILITY_700013;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
						default: {
							this.custAndErrorMsgFlgunauth = true;
							this.custErrorMsgPaymentPlanEligibilityunauth = MODAL_MESSAGES.NOTIFY_MSG_PAYMENT_PLAN_DEFAULT;
							this.errorCode = this.selectedPaymentPlanEligibilityauth.code.toString().trim();
							break;
						}
					}
				}
				if (this.errorCode === undefined) {
					this.tealiumService.setModalNotifyMsg(this.custErrorMsgPaymentPlanEligibilityunauth, undefined);
				} else {
					this.tealiumService.setModalNotifyMsg(this.custErrorMsgPaymentPlanEligibilityunauth, this.errorCode);
				}
			} else {
				this.custAndErrorMsgFlgunauth = true;
				this.tealiumService.setModalErrorMsg(this.custErrorMsgPaymentPlanEligibilityunauth, undefined);
			}
		});
	}
}
