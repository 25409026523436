import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
	ICustomer,
	ISelectedPayment,
	IQuestion,
	IUsagePeriod,
	IUrlDeeplinkDetails
} from "../self-serve/models/store.model";
import {
	IProduct,
	IProductSelected,
	IMailboxSelected,
	IProductSelectedUnAuth,
	IBillingAccountSelected,
	IProductPaymentPath
} from "../self-serve/models/product.model";
import { LocalStorageService } from "src/libraries/storage/local-storage.service";
import { IPaymentMethod } from "../self-serve/models/payment.model";
import {
	IPaymentPlanEligibility,
	IInstalmentForm,
	IPaymentPlanForm,
	IPaymentPlanInProgressSummary
} from "../self-serve/models/payment-plan.model";
import {
	IInstallmentPlan,
	IBalance,
	IInvoice
} from "../self-serve/models/invoice.model";
import { ICreditRefund } from "../self-serve/models/credit-refund.model";
import { ITrackOrder, ITrackOrderAppointmentStatus } from "../self-serve/models/track-order.model";
import { IMailBox } from "../self-serve/models/mail-box.model";

import {
	ICustomerUnAuth,
	ISelectedPaymentUnAuth
} from "../self-serve/models/store.model";

import { IProductUnAuth, IBillingAccount } from "../self-serve/models/product.model";
import { IProductPaymentPlanExtPath } from "../self-serve/models/product.model";
import { IMigrationSelected } from "../self-serve/models/migration.model";
import { ITopupValueSelected } from "../self-serve/models/addons.model";
import { IMfaMedium, IMfaVerifyLoginAttempts, IMfaVerifyCodeEntryAttempts, IMfaNotDPGonlyMigrate } from "../self-serve/models/mfa-contactmedium.model";
import { IOutageNotification } from "../self-serve/models/outageNotification.model";
import { IMigrationNotification } from "../self-serve/models/migrationNotification.model";

const localStoreKey = "app-store";
export interface AppStore {
	mulesoftAuthentication: boolean;
	products: Array<IProduct>;
	customer: ICustomer;
	productSelected: IProductSelected;
	mailboxSelected: IMailboxSelected;
	customerunauth: ICustomerUnAuth;
	productsunauth: Array<IProductUnAuth>;
	productSelectedunauth: IProductSelectedUnAuth;
	billingAccounts: Array<IBillingAccount>;
	productPaymentPlanExtPath: IProductPaymentPlanExtPath;
	backBtn: boolean;
	summaryFlg: boolean;
	flgPrimaryMethod: boolean;

	urlDeeplinkDetails: IUrlDeeplinkDetails;
	appointmentStatus: ITrackOrderAppointmentStatus;
	billingAccountSelected: IBillingAccountSelected;
	productsflg: boolean;
	migrationSelected: IMigrationSelected;
	topupSelected: ITopupValueSelected;
	topupQuoteId: any;
	usageRequestTimestamp?: number;
	mfaMediums: Array<IMfaMedium>;
	DPGOnlyflg: boolean;
	apiMfaContactsError: boolean;
	mfaVerifyLoginAttempts: IMfaVerifyLoginAttempts;
	mfaVerifyCodeEntryAttempts: IMfaVerifyCodeEntryAttempts;
	mfaNotDPGonlyMigrate: IMfaNotDPGonlyMigrate;
	productsTimestamp?: any;
	outageNotifications?: Array<IOutageNotification>;
	migrationNotifications?: Array<IMigrationNotification>;
	productPaymentPath: IProductPaymentPath;
	irToggleStatus: boolean
}

export enum APP_EVENT {
	STORE_INIT,
	STORE_RESTORED,
	PRODUCTS_UPDATED,
	CUSTOMER_UPDATED,
	CUSTOMER_PAYMENT_UPDATED,
	INSTALMENT_PLAN_UPDATED,
	INSTALMENT_PLAN_CURRENT,
	PAYMENT_PLAN_UPDATED,
	CUSTOMER_INSTALMENT_PLAN_UPDATED,
	CUSTOMER_PAYMENT_PLAN_UPDATED,
	CUSTOMER_USAGE_PERIOD_UPDATED,
	PRODUCT_SELECTED_UPDATED,
	MAILBOX_SELECTED_UPDATED,
	IN_PROGRESS_PAYMENT_PLAN_UPDATED,
	IN_PROGRESS_PAYMENT_PLAN_SUMMARY_UPDATED,
	ACCOUNTBALANCE_UPDATED,
	BILLDUEDATE_UPDATED,
	CREDITBALANCE_UPDATED,
	ORDERSTATUS_UPDATED,
	MAILBOXRECORDS_UPDATED,
	CUSTOMER_UPDATED_UNAUTH,
	CUSTOMER_PAYMENT_UPDATED_UNAUTH,
	PRODUCTS_UPDATED_UNAUTH,
	ORDERSTATUS_UPDATED_UNAUTH,
	PRODUCT_SELECTED_UPDATED_UNAUTH,
	PAYMENT_PLAN_UPDATED_UNAUTH,
	CUSTOMER_PAYMENT_PLAN_UPDATED_UNAUTH,
	AUTH_UPDATED,
	BILLING_ACCOUNT_UPDATED,
	BILLING_ACCOUNT_NUMBERS_UPDATED,
	BILLING_ACCOUNT_PAYMENT_UPDATED,
	PRODUCT_PAYMENT_PLAN_EXT_URL_UPDATED,
	PRODUCT_PAYMENT_PLAN_EXT_BACK_BTN,
	PRODUCT_PAYMENT_PLAN_EXT_SUMMARY_FLG,
	URL_DEEP_LINK_DETAILS_UPDATED,
	DEEPLINK_REDIRECT_FLG,
	TRACK_ORDER_APPOINTMENT,
	BILLING_ACCOUNT_SELECTED_UPDATED,
	BILLING_ACCOUNT_FLAG_PRIMARY_METHOD,
	PRODUCTS_FLG,
	MIGRATION_SELECTED,
	MFA_CONTACT_MEDIUM_UPDATED,
	MFA_DPGONLY_AND_ERROR_FLG,
	MFA_LOGIN_ATTEMPTS,
	MFA_CODE_ENTRY_ATTEMPTS,
	MFA_NOT_DPGONLY_NEW_EMAIL,
	MFA_NOT_DPGONLY_ERROR_MESSAGE,
	MFA_DPGONLY_AND_ERROR_FLG_MIGRATE,
	PRODUCTS_REQUEST_TIMESTAMP,
	DEEPLINK_OPEN_FLG,
	TOPUP_SELECTED,
	TOPUP_QUOTEID_UPDATED,
	USAGE_REQUEST_TIMESTAMP,
	UPDATE_PRODUCT_USAGE,
	OUTAGE_NOTIFICATIONS_UPDATED,
	MIGRATION_NOTIFICATIONS_UPDATED,
	PRODUCT_PAYMENT_PATH,
	IR_TOGGLE_UPDATED
}

@Injectable({
	providedIn: "root"
})
export class AppStoreService {
	private _appStore: AppStore;

	public storeUpdated: BehaviorSubject<APP_EVENT>;

	constructor(private storageService: LocalStorageService) {
		this.storeUpdated = new BehaviorSubject<APP_EVENT>(undefined);
		this._appStore = <AppStore>{};
		this._appStore.outageNotifications = [];
		this._appStore.migrationNotifications = [];
		this.restoreFromLocalStroage();
	}

	private restoreFromLocalStroage() {
		const storedAppStore = this.storageService.get(localStoreKey);
		if (storedAppStore) {
			this._appStore = { ...storedAppStore };
		}
	}
	private storeInLocalStorage() {
		this.storageService.set(localStoreKey, this._appStore);
	}

	public get store(): AppStore {
		return this._appStore;
	}

	public updateAuth(mulesoftAuthentication: boolean) {
		this._appStore.mulesoftAuthentication = mulesoftAuthentication;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.AUTH_UPDATED);
	}

	public updateProducts(products: IProduct[]) {
		this._appStore.products = products;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCTS_UPDATED);
	}

	public updateBillingAccounts(billingAccounts: IBillingAccount[]) {
		this._appStore.billingAccounts = billingAccounts;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.BILLING_ACCOUNT_NUMBERS_UPDATED);
	}

	public updateBillingAccount(billingAccount: IBillingAccount) {
		this._appStore.billingAccounts.map(data => {
			if (data.id === billingAccount.id) {
				data = billingAccount;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.BILLING_ACCOUNT_UPDATED);
	}

	public updateBillingAccountPayment(billingAccount: IBillingAccount) {
		this._appStore.billingAccounts.map(data => {
			if (data.id === billingAccount.id) {
				data = billingAccount;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.BILLING_ACCOUNT_PAYMENT_UPDATED);
	}

	public updateCustomer(customer: ICustomer) {
		this._appStore.customer = {
			...this._appStore.customer,
			...customer
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_UPDATED);
	}

	public updateDodoCustomerPayment(payment: ISelectedPayment) {
		this._appStore.customer = {
			...this._appStore.customer,
			payment
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_PAYMENT_UPDATED);
	}

	public updateCustomerPayment(payment: ISelectedPayment) {
		this._appStore.customer = {
			...this._appStore.customer,
			payment
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_PAYMENT_UPDATED);
	}

	public updateCustomerPaymentDetail(paymentDetail: {
		questions?: IQuestion;
		selectedPaymentMethod?: IPaymentMethod;
	}) {
		this._appStore.customer = {
			...this._appStore.customer,
			payment: {
				...this._appStore.customer.payment,
				...paymentDetail
			}
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_PAYMENT_UPDATED);
	}

	public updateInstalmentPlan(
		productId: any,
		instalment: IPaymentPlanEligibility
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.instalmentPlan.instalmentPlanReview = instalment;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.INSTALMENT_PLAN_UPDATED);
	}

	public updateInstalmentPlanCurrent(
		productId: any,
		instalment: IInstallmentPlan
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.instalmentPlan = {};
				data.instalmentPlan.instalmentPlanCurrent = instalment;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.INSTALMENT_PLAN_CURRENT);
	}

	public updateInProgressPaymentPlanExtList(
		productId: any,
		inProgressPlan: IInstallmentPlan
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				if (!data.payment) { data.payment = {}; }
				data.payment.inProgressPlan = inProgressPlan;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.IN_PROGRESS_PAYMENT_PLAN_UPDATED);
	}

	public updateCustomerInstalmentDetails(
		productId: any,
		selectedInstalmentDetails: IInstalmentForm
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.instalmentPlan.selectedInstalmentDetails = selectedInstalmentDetails;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_INSTALMENT_PLAN_UPDATED);
	}

	public updatePaymentPlan(productId: any, plan: IPaymentPlanEligibility) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				if (!data.payment) { data.payment = {}; }
				data.payment.plan = plan;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PAYMENT_PLAN_UPDATED);
	}

	public updateCustomerPaymentPlanDetails(
		productId: any,
		selectedPlanDetails: IPaymentPlanForm
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.payment.selectedPlanDetails = selectedPlanDetails;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_PAYMENT_PLAN_UPDATED);
	}

	public updateCustomerUsagePeriod(usagePeriod: IUsagePeriod) {
		this._appStore.customer.usagePeriod = <IUsagePeriod>{};
		this._appStore.customer = {
			...this._appStore.customer,
			usagePeriod
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_USAGE_PERIOD_UPDATED);
	}

	public flushAppStoreLocalStorage() {
		this._appStore = <AppStore>{};
		sessionStorage.clear();
	}

	public updateProductSelected(productSelected: IProductSelected) {
		this._appStore.productSelected = productSelected;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCT_SELECTED_UPDATED);
	}

	public storeSelectedProductMigrationDetails(productId): void {
		const productSelected = this.store.products.find(product => product.id === productId);
		const migrationSelected = <IMigrationSelected>{};
		let isMigrated = false;
		let migration_cohort;
		if (productSelected.services[0]?.hasOwnProperty("serviceCharacteristic")) {
			const serviceCharacteristic = productSelected.services[0]?.serviceCharacteristic;
			if (serviceCharacteristic[0]?.hasOwnProperty("name") && serviceCharacteristic[0]?.hasOwnProperty("value")) {
				if (serviceCharacteristic[0].name === "MigrationStatus" && serviceCharacteristic[0].value === "Completed") {
					isMigrated = true;
				}
			}
			if (serviceCharacteristic[1]?.hasOwnProperty("name") && serviceCharacteristic[1]?.hasOwnProperty("value")) {
				if (serviceCharacteristic[1].name === "MigrationCohort" && serviceCharacteristic[1].value) {
					migration_cohort = serviceCharacteristic[1].value;
				}
			}
		}
		migrationSelected.migration_cohort = migration_cohort;
		migrationSelected.billingAccountNo = productSelected.ban;
		migrationSelected.isMigrated = isMigrated;
		this.updateMigrationSelected(migrationSelected);

	}

	public updateInProgressPaymentPlanSummary(
		productId: any,
		paymentPlanSummary: IPaymentPlanInProgressSummary[]
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.payment.inProgressPlanSummary = paymentPlanSummary;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.IN_PROGRESS_PAYMENT_PLAN_SUMMARY_UPDATED);
	}

	public updateDodoAccountBalance(productId: any, accountBalance: IBalance) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.accountBalance = accountBalance;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.ACCOUNTBALANCE_UPDATED);
	}

	public updateBillDueDate(productId: any, billDueDate: IInvoice) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.billDueDate = billDueDate;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.BILLDUEDATE_UPDATED);
	}

	public updateCreditRefundBalance(productId: any, runningBalance: IBalance) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				if (!data.creditRefund) { data.creditRefund = <ICreditRefund>{}; }
				data.creditRefund.runningBalance = runningBalance;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CREDITBALANCE_UPDATED);
	}

	public updateSelectedCreditRefundBalance(
		productId: any,
		selectedBalance: any
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.creditRefund.selectedBalance = selectedBalance;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CREDITBALANCE_UPDATED);
	}

	public updatePrimaryCardCreditRefundBalance(
		productId: any,
		primaryCard: IPaymentMethod
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				if (!data.creditRefund) { data.creditRefund = <ICreditRefund>{}; }
				data.creditRefund.primaryCard = primaryCard;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CREDITBALANCE_UPDATED);
	}

	public updateProductOrderStatus(productId: any, orderStatus: ITrackOrder[]) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.orderStatus = orderStatus;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.ORDERSTATUS_UPDATED);
	}

	public updateProductMailBox(productId: any, mailBox: IMailBox[]) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.mailBox = mailBox;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MAILBOXRECORDS_UPDATED);
	}
	public updateMailBoxSelected(mailboxSelected: IMailboxSelected) {
		this._appStore.mailboxSelected = mailboxSelected;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MAILBOX_SELECTED_UPDATED);
	}

	public updateCustomerUnAuth(customerunauth: ICustomerUnAuth) {
		this._appStore.customerunauth = {
			...this._appStore.customerunauth,
			...customerunauth
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_UPDATED_UNAUTH);
	}

	public updateProductsUnAuth(productsunauth: IProductUnAuth[]) {
		this._appStore.productsunauth = productsunauth;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCTS_UPDATED_UNAUTH);
	}

	public updateCustomerPaymentUnAuth(payment: ISelectedPaymentUnAuth) {
		this._appStore.customerunauth = {
			...this._appStore.customerunauth,
			payment
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_PAYMENT_UPDATED_UNAUTH);
	}

	public updateProductOrderStatusUnAuth(productId: any, orderStatus: ITrackOrder[]) {
		this._appStore.productsunauth.map(data => {
			if (data.customerid === productId) {
				data.orderStatus = orderStatus;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.ORDERSTATUS_UPDATED_UNAUTH);
	}

	public updatePaymentPlanUnAuth(productId: any, plan: IPaymentPlanEligibility) {
		this._appStore.productsunauth.map(data => {
			if (data.customerid === productId) {
				if (!data.payment) { data.payment = {}; }
				data.payment.plan = plan;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PAYMENT_PLAN_UPDATED_UNAUTH);
	}

	public updateCustomerPaymentPlanDetailsUnAuth(
		productId: any,
		selectedPlanDetails: IPaymentPlanForm
	) {
		this._appStore.productsunauth.map(data => {
			if (data.customerid === productId) {
				data.payment.selectedPlanDetails = selectedPlanDetails;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.CUSTOMER_PAYMENT_PLAN_UPDATED_UNAUTH);
	}

	public updateProductSelectedUnAuth(productSelectedunauth: IProductSelectedUnAuth) {
		this._appStore.productSelectedunauth = productSelectedunauth;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCT_SELECTED_UPDATED_UNAUTH);
	}

	public updateAccountBalance(accountNo: any, accountBalance: IBalance) {
		this._appStore.billingAccounts.map(data => {
			if (data.accountNo === accountNo) {
				data.accountBalance = accountBalance;
			}
		});
		this.storeInLocalStorage();
	}

	public updateProductPaymentPlanExtPath(productPaymentPlanExtPath: IProductPaymentPlanExtPath) {
		this._appStore.productPaymentPlanExtPath = productPaymentPlanExtPath;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCT_PAYMENT_PLAN_EXT_URL_UPDATED);
	}

	public updateProductPaymentPath(flg: boolean) {
		this._appStore.backBtn = flg;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCT_PAYMENT_PLAN_EXT_BACK_BTN);
	}
	public updateProductPaymentSummaryPath(flg: boolean) {
		this._appStore.summaryFlg = flg;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCT_PAYMENT_PLAN_EXT_SUMMARY_FLG);
	}

	public updateUrlDeeplinkDetails(urlDeepLinkDetails: IUrlDeeplinkDetails) {
		this._appStore.urlDeeplinkDetails = {
			...this._appStore.urlDeeplinkDetails,
			...urlDeepLinkDetails
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.URL_DEEP_LINK_DETAILS_UPDATED);
	}

	public updateDeeplinkRedirect(redirectFlg: boolean) {
		this._appStore.urlDeeplinkDetails.redirectFlg = redirectFlg;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.DEEPLINK_REDIRECT_FLG);
	}

	public updateAppointmentStatus(appointmentStatus: ITrackOrderAppointmentStatus) {
		this._appStore.appointmentStatus = {
			...this._appStore.appointmentStatus,
			...appointmentStatus
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.TRACK_ORDER_APPOINTMENT);
	}

	public updateBillingAccountSelected(billingAccountSelected: IBillingAccountSelected) {
		this._appStore.billingAccountSelected = billingAccountSelected;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.BILLING_ACCOUNT_SELECTED_UPDATED);
	}

	public updatePrimaryMethod(flgPrimaryMethod: boolean) {
		this._appStore.flgPrimaryMethod = flgPrimaryMethod;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.BILLING_ACCOUNT_FLAG_PRIMARY_METHOD);
	}

	public updateProductsflg(productsflg: boolean) {
		this._appStore.productsflg = productsflg;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCTS_FLG);
	}

	public updateMigrationSelected(migrationSelected: IMigrationSelected) {
		this._appStore.migrationSelected = migrationSelected;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MIGRATION_SELECTED);
	}

	public updateMfaMediums(mfaMediums: IMfaMedium[]) {
		this._appStore.mfaMediums = mfaMediums;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_CONTACT_MEDIUM_UPDATED);
	}

	public updMfaDPGnErrflg(DPGOnlyflg: boolean, apiMfaContactsError: boolean) {
		this._appStore.DPGOnlyflg = DPGOnlyflg;
		this._appStore.apiMfaContactsError = apiMfaContactsError;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_DPGONLY_AND_ERROR_FLG);
	}

	public updMfaLoginAttempts(mfaVerifyLoginAttempts: IMfaVerifyLoginAttempts) {
		this._appStore.mfaVerifyLoginAttempts = {
			...this._appStore.mfaVerifyLoginAttempts,
			...mfaVerifyLoginAttempts
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_LOGIN_ATTEMPTS);
	}

	public updMfaCodeEntryAttempts(mfaVerifyCodeEntryAttempts: IMfaVerifyCodeEntryAttempts) {
		this._appStore.mfaVerifyCodeEntryAttempts = {
			...this._appStore.mfaVerifyCodeEntryAttempts,
			...mfaVerifyCodeEntryAttempts
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_CODE_ENTRY_ATTEMPTS);
	}

	public updMfaDPGnErrflgMigrate(DPGOnlyflg: boolean, apiMfaContactsError: boolean) {
		this._appStore.DPGOnlyflg = DPGOnlyflg;
		this._appStore.apiMfaContactsError = apiMfaContactsError;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_DPGONLY_AND_ERROR_FLG_MIGRATE);
	}

	public storeMfaNotDPGonlyNewEmail(mfaNotDPGonlyMigrate: IMfaNotDPGonlyMigrate) {
		this._appStore.mfaNotDPGonlyMigrate = {
			...this._appStore.mfaNotDPGonlyMigrate,
			...mfaNotDPGonlyMigrate
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_NOT_DPGONLY_NEW_EMAIL);
	}

	public storeMfaNotDPGonlyErrMsg(mfaNotDPGonlyMigrate: IMfaNotDPGonlyMigrate) {
		this._appStore.mfaNotDPGonlyMigrate = {
			...this._appStore.mfaNotDPGonlyMigrate,
			...mfaNotDPGonlyMigrate
		};
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MFA_NOT_DPGONLY_ERROR_MESSAGE);
	}

	public updateTopupQuoteId(topupQuoteId: any) {
		this._appStore.topupQuoteId = topupQuoteId;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.TOPUP_QUOTEID_UPDATED);
	}

	public updateTopupSelected(topupValueSelected: ITopupValueSelected) {
		this._appStore.topupSelected = topupValueSelected;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.TOPUP_SELECTED);
	}
	public updateUsageReqTimestamp(usageRequestTimestamp: number) {
		this._appStore.usageRequestTimestamp = usageRequestTimestamp;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.USAGE_REQUEST_TIMESTAMP);
	}

	public updateUsageOfProduct(
		productId: any,
		usage: any
	) {
		this._appStore.products.map(data => {
			if (data.id === productId) {
				data.usage = usage;
			}
		});
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.UPDATE_PRODUCT_USAGE);
	}

	public updateProductsReqTimestamp(productsTimestamp: any) {
		this._appStore.productsTimestamp = productsTimestamp;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCTS_REQUEST_TIMESTAMP);
	}

	public updateDeeplinkOpen(deeplinkOpenFlg: boolean) {
		this._appStore.urlDeeplinkDetails.deeplinkOpenFlg = deeplinkOpenFlg;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.DEEPLINK_OPEN_FLG);
	}

	public updateOutageNotification(notification: IOutageNotification) {
		this._appStore.outageNotifications.push(notification);
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.OUTAGE_NOTIFICATIONS_UPDATED);
	}

	public updateMigrationNotification(notification: IMigrationNotification) {
		this._appStore.migrationNotifications.push(notification);
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.MIGRATION_NOTIFICATIONS_UPDATED);
	}

	public updatePaymentPath(productPaymentPath: IProductPaymentPath) {
		this._appStore.productPaymentPath = productPaymentPath;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.PRODUCT_PAYMENT_PATH);
	}

	public updateIRToggle(status: boolean) {
		this._appStore.irToggleStatus = status;
		this.storeInLocalStorage();
		this.storeUpdated.next(APP_EVENT.IR_TOGGLE_UPDATED);
	}
}
