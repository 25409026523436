<footer class="footer">
  <section class="_header">
    <div
      class="container flex flex-align-items-center flex-justify-content-between"
    >
      <div class="__brand">
        <a routerLink="/dashboard" class="inline-block">
          <img class="logo" src="assets/images/brand-white.svg" alt="" />
        </a>
      </div>
      <nav class="__social">
        <ul>
          <li class="___icon facebook">
            <a href="https://www.facebook.com/dodoaustralia" target="_blank">
              <i class="fab fa-facebook-f txt-white"></i>
            </a>
          </li>
          <li class="___icon twitter">
            <a href="https://twitter.com/dodoaustralia" target="_blank">
              <i class="fab fa-twitter txt-white"></i>
            </a>
          </li>
          <li class="___icon youtube">
            <a href="https://www.youtube.com/user/DodoAUS" target="_blank">
              <i class="fab fa-youtube txt-white"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
  <nav class="_navigation">
    <div class="container" data-utag-zone="Footer">
      <!-- Mobile Start -->
      <div class="__accordion sm-only __mobile" id="menu">
        <div class="card">
          <div class="card-header" id="headingFooterOne">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFooterOne"
                aria-expanded="false"
                aria-controls="collapseFooterOne"
              >
                Quick links
                <i class="icon"></i>
              </button>
              <!-- <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFooterOne"
                aria-expanded="false" aria-controls="collapseFooterOne">
               
                <i class="fal fa-minus hide"></i>
              </button> -->
            </h5>
          </div>

          <div
            id="collapseFooterOne"
            class="collapse"
            aria-labelledby="headingFooterOne"
            data-parent="#menu"
          >
            <div class="card-body">
              <ul>
                <li>
                  <a routerLink="/make-a-payment" class="txt-white"
                    >Make a payment</a
                  >
                </li>
                <li>
                  <a routerLink="/billing-overview" class="txt-white">Bills</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFooterTwo">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFooterTwo"
                aria-expanded="false"
                aria-controls="collapseFooterTwo"
              >
                My Dodo
                <i class="icon"></i>
              </button>
              <!-- <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFooterTwo"
                aria-expanded="false" aria-controls="collapseFooterTwo">
                <i class="fal fa-plus"></i>
                <i class="fal fa-minus hide"></i>
              </button> -->
            </h5>
          </div>
          <div
            id="collapseFooterTwo"
            class="collapse"
            aria-labelledby="headingFooterTwo"
            data-parent="#menu"
          >
            <div class="card-body">
              <ul>
                <li>
                  <a
                    href="{{config.supportSiteHostServer}}"
                    target="_blank"
                    class="txt-white"
                    >FAQs</a
                  >
                </li>
                <li>
                  <a
                    data-toggle="modal"
                    data-target="#betaModal"
                    class="txt-white"
                    >About My Dodo</a
                  >
                </li>
                <li>
                  <a routerLink="/logout" class="txt-white">Log out</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFooterThree">
            <h5 class="mb-0">
              <button
                class="btn btn-link collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFooterThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                All Dodo products
                <i class="icon"></i>
              </button>
              <!-- <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFooterThree"
                aria-expanded="false" aria-controls="collapseFooterThree">
                <i class="fal fa-plus"></i>
                <i class="fal fa-minus hide"></i>
              </button> -->
            </h5>
          </div>
          <div
            id="collapseFooterThree"
            class="collapse"
            aria-labelledby="headingFooterThree"
            data-parent="#menu"
          >
            <div class="card-body">
              <ul>
                <li>
                  <a
                    href="{{config.websiteHostServer}}/nbn"
                    target="_blank"
                    class="txt-white"
                    >Internet</a
                  >
                </li>
                <li>
                  <a
                    href="{{config.websiteHostServer}}/mobile"
                    class="txt-white"
                    target="_blank"
                    >Mobile</a
                  >
                </li>
                <li>
                  <a
                    href="{{config.websiteHostServer}}/energy"
                    class="txt-white"
                    target="_blank"
                    >Energy</a
                  >
                </li>
              <!--   <li> // Geoff told me to comment it out
                  <a
                    href="{{config.websiteHostServer}}/home-phone/"
                    class="txt-white"
                    target="_blank"
                    >Home phone</a
                  >
                </li>
                <li>
                  <a
                    href="{{config.websiteHostServer}}/fetch-tv"
                    class="txt-white"
                    target="_blank"
                    >Fetch</a
                  >
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Mobile End -->
      <!-- Desktop Start -->
      <div class="row sm-hide __desktop relative">
        <img
          src="assets/images/common/brand-footer.png"
          class="absolute footer-bird-icon lg-only"
        />
        <div class="flex">
          <div class="_footer-links-col">
            <header class="___title">Quick links</header>
            <ul>
              <li>
                <a routerLink="/make-a-payment" class="txt-white"
                  >Make a payment</a
                >
              </li>
              <li>
                <a routerLink="/billing-overview" class="txt-white">Bills</a>
              </li>
            </ul>
          </div>
          <div class="_footer-links-col">
            <header class="___title">My Dodo</header>
            <ul>
              <li>
                <a
                  href="{{config.supportSiteHostServer}}"
                  class="txt-white"
                  target="_blank"
                  >FAQs</a
                >
              </li>
              <li>
                <a
                  data-toggle="modal"
                  data-target="#betaModal"
                  class="txt-white"
                  >About My Dodo</a
                >
              </li>
              <li>
                <a routerLink="/logout" class="txt-white">Log out</a>
              </li>
            </ul>
          </div>
          <div class="_footer-links-col">
            <header class="___title">All Dodo products</header>
            <ul>
              <li>
                <a
                  class="flex flex-justify-content-between"
                  href="{{config.websiteHostServer}}/nbn"
                  target="_blank"
                >
                  <span class="txt-white">Internet</span>
                  <img src="assets/images/icons/icon-external-link-white.svg" />
                </a>
              </li>
              <li>
                <a
                  class="flex flex-justify-content-between"
                  href="{{config.websiteHostServer}}/mobile"
                  target="_blank"
                >
                  <span class="txt-white">Mobile</span>
                  <img src="assets/images/icons/icon-external-link-white.svg" />
                </a>
              </li>
              <li>
                <a
                  class="flex flex-justify-content-between"
                  href="{{config.websiteHostServer}}/energy"
                  target="_blank"
                >
                  <span class="txt-white">Energy</span>
                  <img src="assets/images/icons/icon-external-link-white.svg" />
                </a>
              </li>
            <!--   <li> // Geoff told me to comment it out
                <a
                  class="flex flex-justify-content-between"
                  href="{{config.websiteHostServer}}/home-phone/"
                  target="_blank"
                >
                  <span class="txt-white">Home phone</span>
                  <img src="assets/images/icons/icon-external-link-white.svg" />
                </a>
              </li>
              <li>
                <a
                  class="flex flex-justify-content-between"
                  href="{{config.websiteHostServer}}/fetch-tv"
                  target="_blank"
                >
                  <span class="txt-white">Fetch</span>
                  <img src="assets/images/icons/icon-external-link-white.svg" />
                </a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- Desktop End -->
    </div>
  </nav>
  <section class="_footer">
    <div class="container">
      <nav class="__legal my4">
        <ul>
          <li>
            <a href="{{config.websiteHostServer}}/legal/terms-policies" target="_blank"
              >Terms &amp; Policies</a
            >
          </li>
          <li>
            <a
              href="{{config.websiteHostServer}}/terms-policies#policies-amp-statements"
              target="_blank"
              >Privacy Policy</a
            >
          </li>

          <li>
            <a href="{{config.websiteHostServer}}/contact-us/" target="_blank"
              >Contact Us</a
            >
          </li>

          <li>
            <a href="{{config.supportSiteHostServer}}" target="_blank"
              >Support Centre</a
            >
          </li>
          <li>
            <a href="http://blog.dodo.com/" target="_blank">Dodo Blog</a>
          </li>
        </ul>
      </nav>
      <span class="__copyright inline-block my4"
        >Dodo ABN 33 158 289 331 &copy; 2023 Dodo Services Pty Ltd.</span
      >
    </div>
  </section>
</footer>
<dodo-modal></dodo-modal>
