import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCommonModule } from "@angular/material/core";
import { CommonModule } from "@angular/common";
import { AddressFieldComponent } from "./address-field/address-field.component";
import { AutoCompleteFieldComponent } from "./autocomplete-field/autocomplete-field.component";
import { MaterialModule } from "./material.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { InputFieldComponent } from "./input-field/input-field.component";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatCommonModule,
		MatInputModule,
		MatFormFieldModule,
		MaterialModule,
		MatIconModule,
		MatCheckboxModule
	],
	declarations: [
		AddressFieldComponent,
		AutoCompleteFieldComponent,
		InputFieldComponent
	],
	exports: [
		AutoCompleteFieldComponent,
		AddressFieldComponent,
		InputFieldComponent
	]
})
export class MaterialFormsModule {}
